

import Vue from 'vue'
import Component from 'vue-class-component'
import {
  Getter,
  Action,
} from 'vuex-class'
import {IAuditLogDTO, IStaffMemberDTO, IUserDTO} from "@/types/AutoGenerated/reinforcedTypings";

@Component({
  name: "CalendarStyleSettings",
})

export default class CalendarStyleSettings extends Vue {

  public showInformationBox: boolean = false;
  public informationBoxText: string = "";
public hideIrrelevantDaysInCalendar: boolean = false;
public intervalMinutes: number = 0;

 public showStaffMemberInEvent: number = 0;
 public showTimeInEvent: boolean|number = false;
 public showServiceNameInEvent: boolean|number = false;


  @Action public postSnackbarMessage: Function;
  @Getter public staffMembers: IStaffMemberDTO[];

  @Getter user : IUserDTO;
  @Action patchUser: Function;
  @Action postAuditLog: Function;

  intervalMinutesOptions = [90, 60, 30, 15];


  created() {
    this.showInformationBox = this.user.showInformationBox;
    this.informationBoxText = this.user.informationBoxText;
    this.hideIrrelevantDaysInCalendar = this.user.hideIrrelevantDaysInCalendar;
    this.intervalMinutes = this.user.intervalMinutes;
    this.showStaffMemberInEvent = this.user.showStaffMemberInEvent;
    this.showTimeInEvent = this.user.showTimeInEvent;
    this.showServiceNameInEvent = this.user.showServiceNameInEvent;
  }
  async saveChanges(){


    if(this.hideIrrelevantDaysInCalendar != this.user.hideIrrelevantDaysInCalendar){
      let audit: Partial<IAuditLogDTO> = {action: 1, type: 2, comment: "", message: " endret innstilling 'Skjul dager uten for arbeidstid' til " + this.booleanToOnOff(this.hideIrrelevantDaysInCalendar), hideForUser: false,};
      this.postAuditLog(audit);
    }
    if(this.showServiceNameInEvent != this.user.showServiceNameInEvent){
      let audit: Partial<IAuditLogDTO> = {action: 1, type: 2, comment: "", message: " endret innstilling 'Vis tjenestenavn i hendelse' til " + this.booleanToOnOff(this.showServiceNameInEvent), hideForUser: false,};
      this.postAuditLog(audit);
    }
    if(this.showStaffMemberInEvent != this.user.showStaffMemberInEvent){
      let audit: Partial<IAuditLogDTO> = {action: 1, type: 2, comment: "", message: " endret innstilling 'Vis ansatt i hendelse' til " + this.showStaffMemberInEventLabel(this.showStaffMemberInEvent), hideForUser: false,};
      this.postAuditLog(audit);
    }
    if(this.showTimeInEvent != this.user.showTimeInEvent){
      let audit: Partial<IAuditLogDTO> = {action: 1, type: 2, comment: "", message: " endret innstilling 'Vis tidspunkt i hendelse' til " + this.booleanToOnOff(this.showTimeInEvent), hideForUser: false,};
      this.postAuditLog(audit);
    }

    let update: Partial<IUserDTO> = {
      showInformationBox: this.showInformationBox,
      informationBoxText : this.informationBoxText,
      hideIrrelevantDaysInCalendar: this.hideIrrelevantDaysInCalendar,
      intervalMinutes: this.intervalMinutes,
      showStaffMemberInEvent: this.showStaffMemberInEvent,
      showTimeInEvent: this.showTimeInEvent as number,
      showServiceNameInEvent: this.showServiceNameInEvent as number
    };

    this.patchUser(update);
  }


  showStaffMemberInEventLabel(value){
    if(value == 0){
      return "av";
    }
    if(value == 1){
      return "navn"
    }
    if(value == 2){
      return "farge"
    }
  }

  booleanToOnOff(value: boolean|number): string {
    if (value || value == 1) {
      return "På"
    } else {
      return "Av"
    }
  }


  get isOldDomain(){
    return window.location.toString().includes("vergba");
  }

  get hasMultipleStaff(){
    return this.staffMembers.length > 1;
  }
}

