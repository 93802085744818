

    import Vue from 'vue'
    import Component from 'vue-class-component'
    import {
        Getter,
        Action,
        Mutation,
    } from 'vuex-class'
    import {Prop, Watch} from "vue-property-decorator";
    import {DialogType, IDialog} from "@/store/dialog";
    import CreateCustomer from "./global/CreateCustomer.vue";
    import SelectCustomer from "./global/CreateAppointment.vue";
    import {formatTime} from "@/types/formatDateHelper";
    import {IAppointmentInfoDTO, IAuditLogDTO, IStaffMemberDTO} from "@/types/AutoGenerated/reinforcedTypings";
    import CreateAppointment from "./global/CreateAppointment.vue";
    import {AppointmentInfo} from "@/store/appointments/appointmentTypings";


    @Component({
        name: "AppointmentMenu",
        components: {SelectCustomer, CreateCustomer,CreateAppointment}
    })

    export default class AppointmentMenu extends Vue {

        @Prop({}) appointment :IAppointmentInfoDTO;
        @Prop({}) appointmentDate;
        @Prop({}) appointmentId;
      @Prop({default: false}) displayShowInCalendar;
      @Prop({default: false}) spread;
      @Prop({default: false}) large;

        @Mutation public setDefaultCustomerToBook:Function;

      @Getter public staffMembers: IStaffMemberDTO[];


      @Getter public appointmentsForToday;
        @Action public selectDateToBook:Function;
        @Action public selectHourToBook:Function;

        @Action public getCustomers:Function;
        @Getter public customers:Function;

        public selectedCustomerId:number = 0;

        private newCustomerDefaultName: string = "";

        @Action updateAppointment: Function;
        @Action public deleteBookedAppointment:Function;
        @Action public postSnackbarMessage:Function;
        @Action public addToDialogQueue:Function;

        @Action public getAppointmentTypes:Function;
        @Getter public appointmentType:Function;

      @Action postAuditLog: Function;


      public moveAppointment:boolean = false;
        public selectedAppointmentType:number = -1;
        public defaultSelectedServiceId:number = 0;
        private defaultSelectedCustomerId: number;


        @Watch("appointment")
        onValCahnge(){
          //@ts-ignore
            this.selectedAppointmentType = this.appointment.appointmentTypeId;
        }

        created() {
          //@ts-ignore
            this.selectedAppointmentType = this.appointment.appointmentTypeId;
          //@ts-ignore
            this.defaultSelectedServiceId = this.appointment.appointmentTypeId;

                if(this.appointment.customerId !== 0 && this.appointment.customerId !== -1){
                    this.selectedCustomerId = this.appointment.customerId;
                    this.defaultSelectedCustomerId =  this.selectedCustomerId;
                }
        }


      move(){

        this.postSnackbarMessage('Trykk på et tidspunkt i kalerenderen for å flytte timen.');

        //@ts-ignore
          console.log("ID: ", this.appointment.appointmentId);
        //@ts-ignore
        this.$emit("move", this.appointment.appointmentId);
      }
        findInCalendar(){

          let audit: Partial<IAuditLogDTO> = {
            action: 3,
            type: 4,
            comment: "",
            message: " clicked on search in calendar for " + this.appointment.number,
            hideForUser: true,
          };

          this.postAuditLog(audit);

            this.$router.push("/kalender/" + this.appointment.number);
        }

        public deleteAppointment():void{

            let vm = this;

            let dialog : IDialog = {
                text: "Er du sikker på at du vil slette denne timen?",
                type: DialogType.Choice,
                header: "Slett time",
                action: async function(){
                    let deletePayload = {
                        appointmentId:vm.appointmentId,
                    };

                    await vm.deleteBookedAppointment(deletePayload);

                    vm.$emit("deleted");
                    vm.postSnackbarMessage("Timen er slettet");


                }
            };

            this.addToDialogQueue(dialog);


        }

        onMovedAppointment(){
            this.$emit("moved")
        }

        onClosed(){
            this.moveAppointment = false;
            this.$emit("close");
            this.$emit("confirmed");
        }

        moveAppointmentAction(){


            //TODO: Proper appointment dato....
            this.setDefaultCustomerToBook(null);

            let now = new Date(this.appointment.year,this.appointment.month - 1, this.appointment.day, this.appointment.hour, this.appointment.minute ,0);
            this.selectDateToBook({year: now.getFullYear(), month: now.getMonth(), day: now.getDate()});

            this.selectHourToBook(this.hourAndMinuteOfAppointment());

            this.moveAppointment = true;
        }

        hourAndMinuteOfAppointment(){
            return this.formatTimeCorrectly(this.appointment.hour, this.appointment.minute)
        }

        formatTimeCorrectly(hour:string | number, minute:string | number):string{
            return formatTime(hour, minute);
        }


        customerFilter (item, queryText) {
            const textOne = item.firstName.toLowerCase();

            this.newCustomerDefaultName = queryText;

            return textOne.includes(queryText.toLowerCase());
        };
        get overrideCustomerName(){
            let a : any = this.appointment as any;

            if(a.name != null && a.name != ""){
                return a.name;
            }
            if(this.appointment.customerName != null && this.appointment.customerName != ""){
                return this.appointment.customerName;
            }
            return null;
        }

      getServiceIds(appointment){
          //TODO: Appointment from Weekly calendar has field "appointmentTypeIds" while from "Appointment page" aka "GetInformation (AppointmentInfoDTO" has "serviceIds"

          if(appointment.appointmentTypeIds != null){
            return appointment.appointmentTypeIds;
          } else if(appointment.serviceIds != null){
            return appointment.serviceIds;
          }
          return [];
       }

    }

