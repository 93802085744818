
// @ts-ignore
import axios, {AxiosRequestConfig} from 'axios';
// @ts-ignore
import { GetterTree, MutationTree, ActionTree } from 'vuex';
import { APIURL2 } from '../main';
import authConfig, {AuthConfig} from "@/Utilities/authConfig";


// Interface
export interface customerJournalState {
    customerJournal: ICustomerJournals;
}

// Interface Model
export interface ICustomerJournals {
    id: Number | string;
    title: string;
    content: string;
    entryDate: string;
    customerId: Number | string;
    adminId: string | number;
}

// Mutation enums
export const enum mutationStringCustomerJournals {
    setCustomerJournals = 'setCustomerJournals',
}
export const enum actionStringCustomerJournals{
    getCustomerJournal = 'getCustomerJournal',
}

export const state = {
    customerJournal: [],
};

export const getters: GetterTree<any, customerJournalState> = {
    customerJournal: (state) => state.customerJournal,
};

export const mutations: MutationTree<customerJournalState> = {
    setCustomerJournals(state, payload: ICustomerJournals) {
        state.customerJournal = payload;
    },
};

export const actions: ActionTree<customerJournalState, any> = {

    async getCustomerJournal({commit}, customerID): Promise<any> {
        let header : AxiosRequestConfig = await authConfig();

        return new Promise(((resolve, reject) => {
            axios.get(APIURL2 + 'CustomerJournal/current/customerId?customerId=' + customerID, header ).then((res) => {

                //Check if journal should be locked or not. & parse date to readable formats.
                let today:string | number = new Date().toISOString().substring(0, 10);
                for(let i:number = 0; i < res.data.length; i++){
                    res.data[i].entryDate = res.data[i].entryDate.substring(0,10);

                    if(today === res.data[i].entryDate){
                        Object.assign(res.data[i], {isLocked:false})
                    } else {
                        Object.assign(res.data[i], {isLocked:true});
                    }
                }

                commit(mutationStringCustomerJournals.setCustomerJournals, res.data);
                resolve("");
            }).catch((err) => {
                reject();
                console.log(err);
            });
        }));

    },

    async addNewCustomerJournal({commit, dispatch}, newCustomerJournal) {
        let header : AxiosRequestConfig = await authConfig();

        return new Promise((resolve,reject) => {
            axios.post(APIURL2 + "CustomerJournal/current", newCustomerJournal,
                header ).then(() => {
                dispatch(actionStringCustomerJournals.getCustomerJournal, newCustomerJournal.customerId);
                resolve("");
            }).catch((err) => {
                reject();
                console.log(err);
            });
        });

    },
    async updateCustomerJournal({commit, dispatch}, updatedCustomerJournal:Partial<ICustomerJournals>) : Promise<any> {
        let header : AxiosRequestConfig = await authConfig();

        return new Promise((resolve, reject) => {
            axios.put(APIURL2 + "CustomerJournal/current", updatedCustomerJournal,
                header ).then(() => {
                resolve("");
            }).catch((err) => {
                console.log(err);
                reject();
            });
        })

    },



};

export const customerJournals = {
    actions,
    mutations,
    getters,
    state,
};
