
// @ts-ignore
import { GetterTree, MutationTree, ActionTree } from 'vuex';
// @ts-ignore
import axios, {AxiosRequestConfig} from 'axios';
import { APIURL2 } from '@/main';
import { actionStringSnackbar } from './snackbar';
import authConfig, {AuthConfig} from "@/Utilities/authConfig";
import {actionStringUser} from "@/store/user";




export const enum actionStringCheckout{
    CREATE_SUBSCRIPTION = 'createSubscription',
    CANCEL_SUBSCRIPTION_AT_END_OF_MONTH = 'cancelSubscriptionAtEndOfMonth'
}
export const enum mutationStringCheckout{

}
export const enum getterStringCheckout{

}
export interface checkoutState {
    SubscriptionForm:ISubscriptionForm
}
export interface ISubscriptionForm{
    stripeEmail:string,
    stripeToken:Object,
}


export const state = {
    subscription: null,
};

export const getters: GetterTree<any, checkoutState> = {

};

export const mutations: MutationTree<checkoutState> = {

};



export const actions: ActionTree<checkoutState, any> = {

    async createSubscription({commit, dispatch}, subscriptionForm:ISubscriptionForm):Promise<any> {
        let header : AxiosRequestConfig = await authConfig();
        return new Promise((resolve, reject) => {
            axios.post(APIURL2 + 'Subscription/Create/current?stripeEmail=' + subscriptionForm.stripeEmail + '&stripeToken=' + subscriptionForm.stripeToken,
                { },
                header).then((res) => {
                dispatch(actionStringSnackbar.postSnackbarMessage, "Betaling er godkjent");
                resolve(res);
            }).catch((err) => {
                console.log(err);
                dispatch(actionStringSnackbar.postSnackbarMessage, err.message );
                reject(err);
            });
        });
    },

    async cancelSubscriptionAtEndOfMonth({commit, dispatch}, payload:boolean):Promise<any>{
        let header: AxiosRequestConfig= await authConfig();
        return new Promise((resolve, reject) => {
            axios.post(APIURL2 + 'Subscription/cancel?cancelAtTheEndOfTheMonth=' + payload, {}, header).then((res) => {
                let feedbackMessage: string = '';
                if (payload === true) {
                    feedbackMessage = 'Du har kansellert ditt medlemskap!';
                }
                if (payload === false) {
                    feedbackMessage = 'Ditt medlemskap er nå aktiv igjen!'
                }
                dispatch(actionStringSnackbar.postSnackbarMessage, feedbackMessage);
                resolve(res);
            }).catch((err) => {
                dispatch(actionStringSnackbar.postSnackbarMessage, "Noe gikk galt! Kontakt kundeservice eller prøv igjen.");
                reject(err);
            });
        });

    }



};

export const checkout = {
    actions,
    mutations,
    getters,
    state
};
