
// @ts-ignore
import { GetterTree, MutationTree, ActionTree } from 'vuex';
// @ts-ignore
// @ts-ignore
import axios from "axios";
import {mutationStringAppointments} from "@/store/appointments/appointments";
import {APIURL2} from "@/main";
import {AuthConfig} from "@/Utilities/authConfig";



// Interface
export interface OnboardingState {
}

// Interface Model
export interface Ionboarding {
}

// Mutation enums
export const enum mutationStringOnboarding {
}

export const state:OnboardingState = {
};

export const getters: GetterTree<any, OnboardingState> = {
};

export const mutations: MutationTree<OnboardingState> = {


};

export const actions: ActionTree<OnboardingState, any> = {


};

export const onboarding = {
    actions,
    mutations,
    getters,
    state,
};
