
    import {Component, Vue, Watch} from 'vue-property-decorator';
    import {Action, Getter, Mutation} from "vuex-class";
    import {actionStringCustomers, ICustomer} from '../../store/customers';
    import {ICustomerJournals} from '../../store/customerJournals';
    import muscleSelector from '../global/muscleGroupSelector.vue';
    import DatePicker from "@/components/global/DatePicker.vue";
    import CustomInput from "@/components/global/styledComponents/CustomInput.vue";
    import {Sector} from "@/types/Sector";
    import {IUser} from "@/store/user";
    import CustomButton from "@/components/global/styledComponents/customButton.vue";
    import AppointmentList from "@/pages/admin/AppointmentList.vue";
    import {formatDate, FormatSpaceType} from "@/types/formatDateHelper";
    import {DialogMutations, DialogType, IDialog} from "@/store/dialog";
    import Loader from "@/components/global/Loader.vue";
    import CustomerModal from "@/components/global/CustomerModal.vue";
    import EditCustomer from "./EditCustomer.vue";
    import CustomerModalWindow from "./CustomerModalWindow.vue";
    import {formatTime} from "../../types/formatDateHelper";
    import CreateCustomer from "./CreateCustomer.vue";
    import {formatDateString, formatDateStringHour} from "../../Utilities/dateUtility";

    @Component({
        name: 'AdminCustomers',
        components: {
            CreateCustomer,
            CustomerModalWindow,
            EditCustomer,
            CustomerModal, Loader, AppointmentList, CustomButton, CustomInput, DatePicker, muscleSelector}
    })
    export default class AdminCustomers extends Vue {
        //Props
        public isShowingCustomerForm:boolean = false;
        public searchQuery:string = '';

        public journals = [

        ];
        public sector:Sector | null = null;
        public date = null;
        public menu = false;
        public modal = false;
        public toggleIndex:number | null = null;
        public isShowingEditingDatePicker:boolean = false;
        public isShowingDatePicker:boolean = false;
        public formType:string = '';
        selectedCustomers = [];
        editingCustomer = null;
        viewingCustomer = null;


        get headers(){
            if(this.isMobile()){
                return [{text: "Navn", align: "start", sortable: true, value:'firstName'},];
            }else{
                return [{text: "Navn", align: "start", sortable: true, value:'firstName'},
                    {text: "Epost",sortable: true, value:"emailAddress"},
                    {text: "Telefonnummer",sortable: true, value:"phoneNumber"},
                    {text: "Adresse",sortable: true, value:"address"},
                    {text: "Postkode/by",sortable: true, value:"postalCode"},
                    // { text: 'Opprettet av kunde', value: 'createdByCustomer' },
                    { text: 'Rediger', value: 'actions', sortable: false },
                    // { text: 'Info', value: 'info', sortable: false },
                    {text: "Opprettet",sortable: true, value:"dateAdded"}];
            }
        }
        isMobile(){
            return  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
        }


        public cancelDate():void{
            this.isShowingDatePicker = false;
        }
        public saveDate(event:object, date:Date):void{
                this.newCustomer.dateOfBirth = date;
                this.isShowingDatePicker = false;
        }
        //Vuex
        @Action public addCustomer:Function;
        @Action public getCustomers:Function;
        @Action public getCurrentUser:() => Promise<IUser>;
        @Action public getCustomerJournals:Function;
        @Action public addNewCustomerJournal:Function;
        @Action public getCustomerJournal:Function;
        @Action public postSnackbarMessage:Function;
        @Action public deleteCustomer:Function;
        @Action public updateCustomer: (payload: Partial<ICustomer>) => void;

        @Action public updateCustomerJournal:Function;
        @Getter public customerJournal:ICustomerJournals[];
        @Getter public user;
        @Getter public customers;
        @Action public addToDialogQueue:Function;

        public newCustomer:Partial<ICustomer>  = {
            adminId:'',
            firstName:'',
            lastName:'',
            gender:'',
            phoneNumber:"",
            emailAddress:'',
            generalInfo: '',
            city: '',
            dateOfBirth: null
        };

        displayChoice: boolean = false;
        search: string = "";


        isLoading: boolean = true;
        localCustomersList: Partial<ICustomer>[] = [];

        //Methods
        public toggleCustomerForm():void{
            this.isShowingCustomerForm = !this.isShowingCustomerForm;
        }

        @Watch("calendarType")
        onCalendarChoiceEdit(n,o){
            localStorage.setItem('calendarmode', n);
        }

        formatDateStringHour(date: Date){
            return formatDateStringHour(date);
        }
        formatDateString(date: Date){
            return formatDateString(date);
        }


        public async toggleCustomers(customer:Partial<ICustomer>, i:number){
            if(this.toggleIndex === i){
                this.toggleIndex = null;
            }else{
                this.toggleIndex = i;
            }
        };

        public showDatePicker(formType:string):void{
            this.formType = formType;
            this.isShowingDatePicker = true;
        }




        public filterCustomers():void{
        };


        deletedCustomer(customerId){
            let customerIndex : Partial<ICustomer> = this.localCustomersList.find((x : Partial<ICustomer>) => x.id == customerId);
            let index = this.localCustomersList.indexOf(customerIndex);
            this.localCustomersList.splice(index, 1);
            this.toggleIndex = null;
        }
        // public async addNewCustomer():Promise<any>{
        //     if(this.newCustomer.firstName === ''){
        //         this.postSnackbarMessage('Navn må fylles ut.'); return;
        //     }
        //
        //     const user : IUser = this.user;
        //
        //         this.newCustomer.adminId = user.userId;
        //         // this.newCustomer.id = Math.floor(Math.random() * 100000); //Give random id
        //         // this.localCustomersList.unshift(this.newCustomer);
        //         this.postSnackbarMessage('Ny kunde lagt til.');
        //         this.isShowingCustomerForm = false;
        //
        //         let customerToAdd = this.newCustomer;
        //         const postCustomerResponse = await this.addCustomer(this.newCustomer);
        //         customerToAdd.id = postCustomerResponse.data.id;
        //         this.localCustomersList.unshift(postCustomerResponse.data);
        //
        //         this.newCustomer = { adminId:'', firstName:'', lastName:'', gender:'', phoneNumber:"", emailAddress:'', address:'', postalCOde:'', city: '', generalInfo: '', dateOfBirth: null};
        // };







        public setGender(gender:string, formType:string){
            this.formType = formType;
                this.newCustomer.gender = gender;

            this.isShowingDatePicker = false;

        }

        public editCustomerDatePicker():void{
            this.isShowingEditingDatePicker = true;
        }

        editCustomer(customer){
            this.editingCustomer = customer;
        }
        viewCustomer(customer){
            this.viewingCustomer = customer;
        }
        //Computed
        public get filteredCustomers(){
            return this.customers.filter((customer) => {
                if(customer.firstName != null){
                    return customer.firstName.toUpperCase().match(this.searchQuery.toUpperCase());
                } else {
                    return false;
                }
            });
        };


        //Lifecycles
        public async created():Promise<any>{
            this.sector = this.user.sector;
            this.isLoading = true;
            this.localCustomersList = await this.getCustomers();
            this.isLoading = false;

        }

        get hasSelectedCustomers(){
            return this.selectedCustomers.length === 0;
        }

        //TODO: Very similiar to AppointmentList.Vue -- Refactor?
        deleteSelectedCustomers(){
            let vm = this;

            let dialog : IDialog = {
                text: "Er du sikker på at du vil slette " + vm.selectedCustomers.length + " kunder?",
                type: DialogType.Choice,
                header: "Slett kunde(r)?",
                action: function(){
                        vm.postSnackbarMessage(vm.selectedCustomers.length + " kunder er slettet");
                        vm.selectedCustomers.forEach((x) => {
                            vm.deleteCustomer(x.id);
                        });
                        vm.selectedCustomers = [];
                }
            };

            this.addToDialogQueue(dialog);
        }

        onCreatedCustomer(){
            this.isShowingCustomerForm = false;
        }

    }
