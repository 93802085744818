
    import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
    import {Action, Getter} from "vuex-class";
    import {onboardingProgressType} from "@/types/OnboardingProgress";
    import {IUser} from "../../../store/user";
    import {Sector} from "@/types/Sector";


    export interface ILocalUser {
    firstName:string,
    lastName:string,
    phoneNumber:string,
    }

    @Component({
        name: 'Register-user',
    })
    export default class RegisterUser extends Vue {
        @Prop() public currentStep;


        localUser:ILocalUser = {firstName:"", lastName:"", phoneNumber:""};
        menu:boolean = false;
        modal:boolean = false;
        hasGottenUser:boolean = false;

        @Action registerUser:Function;
        @Action updateUserProfile:Function;
        @Action getCurrentUser:Function;
        @Action postSnackbarMessage:(message:string) => void;
        @Getter user:IUser;

      @Action patchUser:(payload:Partial<IUser>) => Promise<IUser>;



        validateFormFields():boolean{
            if(this.localUser.firstName === ""){
                this.postSnackbarMessage("Du må oppgi et navn ");
                return false;
            }
            if(this.localUser.lastName === ""){
                this.postSnackbarMessage("Du må oppgi et etternavn ");
                return false;
            }
            if(this.localUser.phoneNumber.length !==  8){
                this.postSnackbarMessage("Telefonnummer må være 8 siffer");
                return false;
            }
            return true;
        }

        clickHandler():void{
            this.$emit('register-user-click', this.validateFormFields());
        }



        @Watch('currentStep')
        checkElValue(newval, oldVal){
                if(oldVal === 1){
                    let update : Partial<IUser> = {
                        firstName: this.localUser.firstName,
                        lastName: this.localUser.lastName,
                        phoneNumber: this.localUser.phoneNumber
                    };
                    if(this.validateFormFields()){

                      //Information for initial staff member
                      this.updateUserProfile(update);
                    }

                }
        }

    }
