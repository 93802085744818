

    import Vue from 'vue'
    import Component from 'vue-class-component'
    import {Prop} from "vue-property-decorator";

    @Component({
        name: "ToolTip",
        components: {}
    })

    export default class ToolTip extends Vue {

        @Prop({}) text;

        created() {

        }
    }

