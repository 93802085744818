
    import {Component, Vue, Prop} from 'vue-property-decorator';
    import Sidebar from "../../components/global/Sidebar.vue";
    import HeaderBar from "./MainHeader.vue";
    import {Action, Getter} from "vuex-class";
    import {getBookingUrl} from "../../Utilities/urlUtility";
    import {IUser} from "../../store/user";

    @Component({
        name: 'appointment-booking-link',
        components: {Sidebar, HeaderBar}
    })
    export default class AppointmentBookingLink extends Vue {
        public bookingLink: string = "";
        private isShowingBookingPage: boolean = false;


        @Prop({default: false}) isShowingPreviewButton: boolean;

        //Vuex
        @Action public postSnackbarMessage: (message: string) => void;
        @Action public getCurrentUser: () => Promise<IUser>;
        @Getter public user: IUser;


        public copyLink(): void {
            const copy : any = this.$refs.copy;
            copy.select();
            document.execCommand("copy");
            this.postSnackbarMessage("Lenke er kopiert til utklippstavlen.");
        }

        gotoLink() :void{
            window.open(this.bookingLink);
        }

        private toggleBookingPage(): void {
            this.isShowingBookingPage = !this.isShowingBookingPage;
        }


        public created(): void {
            if (this.user === null) {
                this.getCurrentUser().then(() => {
                    this.bookingLink =  getBookingUrl(this.user.bookingLink);
                });
            } else {
                this.bookingLink =  getBookingUrl(this.user.bookingLink);
            }
        }

        get currentBookingLink() : string {
            return getBookingUrl(this.user.bookingLink);
        }


    }
