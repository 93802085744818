
import Vue from 'vue'
import {Component, Watch} from 'vue-property-decorator';
import {Action, Getter, Mutation} from 'vuex-class';
import {actionStringUser, getterStringUser, IUser} from "@/store/user";

import Loader from "@/components/global/Loader.vue";
import Layout from "@/components/Layout.vue";
import {ISubscriptionStatus, Subscription} from "@/types/HasSubscription";
import DialogQueueDisplay from "@/components/global/DialogQueueDisplay.vue";

import UpdateMessage from "@/components/global/UpdateMessage.vue";
import Sidebar from "./components/global/Sidebar.vue";
import MainHeader from "./components/global/MainHeader.vue";
import CustomButton from "./components/global/styledComponents/customButton.vue";
import {SnackbarObject} from "./store/snackbar";
import MobileHeader from "./components/MobileHeader.vue";
import {IAuditLogDTO} from "./types/AutoGenerated/reinforcedTypings";


@Component({
        name: 'App',
        components: {
          MobileHeader,
            CustomButton,
            MainHeader, Sidebar, UpdateMessage, DialogQueueDisplay, Loader,  Layout}
    })

    export default class App extends Vue {

  isShowingSnackbar: boolean = false;
  snackbarDuration: number = 4000;
  showUpdateMessage: boolean = false;
  snackbarTimeout: number | undefined;

  @Getter public snackbar: SnackbarObject;
  @Action public postSnackbarMessage: Function | undefined;
  @Action public getCurrentUser: Function | undefined;
  @Getter public user: IUser | undefined;
  @Getter public hasResolvedUser: boolean | undefined;
  @Action public getUserAsAnonymous: Function;
  @Getter public userToBook;
  @Getter public customerAccount;
  @Action logoutCustomerAccount;
  @Action addToDialogQueue;
  @Mutation setBookingStep;
  @Getter public bookingStep;
  @Action postAuditLog: Function;

  width: number = 0;
  height: number = 0;

  get mobileNavigation() {
    return this.$store.getters.mobileNavigation;
  }

  set mobileNavigation(v) {
    this.$store.commit("setMobileNavigation", v);
  }


  public isLoggedIn: boolean = false;
  public showMobileMenu: boolean = false;
  public group = null;


  get itemList2() {


    let mobileItems =  [
      {
        alt: 'kunder',
        text: 'Kunder',
        image: '../img/customers.png',
        image2: '../img/white-icons/customers2.png',
        route: '/kunder',
        icon: "account_circle"
      },
      {
        alt: 'bestillinger',
        children: [],
        text: 'Oversikt',
        image: '../img/overview.png',
        route: '/bestillinger',
        icon: "event_note"
      },

      {
        alt: 'innstillinger',
        text: 'Innstillinger',
        onlySmallMenu: true,
        image: '../img/settings.png',
        route: '/innstillinger',
        icon: "calendar_today"
      },
      // { alt:'Tjenester', text:'Tjenester', image:'../img/service.png', route: '/tjenester', icon: "add_shopping_cart"},
      {
        alt: 'logg',
        text: 'Logg',
        image: '../img/commerce.png',
        image2: '../img/commerce.png',
        route: '/logg',
        icon: "post"
      },
      {
        alt: 'statistikk',
        text: 'Statistikk',
        image: '../img/increasing-chart.png',
        route: '/statistikk',
        icon: "insert_chart"
      },
      // { alt:'eksporter', text:'Eksporter', image:'../img/export.png', route: '/eksporter', icon:"calendar_today"},
      // { alt:'innstillinger', text:'Innstillinger', image:'../img/white-icons/settings.png', route: '/innstillinger', icon:"calendar_today"},
      // { alt:'hendelser', text:'Hendelser', image:'../img/book.png', route: '/hendelser', icon:"inbox"},
      // { alt:'Gavekort', text:'Gavekort', image:'../img/gift-card.png', route: '/gavekort'},
    ];

    let tabletItems = [
      {
        alt: 'logg',
        text: 'Logg',
        image: '../img/commerce.png',
        image2: '../img/commerce.png',
        route: '/logg',
        icon: "post"
      },
      {
        alt: 'statistikk',
        text: 'Statistikk',
        image: '../img/increasing-chart.png',
        route: '/statistikk',
        icon: "insert_chart"
      },

    ];

    console.log("WWW: ", this.width);

    if(this.width > 800){
      return tabletItems;
    } else {
      return mobileItems;
    }

  }


  isTablet(){
    return document.documentElement.clientWidth > 750;
  }

  @Watch("showMobileMenu")
  onChangeMobileMenu() {
    if (this.showMobileMenu) {
      document.documentElement.style.overflow = "hidden";
    } else {
      document.documentElement.style.overflow = "auto";
    }
  }

  @Watch('$route')
  onChangeRoute(newRoute) {
    if (newRoute.name == "booking-page") {
      this.isLoggedIn = false;
    }
    if (newRoute.name == "customer-account") {
      this.isLoggedIn = true;
    }

  }

  @Watch('snackbar')
  onChangeSnackbar(newVal): void {

    if (newVal.message == null) {
      return;
    }
    if (this.snackbar) {
      this.isShowingSnackbar = true;
    }
    if (!this.isShowingSnackbar) {
      this.isShowingSnackbar = false;
    }

    if (this.snackbarTimeout != null) {
      clearTimeout(this.snackbarTimeout);
    }
    this.snackbarTimeout = setTimeout(() => {
      if (this.postSnackbarMessage != null) {
        this.postSnackbarMessage(null);
      }
      this.isShowingSnackbar = false;
    }, this.snackbarDuration);

  }

  isMobile(){
    return  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  }


  getDimensions() {
    this.width = document.documentElement.clientWidth;
    this.height = document.documentElement.clientHeight;
    console.log("Width: ", this.width);
  }


  public async mounted():Promise<void> {
    const vm = this;

    if (this.isMobile()) {
      this.width = document.documentElement.clientWidth;
      this.height = document.documentElement.clientHeight;
      window.addEventListener('resize', this.getDimensions);
    }
  }


    async created() {
    // let hasUpdate = localStorage.getItem('hasUpdate');
    // this.showUpdateMessage = hasUpdate !== null;

      //@ts-ignore
      if (this.$workbox) {
        //@ts-ignore
        this.$workbox.addEventListener("waiting", () => {
          //@ts-ignore
          this.$workbox.messageSW({ type: "SKIP_WAITING" });
          //This will update new version :D

         // this.showUpdateUI = true;
        });
      }

  }


  get requireAuth() {
    return this.$route.meta.requiresAuth != null && this.$route.meta.requiresAuth == true;
  }

  get hasLayout() {
    return this.$route.meta.hasLayout == null || this.$route.meta.hasLayout == true;
  }

  updateSoftware() {
    navigator.serviceWorker.ready.then(res => {
      res.waiting.postMessage({action: "skipWaiting"});
    });
    //@ts-ignore
    window.location.reload(true);
    localStorage.removeItem('hasUpdate');
  }

  get displayingSnackbar(): SnackbarObject {
    return this.snackbar;
  }

  goTo(route) {
    let audit: Partial<IAuditLogDTO> = {
      action: 3,
      type: 4,
      comment: "",
      message: " went to route from mobile menu: " + route,
      hideForUser: true,
    };

    this.postAuditLog(audit);

    this.$router.push(route);
  }

  get showMobileToolbar(){
    return this.$route.meta.hideMobileHeader == null || this.$route.meta.hideMobileHeader == false;
  }


}
