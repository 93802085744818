
    import {Component, Vue, Watch} from 'vue-property-decorator';
    import {Action, Getter, Mutation} from "vuex-class";
    import router from '../../router'
    import {IUser} from '../../store/user';
    import {logout} from "@/Utilities/authConfig";
    import {DialogType, IDialog} from "../../store/dialog";


    @Component({name:'sidebar',
        components: {}
    })
export default class Sidebar extends Vue {
    public itemlist:Array<Object> = [
        { alt:'hjem', text:'Hjem', image:'../img/dashboard.png', route: '/admin', icon: "home"},
        { alt:'kunder', text:'Kunder', image:'../img/customers.png', route: '/kunder', icon: "account_circle"},
        // { alt:'Tjenester', text:'Tjenester', image:'../img/service.png', route: '/tjenester', icon: "add_shopping_cart"},
        { alt:'kalender', text:'Kalender', image:'../img/calendars.png', route: '/kalender', icon:"calendar_today"},
        { alt:'innstillinger', text:'Innstillinger', onlySmallMenu: true, image:'../img/settings.png', route: '/innstillinger', icon:"calendar_today"},
        // { alt:'hendelser', text:'Hendelser', image:'../img/book.png', route: '/hendelser', icon:"inbox"},
        // { alt:'Gavekort', text:'Gavekort', image:'../img/gift-card.png', route: '/gavekort'},

    ];


    get appointmentTabs(){
        if(this.user == null){
            return [];
        }
        if(this.user.enablePaymentModule){
            return [
            { alt:'bestillinger', children: [], text:'Oversikt', image:'../img/overview.png', route: '/bestillinger', icon:"event_note"},
            { alt:'betalinger', text:'Betalinger', image:'../img/paymenticon.png', route: '/betalinger', icon:"event_note"},
            { alt:'faktura', text:'Faktura', image:'../img/commerce.png', route: '/faktura', icon:"event_note"},
                { alt:'statistikk', text:'Statistikk', image:'../img/increasing-chart.png', route: '/statistikk', icon:"insert_chart"},
                { alt:'eksporter', text:'Eksporter', image:'../img/export.png', route: '/eksporter', icon:"calendar_today"},
        ];
        } else {
            return [
                { alt:'bestillinger', children: [], text:'Oversikt', image:'../img/overview.png', route: '/bestillinger', icon:"event_note"},
                { alt:'statistikk', text:'Statistikk', image:'../img/increasing-chart.png', route: '/statistikk', icon:"insert_chart"},
                { alt:'eksporter', text:'Eksporter', image:'../img/export.png', route: '/eksporter', icon:"calendar_today"},
            ];
        }
    }


    public showListGroup = false;

    @Action public getCurrentUser:Function;
        @Action public addToDialogQueue:Function;
        @Action public getUnseenEvents: Function;
        @Getter public unseenEvents: Function;
        @Mutation public setMini: Function;
    @Getter public user:IUser;

        @Getter public firebaseUserAccount;

    @Getter mini: Boolean;

        public drawer:boolean = true;
        // public mini:boolean = false;

    // @Getter(getterStringUser.HAS_SUBSCRIPTION) hasSubscription:boolean;
    // @Mutation(mutationStringUser.SET_HAS_SUBSCRIPTION) setHasSubscription:(payload:boolean) => void;


      get getSidebarWidth(){
        if(this.mini){
          return 80;
        } else {
          return 256;
        }
      }

        toggleMini(){
            this.setMini(!this.mini);

            if(this.mini){
                localStorage.setItem('sidebarmini', "true");
            } else {
                localStorage.setItem('sidebarmini', "false");
            }
        }


    get displayProfilePicture(){
            return this.user != null && this.user.profilePictureUrl != null;
    }

        openChat():void{
            window.open("http://m.me/availify/",'_blank');
        }



    public goToSettingsPage():void{
            this.$router.push("/innstillinger");
    }

    public async created():Promise<void> {
        let defaultMini = localStorage.getItem('sidebarmini')
        if(defaultMini != null){
            if(defaultMini == "true"){
                this.setMini(true);
            } else {
                this.setMini(false);
            }
        }

    };

        @Watch('$route')
        onChangeRoute(newRoute, oldroute){

            if(newRoute.name == "Bestillinger" || newRoute.name == "Bestilling" || newRoute.name == "Faktura" || newRoute.name == "Statistikk" || newRoute.name == "Betalinger" || newRoute.name == "Eksporter"){
                this.showListGroup = true;
            } else {
                this.showListGroup = false;
            }


        }

}
