
// @ts-ignore
import axios, {AxiosRequestConfig} from 'axios';
// @ts-ignore
import { GetterTree, MutationTree, ActionTree } from 'vuex';
import {APIURL2} from '@/main';
import authConfig, {AuthConfig} from "@/Utilities/authConfig";



// Interface Model
export interface IMuscleCommentState {
    muscleComment: Array<IMuscleComment> | null;
}

export interface IMuscleComment{
    muscleId: number,
    isChecked: boolean,
    comment:string,
    customerId?:number
}

// Mutation enums
export const enum mutationStringMuscleComment {
    setMuscleComment = 'setMuscleComment',
}
export const enum actionStringMuscleComment{
    getMuscleComment = 'getMuscleComment',
    updateMuscleComment = 'updateMuscleComment',
}

export const state:IMuscleCommentState = {
        muscleComment: null,
};

export const getters: GetterTree<any, IMuscleCommentState> = {
    muscleComment:state => state.muscleComment,
};

export const mutations: MutationTree<IMuscleCommentState> = {
    setMuscleComment(state, payload:IMuscleComment[]) { state.muscleComment = payload; },
};

export const actions: ActionTree<IMuscleCommentState, any> = {
    async getMuscleComment({commit}, payload:string /* customerId */ ) {
        let header : AxiosRequestConfig = await authConfig();
        return new Promise((resolve, reject) => {
        axios.get(APIURL2 + 'MuscleComment/current?customerId=' + payload,
        header).then((res) => {
            commit(mutationStringMuscleComment.setMuscleComment, res.data);
            resolve(res.data);
        }).catch((err: string) => {
            console.log(err);
            reject(err);
        });
    })
    },



    async updateMuscleComment({commit, dispatch}, payload:IMuscleComment) {
        let header : AxiosRequestConfig = await authConfig();
        axios.put(APIURL2 + 'MuscleComment/current?customerId='
        + payload.customerId + "&muscleId="
        + payload.muscleId + "&isChecked="
        + payload.isChecked +  "&comment="
        + payload.comment, {},
       header).then(() => {
            // commit(mutationStringMuscleComment.setMuscleComment, res.data)
            dispatch(actionStringMuscleComment.updateMuscleComment);
        }).catch((err) => {
            console.log(err);
        });
    },


};

export const muscleComment = {
    actions,
    mutations,
    getters,
    state,
};
