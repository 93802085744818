
    import {Component, Prop, Vue} from 'vue-property-decorator';
    import Sidebar from "@/components/global/Sidebar.vue";
    import HeaderBar from "../../components/global/MainHeader.vue";
    import {AppointmentInfo} from "@/store/appointments/appointmentTypings";
    import {getRequest} from "@/Utilities/axiosHelper";
    import Loader from "@/components/global/Loader.vue";
    import {formatDate, formatTime} from "@/types/formatDateHelper";
    import CustomButton from "../../components/global/styledComponents/customButton.vue";
    import {DialogType, IDialog} from "@/store/dialog";
    import {Action, Getter, Mutation} from "vuex-class";
    import CustomerModal from "../../components/global/CustomerModal.vue";
    import CustomerModalWindow from "../../components/global/CustomerModalWindow.vue";
    import CreateAppointment from "../../components/global/CreateAppointment.vue";
    import {IUser} from "@/store/user";
    import {IAppointmentDTO, IAppointmentInfoDTO, IAuditLogDTO} from "@/types/AutoGenerated/reinforcedTypings";
    import {IAppointmentPatch} from "@/store/appointments/appointments";
    import {formatDateString, formatDateStringHour} from "@/Utilities/dateUtility";


    @Component({
        name: 'Appointment-list',
        components: {
            CreateAppointment,
            CustomerModalWindow, CustomerModal, CustomButton, Loader, Sidebar, HeaderBar}
    })
    export default class AppointmentList extends Vue {
        public toggleIndex:number | null = null;

        @Prop({}) customerId;
        @Prop({default: true}) viewCustomerDetails;
        @Prop({default: true}) showPaid;
        @Prop({default: false}) chooseAppointment;
        @Prop({default: true}) tableActions;
        @Action public addToDialogQueue:Function;
        @Action patchAppointment: Function;
        @Action public postSnackbarMessage: Function;
        @Action public deleteBookedAppointment:Function;


        @Action postAuditLog : Function;

        @Action public getAppointmentsForOverview: Function;
        @Getter public appointmentsForOverview: Function;
        @Getter public user: IUser;

        @Action updateAppointment: Function;

        @Action public selectDateToBook:Function;
        @Mutation public setDefaultCustomerToBook:Function;
        displayChoice = false;
        displayChoice2 = false;
        viewingCustomer = null;

        options = {};


        statusFilters = [
        ];

        private defaultSorting: string = "created";
        private defaultDesc: string = "true";




        get invoiceStatuses(){
            if(this.user.enablePaymentModule){
                return [
                    "Ny",
                    "Faktura opprettet",
                    "Faktura sendt",
                    "Faktura betalt",
                    "Ferdig",
                ];
            } else {
                return [
                    "Ny",
                    "Ferdig",
                ];
            }
        }


        readingNote: AppointmentInfo = null;

        appointments: AppointmentInfo[]|null = null;
        isLoading: boolean = true;

        public search: string = "";
        selectedAppointments = [];
        tags = [];
        private bookOpen: boolean = false;


        async created() {

            let overViewSorting = localStorage.getItem("overviewsorting");
            let desc = localStorage.getItem("overviewsortingdesc");
            let savedFilters = localStorage.getItem("statusfilter");
            if(savedFilters != null){
                this.statusFilters = savedFilters.split(",");
            } else {

                this.statusFilters = [
                    "Ny",
                    "Faktura opprettet",
                    "Faktura sendt",
                    "Faktura betalt",
                    "Ferdig",
                ];
            }

            if(overViewSorting != null){
                this.defaultSorting = overViewSorting;
            }
            if(desc != null){
                this.defaultDesc = desc;
            }


            let vm = this;
            await vm.getAppointments();
        }

        formatTimeCorrectly(hour:string | number, minute:string | number):string{
            return formatTime(hour, minute);
        }

        formatDateStringHour(date: Date){
            return formatDateStringHour(date);
        }
        formatDateString(date: Date){
            return formatDateString(date);
        }
        get hasAppointments():boolean{
            if(this.customerId == null){
                return this.appointmentsForOverview.length > 0 || !this.isLoading;
            } else {
                return this.appointments != null  || !this.isLoading;
            }
        }
        deletedCustomer(){
            this.viewingCustomer = null;
        }
        public get headers(){
            if(this.viewCustomerDetails){
                if(!this.displayChoice2){
                    let array = [
                        {text: "Nr",sortable: true, value:"number"},
                        {text: "Tjeneste",sortable: true, value:"appointmentName"},
                        {text: "Tidspunkt",sortable: true, value:"date"},
                        {text: "Kunde",sortable: true, value:"customerName"},
                        { text: 'Status', value: 'status', sortable: true },
                        { text: 'Kommentar', value: 'comment', sortable: false },
                        { text: 'Dine notater', value: 'adminNote', sortable: false },
                        {text: "Opprettet",sortable: true, value:"created"},
                    ];


                    if(!this.showPaid){
                        this.statusFilters = [
                            "Ny",
                        ]
                    }

                    if(this.chooseAppointment){
                        array.splice(0, 0, { text: '', value: 'selectAppointment', sortable: true })
                    }

                    return array;



                } else {

                    let array = [
                        {text: "Nr",sortable: true, value:"number"},
                        {text: "Tjeneste",sortable: true, value:"appointmentName"},
                        {text: "Tidspunkt",sortable: true, value:"date"},
                        {text: "Kunde",sortable: true, value:"customerName"},
                        {text: "Epost",sortable: true, value:"customerEmail"},
                        { text: 'Telefonnummer', value: 'customerPhone' },
                        { text: 'Adresse', value: 'customerAddress', sortable: false },
                        { text: 'Postkode', value: 'customerPostalCode', sortable: false },
                        { text: 'Lagt inn av kunde', value: 'createdByCustomer', sortable: false },
                        { text: 'Status', value: 'status', sortable: true },
                        { text: 'Kommentar', value: 'comment', sortable: false },
                        { text: 'Dine notater', value: 'adminNote', sortable: false },
                        {text: "Opprettet",sortable: true, value:"created"},
                    ];

                    if(this.chooseAppointment){
                        array.splice(0, 0, { text: '', value: 'selectAppointment', sortable: true })
                    }

                    return array;
                }

            } else {
                return [
                    {text: "Nr", sortable: true, value: "number"},
                    {text: "Tjeneste", sortable: true, value: "appointmentName"},
                    {text: "Tidspunkt", sortable: true, value: "date"},
                    // {text: 'Lagt inn av kunde', value: 'createdByCustomer', sortable: false},
                    { text: 'Status', value: 'status', sortable: true },
                    {text: 'Kommentar', value: 'comment', sortable: false},
                    { text: 'Dine notater', value: 'adminNote', sortable: false },
                    {text: "Opprettet", sortable: true, value: "created"}
                ];
            }
        }
        get appointmentsForDataTable(){
            let returnValue : any  = []; //TODO: Type appointment for overview

            if(this.customerId == null){
                 returnValue  = this.appointmentsForOverview;
            } else {
                 returnValue  = this.appointments;
            }
            let filteredByStatus =  returnValue.filter(x=> {
                return this.statusFilters.includes(x.status) || x.status == null
            });

            if(this.defaultSorting == null){
                return filteredByStatus;
            }

            let sortValue = this.defaultSorting;

            let desc = true;
            if(this.defaultDesc != null){
                desc = (this.defaultDesc == "true");
            }

            if(desc){
                return filteredByStatus.sort(this.dynamicSortDesc(sortValue));
            } else {
                return filteredByStatus.sort(this.dynamicSort(sortValue));
            }



        }
        dynamicSortDesc(property) {
            let sortOrder = 1;
            if(property[0] === "-") {
                sortOrder = -1;
                property = property.substr(1);
            }
            return function (a,b) {
                /* next line works with strings and numbers,
                 * and you may want to customize it to your needs
                 */
                let result = (a[property] > b[property]) ? -1 : (a[property] < b[property]) ? 1 : 0;
                return result * sortOrder;
            }
        }
        dynamicSort(property) {
            let sortOrder = 1;
            if(property[0] === "-") {
                sortOrder = -1;
                property = property.substr(1);
            }
            return function (a,b) {
                /* next line works with strings and numbers,
                 * and you may want to customize it to your needs
                 */
                let result = (a[property] < b[property]) ? -1 : (a[property] > b[property]) ? 1 : 0;
                return result * sortOrder;
            }
        }
        getDesc(){
            if(this.defaultDesc != null){
                return (this.defaultDesc == "true");
            }
            return true;
        }
        getSort(){
            let overViewSorting = localStorage.getItem("overviewsorting");
            if(overViewSorting != null){
                return overViewSorting;
            }
            return "";
        }

        async getAppointments(){
            if(this.customerId == null){

                this.isLoading = true;
                await this.getAppointmentsForOverview();
                this.isLoading = false;

            } else {
                this.appointments = await getRequest("Appointment/current/" + this.customerId);
                this.isLoading = false;
            }
        }
        readComment(comment){
            let dialog : IDialog = {
                text: comment,
                header: "Kundens kommentar"
            };
            this.addToDialogQueue(dialog);

        }

        readNote(appointment){
            this.readingNote = appointment;
        }

        get isReadingNote(){
            return this.readingNote != null;
        }
        set isReadingNote(value){
            if(value == false){
                this.readingNote = null;
            }
        }

        viewCustomer(customer){
            this.viewingCustomer = customer;
        }

      logShowMoreCustomerInfo(value){
        let audit: Partial<IAuditLogDTO> = {
          action: 3,
          type: 4,
          comment: "",
          message: " show more customer info: " + value,
          hideForUser: true,
        };

        this.postAuditLog(audit);
      }

        updateAdminComment(event, selectedEvent: IAppointmentInfoDTO){

            let update : Partial<IAppointmentDTO> = {
                adminNote: selectedEvent.adminNote,
            };

            const payload : IAppointmentPatch = {
                appointmentId: selectedEvent.id,
                patch: update
            };

            this.patchAppointment(payload)
        }


        onSelectedAppointment(appointment){
            this.$emit("chosen", appointment);
        }

        getColor (eventId) {
            if (eventId == "Ny") return '#E0E0E0';
            if (eventId == "Faktura opprettet") return '#a5c6d9';
            if (eventId == "Faktura sendt") return '#a5c6d9';
            if (eventId == "Ferdig") return '#a5d9af';
            if (eventId == "Faktura betalt") return '#a5d9af';
            return "grey";
        }

        async updateInvoiceStatus(event, invoice){
            let update : Partial<IAppointmentDTO> = {
                status: event,
            };

            const payload : IAppointmentPatch = {
                appointmentId: invoice.id,
                patch: update
            };

            await this.patchAppointment(payload);

            await this.getAppointments();
        }

        get hasSelectedAllFilters(){
            return this.statusFilters.length == this.invoiceStatuses.length;
        }

        bookNewAppointment(){
            if(this.customerId != null && this.customerId != 0){
                this.setDefaultCustomerToBook(this.customerId);
            } else {
                this.setDefaultCustomerToBook(null);
            }

            if(this.customerId != null && this.customerId != 0){
                let audit: Partial<IAuditLogDTO> = {
                    action: 3,
                    type: 4,
                    comment: "",
                    message: " opened new appointment @ appointment list @ customer",
                    hideForUser: true,
                };

                this.postAuditLog(audit);
            } else{
                let audit: Partial<IAuditLogDTO> = {
                    action: 3,
                    type: 4,
                    comment: "",
                    message: " opened new appointment @ appointment list",
                    hideForUser: true,
                };

                this.postAuditLog(audit);
            }

            let now = new Date();
            this.selectDateToBook({year: now.getFullYear(), month: now.getMonth(), day: now.getDate()});
            this.bookOpen = true;
        }

        async addedAppointment(){
            this.bookOpen = false;
            await this.getAppointments();
        }

        onStatusChange(event){
            if(event != null){
                localStorage.setItem('statusfilter', event);
            } else {
                localStorage.setItem('statusfilter', null);
            }
        }
        sortByChanged(event){
            if(event != null){
                localStorage.setItem('overviewsorting', event);
            } else {
                localStorage.setItem('overviewsorting', null);
            }

        }

        sortByChangedDesc(event){
            if(event != null){
                if(event){
                    localStorage.setItem('overviewsortingdesc', "true");
                } else {
                    localStorage.setItem('overviewsortingdesc', "false");
                }
            } else {
                localStorage.setItem('overviewsortingdesc',null);
            }

        }

        get hasSelectedAppointments(){
            return this.selectedAppointments.length === 0;
        }

        //TODO: Very similiar to AdminCustomers.Vue -- Refactor?
        deleteSelectedAppointments(){


            let vm = this;

            let dialog : IDialog = {
                text: "Er du sikker på at du vil slette " + vm.selectedAppointments.length + " bestillinger?",
                type: DialogType.Choice,
                header: "Slett bestilling(er)?",
                action: function(){
                  let audit: Partial<IAuditLogDTO> = {
                    action: 3,
                    type: 4,
                    comment: "",
                    message: " deleted " + vm.selectedAppointments.length + " appointments from overview list",
                    hideForUser: true,
                  };

                  vm.postAuditLog(audit);

                    vm.postSnackbarMessage(vm.selectedAppointments.length + " bestillinger er slettet");
                    vm.selectedAppointments.forEach((x) => {
                        vm.deleteBookedAppointment({appointmentId: x.id});
                    });
                    vm.selectedAppointments = [];
                }
            };

            this.addToDialogQueue(dialog);
        }




    }
