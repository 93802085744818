
    import {Component, Vue, Prop} from 'vue-property-decorator';
    import Sidebar from "../../components/global/Sidebar.vue";
    import HeaderBar from "./MainHeader.vue";
    import {Action, Getter} from "vuex-class";
    import {IUser} from "@/store/user";
    import CustomButton from "@/components/global/styledComponents/customButton.vue";
    import authConfig from "@/Utilities/authConfig";
    import {IS_DEVELOPING} from "@/main";

    @Component({
        name: 'token-link',
        components: {CustomButton, Sidebar, HeaderBar}
    })
    export default class TokenLink extends Vue {

        public copyText: string = "";
        isDeveloping:boolean = IS_DEVELOPING;
        //Vuex
        @Action public postSnackbarMessage: (message: string) => void;


        public copyLink(): void {
          const copy : any = this.$refs.copy;
          copy.select();
            document.execCommand("copy");
            this.postSnackbarMessage("Token er kopiert til utklippstavlen.");
        }

        public created(): void {
            authConfig().then((x) => {
                this.copyText = x.headers.Authorization as string;
            });
        }


    }
