

    import Vue from 'vue'
    import Component from 'vue-class-component'
    import {
        State,
        Getter,
        Action,
        Mutation,
        namespace
    } from 'vuex-class'
    import {IAppointmentTypeDTO, IStaffMemberDTO} from "../../../../types/AutoGenerated/reinforcedTypings";
    import {Prop} from "vue-property-decorator";
    import Loader from "../../../global/Loader.vue";

    @Component({
        name: "StaffMemberServicesSettings",
        components: {Loader}
    })

    export default class StaffMemberServicesSettings extends Vue {

      public loading:boolean = false;
        @Action public getAppointmentTypes:Function;
        @Action public setServiceToStaffMember:Function;
        @Getter public appointmentType:IAppointmentTypeDTO[];
        @Prop({}) staffMember: IStaffMemberDTO;

        async created() {
          this.loading= true;
            await this.getAppointmentTypes();
          this.loading= false;
        }

        getName(service :IAppointmentTypeDTO){
            return service.title;
        }

        staffMemberHasService(service: IAppointmentTypeDTO) : boolean {
               const foundService = this.staffMember.services.find((x) => {
                  return x.id == service.id
               });
               return foundService != null;
        }

        onServiceSwitch(service: IAppointmentTypeDTO, event : boolean) : void {
            const payload = {
                serviceId: service.id,
                staffMemberId: this.staffMember.id,
                enabled: event
            };
            this.setServiceToStaffMember(payload);
        }
    }

