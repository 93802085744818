

import Vue from 'vue'
import Component from 'vue-class-component'
import {
  State,
  Getter,
  Action,
  Mutation,
  namespace
} from 'vuex-class'
import {Prop, Watch} from "vue-property-decorator";
import {IUser} from "../../store/user";

@Component({
  name: "NotificationBell",
  components: {}
})

export default class NotificationBell extends Vue {

  @Action public getUnseenEvents: Function;
  @Getter public unseenEvents: number;
  @Getter public user: IUser;

  @Prop({}) useImage;
  @Prop({}) group2;
  @Prop({}) width;
  @Prop({}) height;

  get showEventBadge(){
    return this.unseenEvents > 0;
  }


  @Watch("user")
  async onUserChanged(newVal){
    let vm = this;
    if(newVal != null) {
      vm.getUnseenEvents();
    }
  }

  get router(){
    return this.$router.currentRoute;
  }
  @Watch("router")
    onNewPath(newV){
    console.log("New path: ", newV);
  }

  get hasUnseenEvents(){
    return this.unseenEvents > 0;
  }


  created() {

  }

  clickMe(){
    this.$emit("clicked");
    this.$router.push("/hendelser");
  }
}

