

    import Vue from "vue";
    import {Component} from 'vue-property-decorator';
    import Dialog from "@/components/global/Dialog.vue";
    import {IDialog} from "../../store/dialog";

    //* Component definition ************************************************************

    @Component({
        name: "DialogQueueDisplay",
        components: {Dialog}
    })

//* Class ***************************************************************************

    export default class DialogQueueDisplay extends Vue {

        get getDialogQueue(){
            return this.$store.getters.dialogQueue;
        }

         maxWidth(dialog:IDialog){
            if(dialog.fullScreen){
                return "800";
            } else{
                return "290"
            }
        }


    }

