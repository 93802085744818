
import {Component, Vue, Watch} from 'vue-property-decorator';
    import Sidebar from "../../components/global/Sidebar.vue";
    import HeaderBar from "../../components/global/MainHeader.vue";
    import {Action, Getter, Mutation} from "vuex-class";
    import MonthlyEarnings from "@/components/global/statistics/MonthlyEarned.vue";
    import AppointmentBookingLink from '../../components/global/AppointmentBookingLink.vue';
    import TokenLink from "@/components/global/TokenLink.vue";
    import {castMonthFromNumberToStringName} from "@/types/formatDateHelper";
    import Loader from "@/components/global/Loader.vue";
    import WeekCalendar from "../../components/global/calendar/WeekCalendar.vue";
    import CreateAppointment from "../../components/global/CreateAppointment.vue";
import axios, {AxiosRequestConfig} from 'axios';

import {
  IAppointmentDTO,
  IAppointmentForWeeklyCalender,
  IAuditLogDTO,
  IStaffMemberDTO, IUserDTO
} from "@/types/AutoGenerated/reinforcedTypings";
    import {formatDateString, formatOnlyHour} from "@/Utilities/dateUtility";
import {actionStringUser, getterStringUser} from "@/store/user";
import {ISubscriptionStatus} from "@/types/HasSubscription";
import {IDialog} from "@/store/dialog";
import {IAppointmentPatch} from "@/store/appointments/appointments";
import GenericDialog from "@/components/global/GenericDialog.vue";
import {APIURL2} from "@/main";
import authConfig, {getToken} from "@/Utilities/authConfig";
import googleAuthConfig from "@/Utilities/authConfig";
    @Component({
        name: 'Dashboard',
        components: {
          GenericDialog,
            CreateAppointment,
            WeekCalendar, Loader, TokenLink, MonthlyEarnings, Sidebar, HeaderBar, AppointmentBookingLink}
    })
    export default class dashboard extends Vue {


        hasLoadedStatistics = false;

        currentMonth: string = "";
        @Getter public user : IUserDTO;
        @Getter public moneyMade: number;
        @Getter public appointmentsDone: number;
        @Action getBookedAppointmentsForDay: Function;
        @Mutation public setDefaultCustomerToBook: Function;

        @Getter public appointmentsForToday;
        @Getter public latestUpdate;
      @Action public addToDialogQueue: Function;

        @Action public selectDateToBook: Function;
        @Action public postSnackbarMessage:(message:string) => void;
        @Action public getMoneyMade: Function;
        @Action public getAppointmentsDone: Function;
      @Action public getLatestUpdate: Function;
      @Action public authenticateGoogle: Function;

      @Action public getStaffMembers:Function;
      @Getter public staffMembers: IStaffMemberDTO[];
      @Action patchAppointment: Function;

        @Action postAuditLog: Function;

      @Action(actionStringUser.GET_USER_SUBSCRIPTION) getUserSubscription:() => Promise<ISubscriptionStatus>;
      @Getter(getterStringUser.SUBSCRIPTION_STATUS) subscriptionStatus:ISubscriptionStatus;

      showSubscriptionReminder = false;
      loadingToken = true;
      token = null;


      currentUser = null;

      @Watch("subscriptionStatus")
      onSubscriptionStatusChange(newValue : ISubscriptionStatus){
        console.log("New sub: ", newValue);
        if(newValue.daysLeftOfTrial <= 0 && newValue.status == "None" && false){
          this.showSubscriptionReminder = true;
        }
      }

      async grantOfflineAccess(){
        //@ts-ignore
        auth2.grantOfflineAccess().then(this.signInCallback);
      }
      async signInCallback(authResult) {

        console.log("Auth result: ", authResult);
        console.log("Auth result: ", authResult['code']);

        if (authResult['code']) {

          // Hide the sign-in button now that the user is authorized, for example:
          // $('#signinButton').attr('style', 'display: none');

          // Send the code to the server

          let header : AxiosRequestConfig = await googleAuthConfig();

          let res = await axios.post("http://locahost:51512/api/GoogleCalendar/current/code/", authResult['code'], header);

        }
      }



      gotoSubscription(){
        this.$router.push("/innstillinger/5")
      }

        public isLoading: boolean = true;
        public bookOpen: boolean = false;
        public active: boolean = false;
        public showFinishedAppointmentsInAgenda: boolean = false;
        public selected =  [];
        public showUpdateDialog =  false;
        public showGoogle = true;

        public auth2 = null;

        async getEvents() : Promise<void> {
            let date = new Date();
            await this.getBookedAppointmentsForDay(date);
        }

        public TOP_DEFAULT_CONFIG = {
            pullText: "", // The text is displayed when you pull down
            triggerText: 'Slipp for å laste inn på nytt', // The text that appears when the trigger distance is pulled down
            loadingText: 'Laster inn', // The text in the load
            doneText: '', // Load the finished text
            failText: 'Kunne ikke oppdatere', // Load failed text
            loadedStayTime: 400, // Time to stay after loading ms
            stayDistance: 50, // Trigger the distance after the refresh
            triggerDistance: 70 // Pull down the trigger to trigger the distance
        }

        async addedAppointment() {
            this.bookOpen = false;
            await this.getEvents();
        }


      get hasMultipleStaff(){
        return this.staffMembers.length > 1;
      }



      async start() {

          let vm = this;

          //@ts-ignore
          console.log("GAPI has loaded:", gapi);

        setTimeout(() => {
          //@ts-ignore
          gapi.load('auth2', function() {
            console.log("Loaded auth2")
            //@ts-ignore
            vm.auth2 = gapi.auth2.init({
              client_id: '690506393150-237e590uvdcrfsvq67if7f3j8fp0n2hh.apps.googleusercontent.com',
              scope: 'https://www.googleapis.com/auth/calendar',
              redirect_uri: 'http://localhost:8081',
              plugin_name: 'AVAILIFY'
            });

          // gapi.auth2.init({
          //   client_id: '690506393150-237e590uvdcrfsvq67if7f3j8fp0n2hh.apps.googleusercontent.com',
          //   scope: 'https://www.googleapis.com/auth/calendar',
          //   redirect_uri: 'http://localhost:8080',
          //   plugin_name: 'AVAILIFY'
          // });

        }, 1000);

          console.log("Auth2: ", vm.auth2);
        // });
      });
      }

      load_script () {
        let self = this
        return new Promise((resolve, reject) => {

          // if script is already loading via another component
          //@ts-ignore
          if ( self.is_script_loading ){
            // Resolve when the other component has loaded the script
            this.$root.$on('script_loaded', resolve)
            return
          }

          console.log("Creating element");
          let script = document.createElement('script')
          script.setAttribute('src', 'https://apis.google.com/js/client:platform.js?onload=start')
          script.async = true

         console.log("Loading script...");

          function start () {
            console.log("Starting...");

          }
          script.onload = async () => {
            /* emit to global event bus to inform other components
             * we are already loading the script */
            console.log("script on load");

            await this.start();
            //@ts-ignore
            resolve()
          }

          console.log("Append child")
          document.head.appendChild(script)

        })

      }

        async mounted(): Promise<void> {

        //  console.log("Loading script...")
        //  await this.load_script();
       //   console.log("Script loaded")



          if(this.staffMembers == null || this.staffMembers.length === 0){
            await this.getStaffMembers();
          }

          this.getUserSubscription();


            this.showFinishedAppointmentsInAgenda = false;
            let date = new Date();
            let month = date.getMonth() + 1;
            this.getEvents();
            this.currentMonth = castMonthFromNumberToStringName(month);

            this.getMoneyMade(month);
            this.getAppointmentsDone(month);
            this.hasLoadedStatistics = true;

            await this.getLatestUpdate();

            if(this.latestUpdate != null && this.latestUpdate != ''){
                this.showUpdateDialog = true;
            }
        }

        formatDateStringHour(date: Date) {
            return formatOnlyHour(date);
        }

        formatDateString(date: Date) {
            return formatDateString(date);
        }

      readComment(comment){
        let dialog : IDialog = {
          text: comment,
          header: "Kundens kommentar"
        };
        this.addToDialogQueue(dialog);

        let audit: Partial<IAuditLogDTO> = {
          action: 3,
          type: 4,
          comment: comment,
          message: " read comment from agenda",
          hideForUser: true,
        };

        this.postAuditLog(audit);
      }
      readNote(comment){
        let dialog : IDialog = {
          text: comment,
          header: "Notater"
        };
        this.addToDialogQueue(dialog);

        let audit: Partial<IAuditLogDTO> = {
          action: 3,
          type: 4,
          comment: comment,
          message: " read admin note from agenda",
          hideForUser: true,
        };

        this.postAuditLog(audit);
      }

        bookNewAppointment() {


            let audit: Partial<IAuditLogDTO> = {
                action: 3,
                type: 4,
                comment: "",
                message: " opened new appointment @ dashboard",
                hideForUser: true,
            };

            this.postAuditLog(audit);

            this.setDefaultCustomerToBook(null);

            let now = new Date();
            this.selectDateToBook({year: now.getFullYear(), month: now.getMonth(), day: now.getDate()});
            this.bookOpen = true;

        }

        gotoAppointment(num) {
          this.$store.commit("setAppointmentsSource", 'admin');
            this.$router.push("/bestillinger/" + num);
        }

        async onRefresh(loaded){
            this.isLoading = true;
            let vm = this;

            let date = new Date();
            await this.getBookedAppointmentsForDay(date);
                loaded('done');
                this.postSnackbarMessage("Oppdatert.");
        }
        loadMore(){

        }

       shadeColor(color, percent) {

        var R = parseInt(color.substring(1,3),16);
        var G = parseInt(color.substring(3,5),16);
        var B = parseInt(color.substring(5,7),16);

        //@ts-ignore
        R = parseInt(R * (100 + percent) / 100);
         //@ts-ignore
         G = parseInt(G * (100 + percent) / 100);
         //@ts-ignore
         B = parseInt(B * (100 + percent) / 100);

        R = (R<255)?R:255;
        G = (G<255)?G:255;
        B = (B<255)?B:255;

        var RR = ((R.toString(16).length==1)?"0"+R.toString(16):R.toString(16));
        var GG = ((G.toString(16).length==1)?"0"+G.toString(16):G.toString(16));
        var BB = ((B.toString(16).length==1)?"0"+B.toString(16):B.toString(16));

        return "#"+RR+GG+BB;
      }


      async toggleFinished(status: IAppointmentForWeeklyCalender){

          console.log("Status:", status);

          let newStatus = "Ny";

          if(status.status == "Ny"){
            newStatus = "Ferdig";
          }

        let update : Partial<IAppointmentDTO> = {
          status: status.status,
        };

        const payload : IAppointmentPatch = {
          appointmentId: status.appointmentId,
          patch: update
        };

        let audit: Partial<IAuditLogDTO> = {
          action: 3,
          type: 4,
          comment: "",
          message: " toggled finished from dashbaord " + status.appointmentId,
          hideForUser: true,
        };

        this.postAuditLog(audit);

        await this.patchAppointment(payload);


      }

        getAgendaColor(status :IAppointmentForWeeklyCalender) {

          let color = status.color;
            let startDate: Date = new Date(status.end);

            let now = new Date();

            return color;
            if (startDate < now) {
                return this.shadeColor(color, 50);
            } else {
                return color;

            }
        }
        isDone(item : IAppointmentForWeeklyCalender){
            return item.status == 'Ferdig'
        }

        get agendaTitle(){

          var days = ['Søndag', 'Mandag', 'Tirsdag', 'Onsdag', 'Torsdag', 'Fredag', 'Lørdag'];
          var d = new Date();
          var dayName = days[d.getDay()];

          let now = new Date();
          let date : any = now.getDate();
          let month : any = now.getMonth();
          if(month < 10){
            month = "0" + month;
          }
          if(date < 10){
            date = "0" + date;
          }
          return date + "." + month + " " + dayName;
        }

      get getStyle(){
console.log("Progres: ", this.progress);
        if(this.progress === 100){
          return "color: black"
        } else {
          return "color: black";
        }
      }

      get isMobile(){
        return  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
      }

     get completedTasks () {
        return this.appointmentsForToday.filter(task => task.status === "Ferdig").length
      }

     get remainingTasks () {
        return this.appointmentsForToday.length - this.completedTasks
      }

      get progress(){
          return this.completedTasks / this.appointmentsForToday.length * 100
      }

      getBlockedText(item : IAppointmentForWeeklyCalender) {
          if(this.hasMultipleStaff){
            return item.name + " - " + item.staffMemberName
          }
        return item.name;
      }

      onSignIn(googleUser) {
        // Useful data for your client-side scripts:
        let profile = googleUser.getBasicProfile();
        console.log("ID: " + profile.getId()); // Don't send this directly to your server!
        console.log('Full Name: ' + profile.getName());
        console.log('Given Name: ' + profile.getGivenName());
        console.log('Family Name: ' + profile.getFamilyName());
        console.log("Image URL: " + profile.getImageUrl());
        console.log("Email: " + profile.getEmail());

        // The ID token you need to pass to your backend:
        let id_token = googleUser.getAuthResponse().id_token;
        console.log("ID Token: " + id_token);
      }
    }


