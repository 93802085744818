
        "use strict";

        import Vue from "vue";
        import {Component, Watch} from 'vue-property-decorator';
        import Sidebar from "@/components/global/Sidebar.vue";
        import MainHeader from "@/components/global/MainHeader.vue";
        import {Action, Getter} from "vuex-class";
        import UpdateMessage from "@/components/global/UpdateMessage.vue";
        import {IS_DEVELOPING} from "../main";
        import {actionStringUser, getterStringUser} from "../store/user";
        import {ISubscriptionStatus, Subscription} from "../types/HasSubscription";

        //* Component definition ************************************************************

        @Component({
            name: "layout",
            components: {UpdateMessage,  MainHeader, Sidebar}
        })

//* Class ***************************************************************************

        export default class Layout extends Vue {
            @Action(actionStringUser.GET_USER_SUBSCRIPTION) getUserSubscription:() => Promise<ISubscriptionStatus>;
            @Getter(getterStringUser.SUBSCRIPTION_STATUS) subscriptionStatus:ISubscriptionStatus;
            trialHasEnded:boolean = false;

            @Watch('subscriptionStatus')
            checkForChange(newValue:ISubscriptionStatus):void{
                if(newValue.status === Subscription.Active){
                    this.trialHasEnded = false;
                }
            }

            async created():Promise<any>{
                await this.getUserSubscription();
                if(this.subscriptionStatus.status !== Subscription.CancelNextPayment &&  this.subscriptionStatus.status !== Subscription.Trial && this.subscriptionStatus.status !== Subscription.Active) {
                    if(IS_DEVELOPING == false){
                    this.trialHasEnded = true;
                    }
                }
            }

        }

    