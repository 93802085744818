import {getRequest, postRequest} from "@/Utilities/axiosHelper";
import authConfig, {AuthConfig} from "@/Utilities/authConfig";
import axios from "axios";
import {APIURL2} from "@/main";
import {customers, ICustomer, mutationStringCustomers} from "@/store/customers";
import {ICustomerAccount, ICustomerAccountLoginRequest} from "../store/customerAccounts";
import {putRequest} from "./axiosHelper";

export function getAppointmentByCancelToken(cancelId: string): Promise<any> {
    return getRequest("Appointment/cancelToken?id=" + cancelId)
}

export function cancelAppointmentByCustomerAccount(appointmentId: number, customerAccount : ICustomerAccount): Promise<any> {
    return postRequest("Appointment/cancelByCustomer?appointmentId=" + appointmentId, customerAccount);
}



export function getAllWorkingDays(): Promise<any> {
    return getRequest("WorkingDays/all/current");
}

export async function getCustomers(): Promise<ICustomer[]> {

    let customers = await getRequest("Customer/current");

    // for (let i = 0; i < customers.length; i++) {
    //     let fixedDateFormat = new Date(customers[i].dateOfBirth);
    //     customers[i].dateOfBirth = fixedDateFormat;
    // }
    return new Promise((resolve, reject) => {
        return resolve(customers);
    });
}


export async function postCustomerAccount(customerAccount): Promise<any>{
    let customer = await postRequest("CustomerAccount/?userId="+customerAccount.userId, customerAccount.appointment);
    return new Promise((resolve, reject) => {
        resolve(customer);
    });
}

export async function getCustomerAccountByPhoneNumber(phoneNumber:string): Promise<ICustomer>{
    let customer = await getRequest("CustomerAccount/byPhone?phoneNumber="+phoneNumber);
    return new Promise((resolve, reject) => {
       resolve(customer);
    });
}

export async function getCustomerAccountByPhoneNumberAndPassword(payload : ICustomerAccountLoginRequest): Promise<ICustomerAccount>{
        return new Promise(async (resolve, reject) => {
            try{
                let customer = await postRequest("CustomerAccount/byPhoneAndPassword",payload, true);
                resolve(customer);
            } catch(e){
                console.warn("THE PROMISE CAUGHT THE ERROR");
                reject(e);
            }
        });

}

export async function putCustomerAccount(payload : Partial<ICustomerAccount>): Promise<ICustomerAccount>{
    return new Promise(async (resolve, reject) => {
        try{
            let customer = await putRequest("CustomerAccount",payload);
            resolve(customer);
        } catch(e){
            console.warn("THE PROMISE CAUGHT THE ERROR");
            reject(e);
        }
    });

}

export async function sendNewPassordToCustomerAccount(payload): Promise<ICustomer>{
    return new Promise(async (resolve, reject) => {
        try{
            let customer = await postRequest("CustomerAccount/resetPassword?phoneNumber=" + payload,{});
            resolve(customer);
        } catch(e){
            console.warn("THE PROMISE CAUGHT THE ERROR");
            reject(e);
        }
    });
}
export async function sendPasswordCode(payload): Promise<ICustomer>{
    return new Promise(async (resolve, reject) => {
        try{
            let customer = await postRequest("CustomerAccount/resetPasswordByCode?code=" + payload.code + "&newPassword=" + payload.newPassword,{});
            resolve(customer);
        } catch(e){
            console.warn("THE PROMISE CAUGHT THE ERROR");
            reject(e);
        }
    });
}




export async function getCustomer(customerId: string): Promise<ICustomer[]> {

    let customer = await getRequest("Customer/" + customerId);
    return new Promise((resolve, reject) => {
        resolve(customer);
    });
}

export async function getCustomerStatistics(customerId: string): Promise<ICustomer[]> {

    let customer = await getRequest("Customer/statistics/" + customerId);

    return new Promise((resolve, reject) => {
        resolve(customer);
    });
}

