

    import Vue from 'vue'
    import Component from 'vue-class-component'
    import {Action, Getter} from 'vuex-class'
    import {IUser} from "../store/user";
    import {DialogType, IDialog} from "../store/dialog";
    import {IInsurance, InsuranceActions} from "../store/Insurances";

    @Component({
        name: "InsuranceSettings",
        components: {}
    })

    export default class InsuranceSettings extends Vue {
        public dialog: boolean = false;
        public editing: boolean = false;
        public validCreate: boolean = false;
        public creatingInsurance: Partial<IInsurance> = {};

        @Getter public user: IUser;
        @Action public addToDialogQueue: Function;
        @Action public postSnackbarMessage: Function;

        @Action(InsuranceActions.GET) getInsurances:() => Promise<Array<IInsurance>>;
        @Action(InsuranceActions.PUT) putInsurance:(insurance: Partial<IInsurance>) => Promise<IInsurance>;
        @Action(InsuranceActions.POST) postInsurance:(insurance: Partial<IInsurance>) => Promise<IInsurance>;
        @Action(InsuranceActions.GET_BY_ID) getInsuranceById:(id: number) => Promise<IInsurance>;
        @Action(InsuranceActions.DELETE_BY_ID) deleteInsuranceById:(id: number) => Promise<IInsurance>;
        @Getter public insurances: Array<IInsurance>;


        async created() {
            await this.getInsurances();
        }


        editInsurance(insurance: IInsurance){
            this.creatingInsurance = insurance;
            this.editing = true;
            this.dialog = true;
        }

        addNewInsurance(){
            this.editing = false;
            this.creatingInsurance = {};
            this.dialog = true;
        }

        get addingInsurance(){
            return this.dialog;
        }

        set addingInsurance(value){
            this.dialog = value;
        }

        async confirmEditInsurance(){
            await this.putInsurance(this.creatingInsurance);
            this.postSnackbarMessage("Forsikring redigert");
            this.dialog = false;
        }
        async createInsurance(){

            await this.postInsurance(this.creatingInsurance);
            this.postSnackbarMessage("Forsikring opprettet");
            this.dialog = false;
        }

        async deleteInsurance(id: number){
            let vm = this;
            let dialog : IDialog = {
                text: "Slette denne forsikringen?",
                action: async function(){
                    await vm.deleteInsuranceById(id);
                    vm.postSnackbarMessage("Forsikring slettet");
                },
                type: DialogType.Choice
            };

            this.addToDialogQueue(dialog);

        }
    }

