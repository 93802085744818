
import {store} from "../store/store";
import router from "@/router";
import {IUser} from "@/store/user";
import {logout} from "@/Utilities/authConfig";
import firebase from 'firebase/app';
import {IDialog} from "../store/dialog";
import {IAuditLogDTO} from "../types/AutoGenerated/reinforcedTypings";



function isMobile(){
    return  /Android|webOS|iPhone|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
}

function isiPad(){
    return  /iPad/i.test(navigator.userAgent);
}

async function onStateChanged(user ) {

    console.log("User: ", user);

    let device = "Desktop";
    if(isMobile()){
        device = "Mobile";
    }
    if(isiPad()){
        device = "iPad";
    }


    if (user) {

        // let audit: Partial<IAuditLogDTO> = {
        //     action: 3,
        //     type: 4,
        //     comment: "a",
        //     message: " logged in on " + device,
        //     hideForUser: true,
        // };
        //
        // store.dispatch("postAuditLog", audit);

        store.commit("setFirebaseUser", user);
        store.commit("setHasResolvedUser", false);

        store.commit("setIsLoggedIn", true);
        if (isNewUser(user)) {

            //We may get name and picture from google/facebook login eventually.
            let newUser: Partial<IUser> = {
                firstName: "",
                lastName: "",
                phoneNumber: "",
                emailAddress: user.email,
                profilePictureUrl: "",
                appointmentDistributionInMinutes: 60,
                sector: 0,
            };

            try{
                await store.dispatch("registerUser", newUser);
                await store.dispatch("fetchInitialUserInfo");
                router.push("/onboarding");

                store.commit("setIsNewUser", false);

            } catch(e){
                await logout();
                router.push("/");
                let dialog: IDialog = {
                    text: "Kunne ikke registrere. Vennligst kontakt oss for hjelp."
                };
                store.commit("addToDialogQueue", dialog);
                console.error(e);
            }

        } else {
            await store.dispatch("fetchInitialUserInfo");

            let lastUrl = localStorage.getItem("lasturl");
            if (lastUrl != null && lastUrl != "" && lastUrl != "undefined") {
                if(lastUrl == "/"){
                    router.push("/admin");
                } else {
                    router.push(lastUrl);
                }
            } else {
                router.push("/admin");
            }
        }

    } else {

        // let audit: Partial<IAuditLogDTO> = {
        //     action: 3,
        //     type: 4,
        //     comment: "",
        //     message: " logged OUT on " + device,
        //     hideForUser: true,
        // };
        //
        // await store.dispatch("postAuditLog", audit);

        store.commit("setHasResolvedUser", true);
        store.commit("setIsLoggedIn", false);

        let metaTags: any = router.currentRoute.meta;

        if(metaTags.requiresAuth == true){
            router.push("/")
        }
    }
}

setTimeout(() => {
    firebase.auth().onAuthStateChanged(function (user) {
        onStateChanged(user);
    });
}, 1000)



function isNewUser(user) {
    return store.getters.isNewUser;
}

