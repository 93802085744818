

import Vue from 'vue'
import Component from 'vue-class-component'
import {Action, Getter} from 'vuex-class'
import {IStaffMemberDTO} from "../../../types/AutoGenerated/reinforcedTypings";
import {Watch} from "vue-property-decorator";
import {DialogType, IDialog} from "../../../store/dialog";

@Component({
        name: "StaffMembersOverview",
        components: {}
    })

    export default class StaffMembersOverview extends Vue {
        public createNewStaffMember: boolean = false;
        public newStaffMember: Partial<IStaffMemberDTO> = null;
        @Action postStaffMember: Function;
        @Action deleteStaffMember: Function;

        @Action public getStaffMembers:Function;
        @Getter public staffMembers: IStaffMemberDTO[];
      @Action public addToDialogQueue:Function;


      @Watch("createNewStaffMember")
        onNewStaffMemberToggle(){
            this.resetNewStaffMember();
        }

        created() {
            this.getStaffMembers();
           this.resetNewStaffMember();
        }

        resetNewStaffMember(){
            this.newStaffMember = {
                firstName: "",
                lastName: "",
                phoneNumber: "",
                emailAddress: ""
            };
        }

        saveChanges(){

        }

      get headers(){
            return [{text: "", align: "start", sortable: true, value:'severity'},
                {text: "Fornavn",sortable: true, value:"firstName"},
                {text: "Etternavn",sortable: true, value:"lastName"},
                {text: "Epost",sortable: true, value:"emailAddress"},
                {text: "Telefonnummer",sortable: true, value:"phoneNumber"},
                {text: "Sist logget inn",sortable: true, value:"lastLoggedIn"},
                {text: "Opprettet", align: "start", sortable: true, value:'created'},
                {text: "Slett", align: "start", sortable: true, value:'delete'},
            ];
      }

        get items(){
            return this.staffMembers;
        }

        openNewStaffMember(){
            this.createNewStaffMember = true;
        }
        cancelNewStaffMember(){
            this.createNewStaffMember = false;
        }

        async confirmNewStaffMember(){
            await this.postStaffMember(this.newStaffMember);
            this.createNewStaffMember = false;
        }

  confirmDeleteStaff(staff){
        if(this.staffMembers.length == 1){
          let dialog:IDialog = {
            text: "You må ha minst en ansatt for at programmet skal fungere."
          }
          this.addToDialogQueue(dialog);
          return;
        }
        console.log("delet staff:", staff);
        this.deleteStaffMember(staff.id);
  }
      deleteStaff(staff){
        const vm = this;
          let dialog: IDialog = {
            text: "Vil du slette denne personale?",
            type: DialogType.Choice,
            action: function(){
              vm.confirmDeleteStaff(staff)
            }
          }
          this.addToDialogQueue(dialog);
      }
    }

