

    import Vue from 'vue'
    import Component from 'vue-class-component'
    import {
        Getter,
        Action,
    } from 'vuex-class'
    import {IUser} from "../store/user";
    import {IDialog} from "../store/dialog";

    @Component({
        name: "InvoiceSettings",
        components: {}
    })

    export default class InvoiceSettings extends Vue {
        public useKID: boolean = false;
        public kidLength: number = -1;
        public kidMod: number = -1;
        public taxPercentage: number = 25;
        public dueDateLength: number = 30;

        @Getter public user: IUser;
        @Action public patchUser: Function;
        @Action public addToDialogQueue: Function;
        @Action public postSnackbarMessage: Function;

        created() {
            this.useKID = this.user.useKID;
            this.kidLength = this.user.kidLength;
            this.kidMod = this.user.kidMod;
            this.taxPercentage = this.user.taxPercentage;
            this.dueDateLength = this.user.dueDateLength;
        }

        async saveChanges(){
            if(this.kidLength > 25 || this.kidLength < 2){
                let dialog :IDialog = {
                    text: "Feil kid-nummer lengde"
                };
                this.addToDialogQueue(dialog);
                return;
            }
            let updatedUserPayload: Partial<IUser> = {
                useKID: this.useKID,
                kidLength: this.kidLength,
                kidMod: this.kidMod,
                taxPercentage: this.taxPercentage,
                dueDateLength: this.dueDateLength
            };
            await this.patchUser(updatedUserPayload);
            this.postSnackbarMessage("Endringene lagret");
        }


        booleanToNorwegian(value: boolean): string {
            if (value) {
                return "Ja"
            } else {
                return "Nei"
            }
        }

        get typedUser() : IUser {
            return this.user;
        }
    }

