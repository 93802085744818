
    import {Component, Vue, Watch} from 'vue-property-decorator';
    import {Action, Getter, Mutation} from "vuex-class";
    import {actionStringCustomers, ICustomer} from '../../store/customers';
    import {ICustomerJournals} from '../../store/customerJournals';
    import muscleSelector from '../../components/global/muscleGroupSelector.vue';
    import DatePicker from "../../components/global/DatePicker.vue";
    import CustomInput from "../../components/global/styledComponents/CustomInput.vue";
    import {Sector} from "../../types/Sector";
    import {IUser} from "../../store/user";
    import CustomButton from "../../components/global/styledComponents/customButton.vue";
    import {DialogMutations, DialogType, IDialog} from "../../store/dialog";
    import Loader from "../../components/global/Loader.vue";
    import CustomerModal from "../../components/global/CustomerModal.vue";
    import EditCustomer from "../../components/global/EditCustomer.vue";
    import CustomerModalWindow from "../../components/global/CustomerModalWindow.vue";
    import {formatDate, FormatSpaceType, formatTime} from "../../types/formatDateHelper";
    import {getApiUrl, getStripePublicKey} from "../../main";
    import {getFbConfig} from "../../Utilities/authConfig";
    import firebase from "firebase/app"
    import {getRequest} from "../../Utilities/axiosHelper";
    import {formatDateStringHour} from "../../Utilities/dateUtility";
    import {IAuditLogDTO} from "../../types/AutoGenerated/reinforcedTypings";

    @Component({
        name: 'AuditOverview',
        components: {
            CustomerModalWindow,
            EditCustomer,
            CustomerModal, Loader, CustomButton, CustomInput, DatePicker, muscleSelector}
    })
    export default class AuditOverview extends Vue {


        public headers = [{text: "", align: "start", sortable: true, value:'type'},
            {text: "Hendelse",sortable: true, value:"message"},
            {text: "Tid",sortable: true, value:"time"},
            {text: "Kommentar",sortable: true, value:"comment"},
        ];


        @Getter public user;
        @Getter public firebaseUsers;
        @Getter public auditLog;
        @Action public getFirebaseUsersAsAdmin:Function;
        @Action public addToDialogQueue:Function;
        @Action public getAuditLog:Function;
        @Action public postAuditLog:Function;

        isLoading: boolean = true;

        statusFilters = [
            "Info",
            "Warning",
            "Error",
            "Critical",
        ];


        public async created():Promise<any>{

            let ignoreFlag = this.$route.params.ignoreFlag;
            this.getFirebaseUsersAsAdmin();
            if(ignoreFlag != null){
                await this.getAuditLog(ignoreFlag);
            } else {
                await this.getAuditLog();
            }
            this.isLoading = false;
        }


        getChipIcon (eventId) {
            if (eventId == 0) return 'mdi-account-circle'
            if (eventId == 1) return 'mdi-book-open-page-variant'
            if (eventId == 2) return 'mdi-settings'
            if (eventId == 3) return 'mdi-account-supervisor-circle'
            if (eventId == 4) return 'mdi-magnify'
            if (eventId == 5) return 'mdi-star-circle'
        }

        getColorChip (eventId) {
            if (eventId == 0) return 'green'
            if (eventId == 1) return 'orange'
            if (eventId == 2) return 'red lighten-2'
            if (eventId == 3) return 'grey darken-2'
        }

        getLabel(eventId){
            if (eventId == 0) return 'Kunde'
            if (eventId == 1) return 'Bestilling'
            if (eventId == 2) return 'Innstilling'
            if (eventId == 3) return 'Personale'
            if (eventId == 4) return 'Track'
            if (eventId == 5) return 'Tjeneste'
        }
        eventIdToString(eventId){
            if(eventId == 0){
                return "Debug log"
            }
        }
        formatDateStringHour(date: Date){
            return formatDateStringHour(date);
        }

        readComment(comment){
            let dialog : IDialog = {
                text: comment,
                fullScreen: true,
                html: true,
            };
            this.addToDialogQueue(dialog);

            let ignoreFlag : number = this.$route.params.ignoreFlag as unknown as number;
            this.getFirebaseUsersAsAdmin();
            if(ignoreFlag != 1){
                let audit: Partial<IAuditLogDTO> = {
                    action: 3,
                    type: 4,
                    comment: comment,
                    message: " leste kommentar på logg",
                    hideForUser: true,
                };

                this.postAuditLog(audit);
            }


        }

        get stripePublishKey(){
            return getStripePublicKey();
        }
        get apiUrl(){
            return getApiUrl();
        }
        get firebaseConfig(){
            return getFbConfig();
        }

    }
