

    import Vue from 'vue'
    import Component from 'vue-class-component'
    import {
        State,
        Getter,
        Action,
        Mutation,
        namespace
    } from 'vuex-class'
    import {Prop} from "vue-property-decorator";
    import {IStaffMemberDTO} from "@/types/AutoGenerated/reinforcedTypings";
    import {IStaffMemberPatch} from "@/store/staffMember";

    @Component({
        name: "StaffMemberDetailsSettings",
        components: {}
    })

    export default class StaffMemberDetailsSettings extends Vue {

        @Prop({}) staffMember : IStaffMemberDTO;

        @Action patchStaffMember: (patch: IStaffMemberPatch) => Function;

        public editingStaffMember: Partial<IStaffMemberDTO>;

        public isEditing: boolean = false;

        public color: string = "#FF0000";

        @Getter user;

        created() {
        this.color = this.staffMember.color;
        if(this.color == null){
          this.color = "#4CAF50"
        }
        }

        saveChanges(){

        }

        get title(){
            return this.staffMember.firstName + " " + this.staffMember.lastName;
        }


        get phone(){
            return this.staffMember.phoneNumber;
        }

        get email(){
            return this.staffMember.emailAddress;
        }

        editStaffMember(){
            this.isEditing = true;
            this.editingStaffMember = {
                phoneNumber: this.staffMember.phoneNumber,
                emailAddress: this.staffMember.emailAddress,
                firstName: this.staffMember.firstName,
                lastName:  this.staffMember.lastName
            };
        }
        cancelEdit(){
            this.isEditing = false;
            this.editingStaffMember = {};
        }


        async saveColor(){

          console.log("Save color: ", this.color);

          let edit  = {
            color:  this.color
          };
          let patch: IStaffMemberPatch = {
            staffMemberId: this.staffMember.id,
            patch: edit
          };
          await this.patchStaffMember(patch);
        }
        async saveChangesToStaffMember(){
            let patch: IStaffMemberPatch = {
                staffMemberId: this.staffMember.id,
                patch: this.editingStaffMember
            };
            await this.patchStaffMember(patch);
            this.cancelEdit();
        }

    }

