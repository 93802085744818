
    import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
    import {Action, Getter, Mutation} from "vuex-class";
    import {ICustomer} from '@/store/customers';
    import muscleSelector from '../global/muscleGroupSelector.vue';
    import {IInsurance, InsuranceActions} from "@/store/Insurances";
    import CreateCustomer from "./CreateCustomer.vue";
    import {ICustomerDTO} from "@/types/AutoGenerated/reinforcedTypings";
    import Loader from "./Loader.vue";
    import DatePicker from "./DatePicker.vue";
    import {IUser} from "@/store/user";

    @Component({
        name: 'EditCustomer',
        components: {CreateCustomer, Loader, DatePicker, muscleSelector}
    })
    export default class EditCustomer extends Vue {
        @Prop({}) customer : ICustomerDTO;


        @Mutation public setDefaultCustomerToBook:Function;
        @Action(InsuranceActions.GET) getInsurances:() => Promise<Array<IInsurance>>;
        @Action(InsuranceActions.PUT) putInsurance:(id: number) => Promise<IInsurance>;
        @Action(InsuranceActions.POST) postInsurance:(id: number) => Promise<IInsurance>;
        @Action(InsuranceActions.GET_BY_ID) getInsuranceById:(id: number) => Promise<IInsurance>;
        @Getter public insurances: Array<Partial<IInsurance>>;



        @Action public addCustomer:Function;
        @Action public getCustomers:Function;
        @Action public getCurrentUser:() => Promise<IUser>;
        @Action public postSnackbarMessage:Function;
        @Action public deleteCustomer:Function;
        @Action public updateCustomer: (payload: Partial<ICustomer>) => void;

        @Action public updateCustomerJournal:Function;
        @Action public addToDialogQueue:Function;
        @Getter public user;
        @Getter public customers;


        public editedCustomer:Partial<ICustomerDTO> | null = null;
        public o:Partial<ICustomerDTO> | null = null;
        public dialog:boolean = true;

        public isShowingDatePicker:boolean = false;

        @Watch("dialog")
        dialogHandler(newValue){
            if(!newValue){
                this.$emit("finish");
            }
        }
        onClose(){
            this.dialog = false;
            this.$emit("finish");
        }
        onEditedCustomer(){
            this.$emit("finish");
        }

        public async created():Promise<any> {

            await this.getInsurances();
            this.dialog = true;

            let value = this.customer.sendSmsReminder;

            this.editedCustomer = {
                dateOfBirth: this.customer.dateOfBirth,
                emailAddress: this.customer.emailAddress,
                firstName: this.customer.firstName,
                phoneNumber: this.customer.phoneNumber,
                gender: this.customer.gender,
                postalCode: this.customer.postalCode,
                address: this.customer.address,
                city: this.customer.city,
                insuranceId: this.customer.insuranceId,
                ssn: this.customer.ssn,
                id: this.customer.id,
                sendEmailReminder: this.customer.sendEmailReminder,
            };

            let o = {
                dateOfBirth: this.customer.dateOfBirth,
                emailAddress: this.customer.emailAddress,
                firstName: this.customer.firstName,
                phoneNumber: this.customer.phoneNumber,
                gender: this.customer.gender,
                postalCode: this.customer.postalCode,
                address: this.customer.address,
                city: this.customer.city,
                insuranceId: this.customer.insuranceId,
                ssn: this.customer.ssn,
                id: this.customer.id,
                sendEmailReminder: this.customer.sendEmailReminder,
                sendSmsReminder: this.customer.sendSmsReminder
            };

            this.o = o;
        }

    }
    