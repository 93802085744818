// @ts-ignore
import axios, {AxiosRequestConfig} from 'axios';
// @ts-ignore
import {ActionTree, GetterTree, MutationTree} from 'vuex';
import {APIURL2} from '@/main';
import authConfig, {AuthConfig} from "@/Utilities/authConfig";


// Interface
export interface notificationState {
    notifications: Inotifications | null;
}

// Interface Model
export interface Inotifications {
    notifications: Array<INotificationContent>;
    unreadNotificationsCount: number;
}

export interface INotificationContent{
    customerId: number,
    adminId: string | null,
    title: string,
    content: string,
    seenTime?: null | string | Date,
    hasSeen?: boolean | null
}

// Mutation enums
export const enum mutationStringNotifications {
    setNotifications = 'setNotifications',
    markAllRead = "markAllRead"
}
export const enum actionStringNotifications{
    getNotifications = 'getNotifications',
    addNotification = 'addNotification',
}

export const state:notificationState = {
    notifications: {
        notifications:[{
            customerId: 0,
            adminId: null,
            title: "",
            content: "",
            seenTime: null ,
            hasSeen: false
        }],
        unreadNotificationsCount:0,
    },
};

export const getters: GetterTree<any, notificationState> = {
    notifications:state => state.notifications,
};

export const mutations: MutationTree<notificationState> = {
    setNotifications(state, payload: Inotifications) {
        state.notifications = payload;
    },
    markAllRead(state, payload: Inotifications) {
        state.notifications.notifications.forEach(x=>x.hasSeen = true);
    },
};

export const actions: ActionTree<notificationState, any> = {
    async getNotifications({commit}) {
        let header : AxiosRequestConfig = await authConfig();
        return new Promise((resolve, reject) => {
        axios.get(APIURL2 + 'Notification/all/current',
        header).then((res) => {

            for(let i:number = 0; i < res.data.notifications.length; i++ ){
                let sendDate:Date | string = res.data.notifications[i].createdTime;
                let parsedDate:Date = new Date(sendDate);
                sendDate = parsedDate.toLocaleString("nb-NO");
                res.data.notifications[i].createdTime = sendDate.substring(0, 16);
            }
            commit(mutationStringNotifications.setNotifications, res.data);
            resolve(res.data);
        }).catch((err) => {
            console.log(err);
            reject(err);
        });
    })
    },

    async addNotification({commit, dispatch}, payload:Inotifications) {
        let header : AxiosRequestConfig = await authConfig();
        axios.post(APIURL2 + 'Notification', payload, header).then((res) => {
            commit(mutationStringNotifications.setNotifications, res.data);
            dispatch(actionStringNotifications.getNotifications);
        }).catch((err) => {
            console.log(err);
        });
    },

    async markNotificationAsRead({commit, dispatch}, payload:Inotifications) {
        commit(mutationStringNotifications.markAllRead);
        let header : AxiosRequestConfig = await authConfig();
        axios.put(APIURL2 + 'Notification/all/MarkAsRead/current', {},
       header).then((res) => {
            dispatch(actionStringNotifications.getNotifications);
        }).catch((err) => {
            console.log(err);
        });
    },


};

export const notifications = {
    actions,
    mutations,
    getters,
    state,
};
