

    import Vue from 'vue'
    import Component from 'vue-class-component'
    import {Action, Getter} from 'vuex-class'
    import {IUser} from "../store/user";
    import CustomButton from "./global/styledComponents/customButton.vue";

    @Component({
        name: "FunctionSettings",
        components: {CustomButton}
    })

    export default class FunctionSettings extends Vue {
        public dialog: boolean = false;
        public editing: boolean = false;
        public validCreate: boolean = false;

        @Getter public user: IUser;
        @Action public patchUser: Function;
        @Action public addToDialogQueue: Function;
        @Action public postSnackbarMessage: Function;



        public enablePaymentModule: boolean = false;


        created() {

            this.enablePaymentModule = this.user.enablePaymentModule;
        }




        booleanToNorwegian(value: boolean): string {
            if (value) {
                return "Ja"
            } else {
                return "Nei"
            }
        }
        booleanToOnOff(value: boolean): string {
            if (value) {
                return "På"
            } else {
                return "Av"
            }
        }


        async saveChanges() {

            let updatedUserPayload: Partial<IUser> = {
                enablePaymentModule: this.enablePaymentModule,
            };

            await this.patchUser(updatedUserPayload);

        }
    }

