
    import Vue from 'vue'
    import {Component, Watch} from 'vue-property-decorator';
    import HeaderBar from "../../components/global/MainHeader.vue";
    import selectCustomer from '../../components/global/CreateAppointment.vue';
    import {Action, Getter, Mutation} from "vuex-class";
    import WeekCalendar from "../../components/global/calendar/WeekCalendar.vue";
    import {mutationStringAppointments} from "@/store/appointments/appointments";
    import {IUserDTO} from "@/types/AutoGenerated/reinforcedTypings";

    @Component({
        name: 'Calendar-page',
        components: {WeekCalendar, HeaderBar, selectCustomer}
    })
    export default class CalendarPage extends Vue {

        //Vuex calls
        @Action public getCurrentUser: Function;
            @Mutation(mutationStringAppointments.SET_SELECTED_HOUR) setSelectedHour:(date:string | Date | null) => void;
        @Mutation(mutationStringAppointments.setBookedAppointments) setBookedAppointments:(payload: null) => void;
        @Mutation(mutationStringAppointments.setBookedAppointmentCustomers) setBookedAppointmentCustomers:(payload:any) => void;
        @Getter public user : IUserDTO;
        @Getter public selectHour;
        @Getter public bookedAppointmentCustomers;
        @Getter latestDeletedAppointment:Object | null;


            public async created(): Promise<void> {
                await this.getCurrentUser();
            }


        public destroyed() {
            this.setSelectedHour(null);
            this.setBookedAppointments(null);
        };

        @Watch('latestDeletedAppointment')
        updateAppointmentCustomerViewOnDelete(){
            this.setBookedAppointmentCustomers(null)
        }


    }
