

    import Vue from 'vue'
    import Component from 'vue-class-component'
    import {Action, Getter} from 'vuex-class'
    import {IAuditLogDTO, ICustomFieldDTO, IUserDTO} from "../../../../types/AutoGenerated/reinforcedTypings";
    import ToolTip from "../../../ToolTip.vue";
    import {VerificationType} from "../../../../store/user";
    import {Watch} from "vue-property-decorator";

    @Component({
        name: "BookingPageFieldSettings",
        components: {ToolTip}
    })

    export default class BookingPageFieldSettings extends Vue {


      @Getter user: IUserDTO;
      @Action patchUser: Function;
      @Action postAuditLog: Function;
      @Action postCustomField: Function;
      @Action putCustomField: Function;
      @Action getCustomFields : Function;
      @Getter customFields: Array<ICustomFieldDTO>


      public customerRequiredEmail: boolean = false;
      public customerRequiredEmailOptional: boolean = false;
      public customerRequiredPhoneNumber: boolean = false;
      public customerRequiredPhoneNumberOptional: boolean = false;
      public customerRequiredName: boolean = false;
      public customerRequiredAddress: boolean = false;
      public customerRequiredPostalCode: boolean = false;
      public customerRequiredCity: boolean = false;
      public customerRequiredSSN: boolean = false;
      public customerRequiredBirthdate: boolean = false;
      public customerRequiredGender: boolean = false;
      public useCustomerAccounts: boolean = false;

      public editedCustomFields : Array<ICustomFieldDTO> = [];

      public customerReceiptAndReminderMode: string = "";
      public verificationType: VerificationType = VerificationType.None;

      public showAddCustomField: boolean = false;

      public newCustomField = null;


      @Watch("customFields")
      onCustomFieldsChange(newValue){
        this.editedCustomFields = newValue;
      }

      created() {
        this.customerRequiredEmail = this.user.customerRequiredEmail;
        this.customerRequiredEmailOptional = this.user.customerRequiredEmailOptional;
        this.customerRequiredPhoneNumber = this.user.customerRequiredPhoneNumber;
        this.customerRequiredPhoneNumberOptional = this.user.customerRequiredPhoneNumberOptional;
        this.customerRequiredName = this.user.customerRequiredName;
        this.customerRequiredAddress = this.user.customerRequiredAddress;
        this.customerRequiredPostalCode = this.user.customerRequiredPostalCode;
        this.customerRequiredCity = this.user.customerRequiredCity;
        this.customerRequiredSSN = this.user.customerRequiredSSN;
        this.customerRequiredBirthdate = this.user.customerRequiredBirthdate;
        this.customerRequiredGender = this.user.customerRequiredGender;

        this.customerReceiptAndReminderMode = this.user.customerReceiptAndReminderMode;
        this.verificationType = this.user.verificationType;

        this.getCustomFields();
      }

      addCustomField() {

        this.newCustomField = {
          label: ""
        };

        this.showAddCustomField = true;
      }

      async saveChanges() {

        let audit: Partial<IAuditLogDTO> = {
          action: 1,
          type: 2,
          comment: "",
          message: " endret innstilling 'Kundefelt'",
          hideForUser: false,
        };
        this.postAuditLog(audit);

        let updatedUserPayload: Partial<IUserDTO> = {
          customerRequiredEmail: this.customerRequiredEmail,
          customerRequiredEmailOptional: this.customerRequiredEmailOptional,
          customerRequiredPhoneNumber: this.customerRequiredPhoneNumber,
          customerRequiredPhoneNumberOptional: this.customerRequiredPhoneNumberOptional,
          customerRequiredName: this.customerRequiredName,
          customerRequiredAddress: this.customerRequiredAddress,
          customerRequiredPostalCode: this.customerRequiredPostalCode,
          customerRequiredCity: this.customerRequiredCity,
          customerRequiredSSN: this.customerRequiredSSN,
          customerRequiredBirthdate: this.customerRequiredBirthdate,
          customerRequiredGender: this.customerRequiredGender,
        };

        await this.patchUser(updatedUserPayload);

        for (const x of this.editedCustomFields) {
          await this.putCustomField(x);
        }
      }

      confirmAddCustomField() {
        this.postCustomField(this.newCustomField);
        this.newCustomField = null;
        this.showAddCustomField = false;
      }
    }

