

    import Vue from 'vue'
    import Component from 'vue-class-component'
    import {Action, Getter,} from 'vuex-class'
    import {IDialog} from "../../../store/dialog";
    import {IAuditLogDTO, IUserDTO} from "../../../types/AutoGenerated/reinforcedTypings";
    import ToolTip from "../../ToolTip.vue";
    import {VerificationType} from "../../../store/user";

    @Component({
        name: "GeneralBookingSettings",
        components: {ToolTip}
    })

    export default class GeneralBookingSettings extends Vue {

        @Action addToDialogQueue: Function;
      @Action patchUser: Function;
      @Action postAuditLog: Function;
        @Getter user: IUserDTO;

        public abilityToCancel: boolean = false;
        public hoursToCancel: number = 0;
        public maxDaysInAdvanceForBooking : number  = 0;
        public minumumHourBeforeBooking : number  = 0;

        public smsVerification: boolean = false;
        public verificationType: VerificationType = VerificationType.None;
        public customerReceiptAndReminderMode: string = "";

      public sendAutomaticallyReminder : boolean = false;
      public sendAutomaticallyReceipt : boolean = false;

        created() {
            this.abilityToCancel = this.user.abilityToCancel;
            this.hoursToCancel = this.user.hoursToCancel;

            this.maxDaysInAdvanceForBooking = this.user.maxDaysInAdvanceForBooking;
            this.minumumHourBeforeBooking = this.user.minumumHourBeforeBooking;

            this.verificationType = this.user.verificationType;
            this.customerReceiptAndReminderMode = this.user.customerReceiptAndReminderMode;

          this.sendAutomaticallyReceipt = this.user.sendAutomaticallyReceipt;
          this.sendAutomaticallyReminder = this.user.sendAutomaticallyReminder;
        }


        async saveChanges() {
            let update: Partial<IUserDTO> = {
                maxDaysInAdvanceForBooking: this.maxDaysInAdvanceForBooking,
                minumumHourBeforeBooking: this.minumumHourBeforeBooking,
                abilityToCancel: this.abilityToCancel,
                hoursToCancel: this.hoursToCancel,
                verificationType: this.verificationType,
                customerReceiptAndReminderMode: this.customerReceiptAndReminderMode,
              sendAutomaticallyReceipt: this.sendAutomaticallyReceipt,
              sendAutomaticallyReminder: this.sendAutomaticallyReminder
            };


          if(this.verificationType != this.user.verificationType){
            let audit: Partial<IAuditLogDTO> = {action: 1, type: 2, comment: "", message: " endret innstilling 'Kundeverifikasjon' ", hideForUser: false,};
            this.postAuditLog(audit);
          }
          if(this.abilityToCancel != this.user.abilityToCancel){
            let audit: Partial<IAuditLogDTO> = {action: 1, type: 2, comment: "", message: " endret innstilling 'Kansellering/Endring av timer'", hideForUser: false,};
            this.postAuditLog(audit);
          }
          if(this.hoursToCancel != this.user.hoursToCancel){
            let audit: Partial<IAuditLogDTO> = {action: 1, type: 2, comment: "", message: " endret innstilling 'Kansellering minimum timer før avtalt tid' til " + this.hoursToCancel + " timer ", hideForUser: false,};
            this.postAuditLog(audit);
          }

          if(this.customerReceiptAndReminderMode != this.user.customerReceiptAndReminderMode){
            let audit: Partial<IAuditLogDTO> = {action: 1, type: 2, comment: "", message: " endret innstilling 'Påminnelser/Kvittering til kunder'", hideForUser: false,};
            this.postAuditLog(audit);
          }
          if(this.maxDaysInAdvanceForBooking != this.user.maxDaysInAdvanceForBooking){
            let audit: Partial<IAuditLogDTO> = {action: 1, type: 2, comment: "", message: " endret innstilling 'Bookingrestriksjoner' antall dager kan en kunde booke frem i tid til " + this.maxDaysInAdvanceForBooking + " dager.", hideForUser: false,};
            this.postAuditLog(audit);
          }
          if(this.minumumHourBeforeBooking != this.user.minumumHourBeforeBooking){
            let audit: Partial<IAuditLogDTO> = {action: 1, type: 2, comment: "", message: " endret innstilling 'Bookingrestriksjoner' hvor tidlig en kunde booke time før avtalen " + this.minumumHourBeforeBooking + " timer.", hideForUser: false,};
            this.postAuditLog(audit);
          }

            if((this.verificationType == VerificationType.Email || this.customerReceiptAndReminderMode == 'email') && (this.user.customerRequiredEmailOptional || !this.user.customerRequiredEmail)){
                let dialog : IDialog = {
                    text: "Epost-feltet har blitt satt til påkrevd på grunn av informasjon blir sendt via e-post."
                };
                this.addToDialogQueue(dialog);
                let update : Partial<IUserDTO> = {
                    customerRequiredEmailOptional: false,
                    customerRequiredEmail: true
                };
                await this.patchUser(update);
            }
            if(this.customerReceiptAndReminderMode == 'sms' && (this.user.customerRequiredPhoneNumberOptional || !this.user.customerRequiredPhoneNumber)){
                let dialog : IDialog = {
                    text: "Telefonnummer-feltet har blitt satt til påkrevd på grunn av at kvittering og påminnelser blir sendt via sms."
                };
                this.addToDialogQueue(dialog);
                let update : Partial<IUserDTO> = {
                    customerRequiredPhoneNumberOptional: false,
                    customerRequiredPhoneNumber: true,
                };
                await this.patchUser(update);
            }
            await this.patchUser(update);

        }

        async changeReceiptAndReminderMode(event){

        }


        get verificationOptions() {
            return [
                {value: 0, label: "Ingen verifikasjon"},
                {value: 1, label: "Epost"},
                //  {value: 2, label: "SMS"}
            ]
        }

        booleanToOnOff(value: boolean): string {
            if (value) {
                return "På"
            } else {
                return "Av"
            }
        }

      booleanToOnOff2(value: boolean): string {
        if (value) {
          return "Ja"
        } else {
          return "Nei"
        }
      }

    }

