
    import {Component, Prop, Vue} from 'vue-property-decorator';
    import CustomButton from "@/components/global/styledComponents/customButton.vue";
    import Loader from "@/components/global/Loader.vue";
    import StripePayment from "./StripePayment.vue";

    @Component({
    name:'genericdialog',
    components:{StripePayment, Loader, CustomButton}
})
export default class GenericDialog extends Vue {

      @Prop({}) model;
      @Prop({}) maxWidth;


      get enabled(){
        return this.model;
      }

      set enabled(value){
        this.$emit("exit");
      }

      get width(){
        return this.maxWidth + "px"
      }
    }
