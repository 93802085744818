
    import {Component, Prop, Vue} from 'vue-property-decorator';

    // export const enum inputTypes  {
    //     text = 'text',
    //     number = 'number',
    // };


    @Component({
        name: 'custom-input',
    })
    export default class CustomInput extends Vue {
        @Prop(String) public inputType: string;
        @Prop(String) public placeholderText: string;
        @Prop(String) public value: string;
        @Prop(Number) public width: number;
        @Prop(Number) public height: number;

        public defaultWidth: number = 100;
        public defaultHeight: number = 45;

        public get calculateWidth(): number {
            if (this.width === undefined) {
                return this.defaultWidth;
            } else {
                return this.width;
            }
        }

        public get calculateHeight(): number {
            if (this.height === undefined) {
                return this.defaultHeight;
            } else {
                return this.height;
            }
        }


        created() {
        }
    }


    //Props

