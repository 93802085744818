
    import {Component, Prop, Vue} from 'vue-property-decorator';
    import {ButtonSize} from "@/types/ButtonSize";



    @Component({
        name: 'custom-button',
    })


    export default class customButton extends Vue {
        @Prop({default:ButtonSize.LARGE}) size:ButtonSize;
        @Prop({default:'Knapp'}) buttonText:string;
        @Prop({default:true}) filled:boolean;
        @Prop({default:false}) isAlert:boolean;
        @Prop({default:false}) isGrey:boolean;
        @Prop({default:false}) isFlat:boolean;
        @Prop({default:false}) disabled:boolean;


        private classList(): string {
            let classList: Array<string> = [];

            classList.push("custom-button--" + this.size);
            // classList.push("custom-button__color--" + this.color);

            if(this.filled){
                classList.push("custom-button--" + 'filled');
            } else {
                classList.push("custom-button--" + 'not-filled');
            }
            if(this.isAlert){
                classList.push("custom-button--" + 'alert');
            }
            if(this.isGrey){
                classList.push("custom-button--" + 'grey');
            }
            if(this.isFlat){
                classList.push("custom-button--" + 'flat');
            }
            if(this.disabled){
                classList.push("custom-button--" + 'disabled');
            }

            return classList.join(" ");
        }

        public clickHandler(event):void{
            this.$emit('custom-button-click', event);
        }

    }
