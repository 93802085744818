
    "use strict";

    import Vue from "vue";
    import {Component} from 'vue-property-decorator';
    import {AppointmentInfo} from "@/store/appointments/appointmentTypings";
    import {getRequest, deleteRequest} from "@/Utilities/axiosHelper";
    import CustomButton from "@/components/global/styledComponents/customButton.vue";
    import Loader from "@/components/global/Loader.vue";
    import {getAppointmentByCancelToken} from "@/Utilities/endpoints";

    //* Component definition ************************************************************

    @Component({
        name: "cancel_appointment",
        components: {Loader, CustomButton}
    })

//* Class ***************************************************************************

    export default class CancelAppointment extends Vue {

        appointmentInfo: Partial<AppointmentInfo> = {
            appointmentName: "",
            appointmentTypeId: 0,
            customerName: "",
            customerId: 0,
            day: 0,
            hour: 0,
            id: 0,
            minute: 0,
            month: 0,
            year: 0,
            companyName: "",
            adminEmail: ""
        }

        hasConfirmed: boolean = false;
        hasError: boolean = false;
        isLoading: boolean = false;

        cancelId: string = "";
        hasExpired: boolean = false;


        getCancelId() {

        }

        async mounted() {
            const url = new URL(window.location.href);
            const cancelId =  this.$route.params.cancelId;

            if(cancelId != null){
                this.cancelId = cancelId;
            }

            try{
                let appointmentInfo: AppointmentInfo = await getAppointmentByCancelToken(this.cancelId);
                this.appointmentInfo = appointmentInfo;
            } catch(e){
                    this.hasError = true;
            }


        }

        async confirmDelete() {

            try {
                this.isLoading = true;
                await deleteRequest("Appointment/cancelToken?id=" + this.cancelId);
                this.isLoading = false;
                this.hasConfirmed = true;
            } catch (e) {
                this.hasConfirmed = true;
                this.isLoading = false;

                if(e.response.data === 'Cancellation time has expired'){
                    this.hasExpired = true;
                    this.hasConfirmed = false;


                }
            }
        }
    }

