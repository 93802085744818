
    import {Component, Vue} from 'vue-property-decorator';
    import {Action, Getter} from "vuex-class";
    import {IUser} from '@/store/user';
    import CustomButton from "@/components/global/styledComponents/customButton.vue";
    import CustomInput from "@/components/global/styledComponents/CustomInput.vue";
    import {getBookingUrl} from "@/Utilities/urlUtility";

    const STATUS_INITIAL = 0, STATUS_SAVING = 1, STATUS_SUCCESS = 2, STATUS_FAILED = 3;

    @Component({
        name: 'company-info-card',
        components: {CustomInput, CustomButton}
    })
    export default class CompanyInfoCard extends Vue {
        uploadedFiles: Array<File> = [];
        uploadError: number | null = null;
        currentStatus: number | null = null;
        uploadFieldName: string = 'photos';
        files: Array<File> | null | any = null;
        isShowingUploadSection: boolean = false;
        hasFile: boolean = false;
        isLoading: boolean = false;
        isEditingCompanyInfo:boolean = false;
        currentAdminInfo:Partial<IUser> = {
            emailAddress: '',
            treatmentAdress: '',
            phoneNumber: '',
            companyName: '',
            bankAccount: 0,
            organizationNumber: 0,
        };

        @Action public postSnackbarMessage: Function;
        @Action public getCurrentUser: Function;
        @Action public updateUserImage: (file: any) => Promise<boolean>;
        @Action patchUser:(payload:Partial<IUser>) => Promise<IUser>;
        @Getter public user: IUser;


        editBusinessInfo():void{
            this.isEditingCompanyInfo = true;
        }

        setCurrentUser():void{
            this.currentAdminInfo.emailAddress = this.user.emailAddress;
            this.currentAdminInfo.treatmentAdress = this.user.treatmentAdress;
            this.currentAdminInfo.phoneNumber = this.user.phoneNumber;
            this.currentAdminInfo.companyName = this.user.companyName;
        }

        async runUpdateCompanyInfo():Promise<any>{

            try{
                await this.patchUser(this.currentAdminInfo);
                this.isEditingCompanyInfo = false;

            } catch(error){
                console.error(error.message);
            }
        }

        mounted(){
            this.setCurrentUser();
        }



        public previewFiles(event): void {
            this.files = event.target.files[0];

            if (this.files.size > 200000) {
                this.postSnackbarMessage("Bilde må være mindre en 200 KB");
                this.hasFile = false;
                this.files = [];
                return;
            }
            if (this.files.type === "image/jpeg" || this.files.type === "image/png" || this.files.type === "image/jpg") {
                this.hasFile = true;
            } else {
                this.postSnackbarMessage("Bilde må være JPG eller PNG format ");
                this.hasFile = false;
                this.files = [];
            }
        }

        public uploadImage(): void {
            this.isLoading = true;
            let fb = new FormData();
            fb.append('file', this.files);
            this.updateUserImage(fb)
                .then(res => {
                    this.isLoading = false;
                    this.isShowingUploadSection = false;
                })
                .catch(res => {
                    this.isLoading = false;
                });
            this.hasFile = false;
        }

        public toggleUploadSection(): boolean {
            return this.isShowingUploadSection = !this.isShowingUploadSection;
        }

        get userProfilePicture() : string|undefined {
            return this.user.profilePictureUrl;
        }

        get userLink(){
            return getBookingUrl(this.user.bookingLink)
        }


    }
