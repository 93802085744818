import { render, staticRenderFns } from "./EventsOverview.vue?vue&type=template&id=4a891e3a&"
import script from "./EventsOverview.vue?vue&type=script&lang=ts&xmlns%3Av-slot=http%3A%2F%2Fwww.w3.org%2F1999%2FXSL%2FTransform&"
export * from "./EventsOverview.vue?vue&type=script&lang=ts&xmlns%3Av-slot=http%3A%2F%2Fwww.w3.org%2F1999%2FXSL%2FTransform&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports