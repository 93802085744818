
    import {Component, Vue, Watch} from 'vue-property-decorator';
    import {Action, Getter, Mutation} from "vuex-class";
    import {actionStringCustomers, ICustomer} from '../../store/customers';
    import {ICustomerJournals} from '../../store/customerJournals';
    import muscleSelector from '../../components/global/muscleGroupSelector.vue';
    import DatePicker from "../../components/global/DatePicker.vue";
    import CustomInput from "../../components/global/styledComponents/CustomInput.vue";
    import {Sector} from "../../types/Sector";
    import {IUser} from "../../store/user";
    import CustomButton from "../../components/global/styledComponents/customButton.vue";
    import {DialogMutations, DialogType, IDialog} from "../../store/dialog";
    import Loader from "../../components/global/Loader.vue";
    import CustomerModal from "../../components/global/CustomerModal.vue";
    import EditCustomer from "../../components/global/EditCustomer.vue";
    import CustomerModalWindow from "../../components/global/CustomerModalWindow.vue";
    import {formatDate, FormatSpaceType, formatTime} from "../../types/formatDateHelper";
    import {formatDateStringHour} from "../../Utilities/dateUtility";

    @Component({
        name: 'EventsOverview',
        components: {
            CustomerModalWindow,
            EditCustomer,
            CustomerModal, Loader, CustomButton, CustomInput, DatePicker, muscleSelector}
    })
    export default class EventsOverview extends Vue {


        public headers = [
            {text: "", align: "start", sortable: true, value:'severity'},
            {text: "Beskjed",sortable: true, value:"message"},
          {text: "Tid",sortable: true, value:"time"},
          {text: "Kommentar",sortable: true, value:"comment"},
            {text: "Bestilling",sortable: true, value:"messageData"},
            {text: "Kvittering",sortable: true, value:"receipt"},
            {text: "Påminnelse",sortable: true, value:"reminder"},
            {text: "Sett",sortable: true, value:"hasSeen"}
        ]


        @Getter public user;
        @Action public addToDialogQueue:Function;
        @Action public getUserEvents:Function;
        @Action public markEventsAsRead:Function;
        isLoading: boolean = true;
        events = [];
        selectedEvents = [];



        public async created():Promise<any>{
            this.events = await this.getUserEvents();
            await this.markEventsAsRead();
            this.isLoading = false;
        }


        getColor (eventId) {
            if (eventId == 0) return 'grey'
            if (eventId == 1) return 'red'
            if (eventId == 2) return 'green'
            if (eventId == 3) return 'orange'
            if (eventId == 4) return 'red'
            if (eventId == 5) return 'orange'
            if (eventId == 6) return 'green'
            if (eventId == 7) return 'green'
        }

        getLabel(eventId){
            if (eventId == 0) return 'Gjest'
            if (eventId == 1) return 'Kritisk'
            if (eventId == 2) return 'Info'
            if (eventId == 3) return 'Advarsel'
            if (eventId == 4) return 'Booking'
            if (eventId == 5) return 'Booking'
            if (eventId == 6) return 'Booking'
            if (eventId == 7) return 'Kunde'
        }
        eventIdToString(eventId){
            if(eventId == 0){
                return "Bruker prøvde å booke opptatt time."
            }
            if(eventId == 1){
                return "Noe feil skjedde med SMS sending. SMS-verifikasjon har blitt skrudd av."
            }
            if(eventId == 2){
                return "Booket time ble feilfritt automatisk lagt til inn i eksisterende kunde."
            }
            if(eventId == 3){
                return "Booket time ble automatisk lagt inn med eksisterende kunde - men det var forskjeller."
            }
            if(eventId == 4){
                return "En time har blitt kansellert."
            }
            if(eventId == 5){
                return "En time har blitt endret."
            }
            if(eventId == 6){
                return ""
            }
            if(eventId == 7){
                return "Ny kunde."
            }
        }
        formatDateStringHour(date: Date){
            return formatDateStringHour(date);
        }

      readComment(comment){
        let dialog : IDialog = {
          text: comment,
          header: "Kundens kommentar"
        };
        this.addToDialogQueue(dialog);

      }
    }
