import axios, {AxiosRequestConfig} from "axios";
import {APIURL2} from "@/main";
import authConfig, {AuthConfig} from "@/Utilities/authConfig";

export async function getRequest(request: string) : Promise<any>{

    let header : AxiosRequestConfig = await authConfig();

    return new Promise((resolve, reject) => {
        axios.get(APIURL2 + request,
            header).then((res) => {
            return resolve(res.data);
        }).catch((err) => {
            console.log(err);
            return reject(err);
        });
    });
}

export async function deleteRequest(request: string) : Promise<any>{

    let header : AxiosRequestConfig = await authConfig();

    return new Promise((resolve, reject) => {
        axios.delete(APIURL2 + request,
            header).then((res) => {
            return resolve(res.data);
        }).catch((err) => {
            console.log(err);
            return reject(err);
        });
    });
}

export async function postRequest(action: string,body: {}, credentials: boolean = false) : Promise<any>{

    let options : AxiosRequestConfig = await authConfig();

    return new Promise((resolve, reject) => {
        axios.post(APIURL2 + action ,
            body, options).then((res) => {
            return resolve(res.data);
        }).catch((err) => {
            console.log(err);
            return reject(err);
        });
    });
}

export async function putRequest(action: string,body: {}) : Promise<any>{

    let header : AxiosRequestConfig = await authConfig();

    return new Promise((resolve, reject) => {
        axios.put(APIURL2 + action ,
            body, header).then((res) => {
            return resolve(res.data);
        }).catch((err) => {
            console.log(err);
            return reject(err);
        });
    });
}




