

    import Vue from 'vue'
    import Component from 'vue-class-component'
    import {
        Getter,
        Action,
    } from 'vuex-class'
    import {IWorkHourSplit} from "@/Utilities/workingHourUtilities";
    import {DialogType, IDialog} from "@/store/dialog";
    import {IUser} from "@/store/user";
    import ToolTip from "../../ToolTip.vue";
    import {IAuditLogDTO} from "@/types/AutoGenerated/reinforcedTypings";

    @Component({
        name: "BookingDistribution",
        components: {ToolTip}
    })

    export default class BookingDistribution extends Vue {

        @Action public patchUser: Function;
        @Action public addToDialogQueue: Function;
      @Action public getCurrentUser: Function;
      @Action public postAuditLog: Function;
        @Action public postSnackbarMessage: Function;
        @Getter public user: IUser;

        public buttonIndex = 0;

        private appointmentDistributionSplit: Array<IWorkHourSplit> = [
            {number: 15, isActive: false, buttonIndex: 0},
            {number: 30, isActive: false, buttonIndex: 1},
            {number: 60, isActive: false, buttonIndex: 2},
            {number: 90, isActive: false, buttonIndex: 3}
        ];


        created() {
            this.updateButtonIndex();
        }

       updateButtonIndex(){
            let index = this.appointmentDistributionSplit.find((x) => {
                return x.number === this.user.appointmentDistributionInMinutes;
            });
            if(index != null){
                this.buttonIndex = index.buttonIndex;
            }
            return 0;
        }


        public setAppointmentDistribution(selectedSplit: number): void {

            let vm = this;

            let dialog : IDialog = {
                text: "Er du sikker på at du vil endre oppdelingen av timer til " + selectedSplit + " minutter?",
                type: DialogType.Choice,
                header: "Endre time format",
                cancelAction: async function(){
                    vm.updateButtonIndex();
                },
                action: async function(){


                    let audit: Partial<IAuditLogDTO> = {action: 1, type: 2, comment: "", message: " endret timeoppdeling til " + selectedSplit + " minutter for booking", hideForUser: false,};
                    vm.postAuditLog(audit);

                    let update : Partial<IUser> = {
                        appointmentDistributionInMinutes: selectedSplit
                    };
                    await vm.patchUser(update);
                    await vm.getCurrentUser();
                    vm.updateButtonIndex();

                    //#3) working hours based on the new split
                   // vm.postSnackbarMessage('Oppdatert time format');
                }
            };

            this.addToDialogQueue(dialog);

        }
    }

