
        "use strict";
    
        import Vue from "vue";
        import { Component } from 'vue-property-decorator';
        import HeaderBar from "../../components/global/MainHeader.vue";

        //* Component definition ************************************************************
                
        @Component({
            name: "gift-card",
            components: {HeaderBar}
        })
    
//* Class ***************************************************************************
    
        export default class GiftCard extends Vue {
        }
    
    