

    import Vue from 'vue'
    import Component from 'vue-class-component'
    import {Action, Getter} from 'vuex-class'
    import {formatDate, FormatSpaceType} from "../types/formatDateHelper";
    import {Prop} from "vue-property-decorator";
    import {IDialog} from "../store/dialog";
    import {AppointmentInfo} from "../store/appointments/appointmentTypings";
    import {IUser} from "../store/user";
    import {IPayment, PaymentActions, PaymentType} from "../store/Payments";
    import Loader from "./global/Loader.vue";

    @Component({
        name: "PaymentList",
        components: {Loader}
    })

    export default class PaymentList extends Vue {

        // @Action(InvoiceActions.GetInvoicesForCustomer) public getInvoicesForCustomer:(appointmentId: number) => Promise<Array<IInvoice>>;

        @Action public addToDialogQueue:Function;
        @Action public postSnackbarMessage: Function;

        @Prop({default: []}) payments: Array<IPayment>;
        @Prop({default: true}) showAllInfo: boolean;
        @Prop({default: false}) showCustomerInfo: boolean;
        @Prop({default: false}) isLoading: boolean;

        @Getter public user : IUser;

        public headers = [];

        async created() {

            if(this.showCustomerInfo){
                this.headers = [
                    {text: "Dato",sortable: true, value:"date"},
                    {text: "Beløp.", align: "start", sortable: true, value:'amount'},
                    {text: "Bestillingnr", align: "start", sortable: true, value:'appointmentNumber'},
                    {text: "Type",sortable: true, value:"type"},
                ];
                return;
            }

                if(this.showAllInfo){
                this.headers = [
                    {text: "Dato",sortable: true, value:"date"},
                    {text: "Beløp.", align: "start", sortable: true, value:'amount'},
                    {text: "Bestillingnr", align: "start", sortable: true, value:'appointmentNumber'},
                    {text: "Kunde",sortable: true, value:"customer"},
                    {text: "Type",sortable: true, value:"type"},
                ];
            } else {
                this.headers = [
                    {text: "Dato",sortable: true, value:"date"},
                    {text: "Beløp.", align: "start", sortable: true, value:'amount'},
                    {text: "Type",sortable: true, value:"type"},
                ];
            }
        }

        get paymentsForDataTable() : Array<IPayment> {
            return this.payments;
        }

        getPaymentNameByType(type: PaymentType){
            if(type == PaymentType.Invoice) return "Faktura";
            if(type == PaymentType.Card) return "Kort";
            if(type == PaymentType.Cash) return "Kontant";
            if(type == PaymentType.Giftcard) return "Gavekort";
            if(type == PaymentType.Vipps) return "Vipps";
        }

        formatDateDot(date: Date){
            if(date == null){
                return "";
            }

            let toDateObject = new Date(date as unknown as string);
            return formatDate(toDateObject, FormatSpaceType.DOT);
        }

        get paymentSum(){
            let total = 0
            for ( let i = 0, _len = this.payments.length; i < _len; i++ ) {
                total += this.payments[i].amount
            }
            return total
        }


        formatNumber(v){
            let value = (v).toLocaleString('no-NO', {
                style: 'currency',
                currency: 'NOK',
            }); /* $2,500.00 */
            value = value.replace(",", " ");
            value = value.replace(".", ",");

            return value;
        }

        get hasPayments(){
            return this.payments.length > 0 || !this.isLoading;
        }

    }
