
    import {Component, Vue} from 'vue-property-decorator';
    import {Action, Mutation} from "vuex-class";
    import FrontPageHeader from '../../components/global/FrontPageHeader.vue';
    import App from "@/App.vue";
    import CustomInput from '../../components/global/styledComponents/CustomInput.vue';
    import CustomButton from "@/components/global/styledComponents/customButton.vue";
    import {ISector, Sector} from "@/types/Sector";
    import {DialogMutations, DialogType, IDialog} from "@/store/dialog";
    import {mutationStringUser} from "../../store/user";
    import RegisterFirebaseAccount from "../../components/RegisterFirebaseAccount.vue";
    import firebase from 'firebase/auth'; //TODO: Super WTF. If I change this to "firebase/app" -> Then authHandler.ts' firebase reference will break. WTF!?
    @Component({
        name:'Login',
        components: {RegisterFirebaseAccount, CustomButton, FrontPageHeader, App, CustomInput},
    })
    export default class Register extends Vue{

        isShowingForgotPasswordField:boolean = false;
        error:string = '';

        isLoading:boolean = false;
        pickedSector:number | null = null;
        pickedSectorToRead:null | Sector = null;
        dialog:boolean = false;


        goToLogin():void{
            this.$router.push("/login")
        }

        public goToLandingPage():void{
            this.$router.push("/");
        }


    }
