
import { Component, Vue, Prop } from 'vue-property-decorator';
import {Getter,  Action} from "vuex-class";
import CustomButton from "../../global/styledComponents/customButton.vue";
import Loader from "../../global/Loader.vue";
import {IServiceCategory} from "@/store/appointmentTypes";
import {IAppointmentTypeDTO, IStaffMemberDTO} from "@/types/AutoGenerated/reinforcedTypings";
import {DialogMutations, DialogType, IDialog} from "@/store/dialog";

@Component({
    name:'treatment_types',
    components:{Loader, CustomButton}
})
export default class TreatmentTypes extends Vue {
    //Props
  @Prop({default: false}) public isOnboarding;
  @Prop({default: false}) public extra;


    public newAppointmentType:Partial<IAppointmentTypeDTO> = {};
    public isShowingAppointmentTypeForm:boolean = false;
    isLoading:boolean = true;

    //Vuex calls
    @Action public getAppointmentTypes:(payload: boolean) => Promise<any>;
  @Getter public appointmentType:Array<IAppointmentTypeDTO>;
  @Getter public extraServices:Array<IAppointmentTypeDTO>;

    @Action public deleteAppointmentType:Function;
    @Action public editAppointmentType:Function;
    @Action public addNewAppointmentType:(payload:any) => Promise<any>;
    @Action public getCurrentUser:Function;
    @Getter public user;
    @Getter public categories : Array<IServiceCategory>;
    @Action public getCategories:() => Promise<Array<IServiceCategory>>;
    @Action public postSnackbarMessage:(message:string) => void;
    @Action public changeCategoryOfService:(payload) => void;
    @Getter public staffMembers: IStaffMemberDTO[];

  @Action public changeServiceOrder:(payload) => Promise<any>;
  @Action public changeExtraService:(payload) => Promise<any>;

    public images = [
        "default_service_img",
        "doctor_appointment",
        "illustration",
        "trial_appointment",
        "physical_therapist",
        "floating"
    ]

    public appointmentImage: string = "default_service_img";
    private editingService: Partial<IAppointmentTypeDTO> = null;
    private isEditing: boolean = false;
    private discount: boolean = false;
    public autoApply: boolean = true;
    private isCreating: boolean = false;


  get hasMultipleStaff(){
    return this.staffMembers.length > 1;
  }

    //Methods
    public toggleAppointmentForm():void{
        this.editingService = {
            discountPrice: -1
        };
        this.isCreating = true;
        this.isShowingAppointmentTypeForm = !this.isShowingAppointmentTypeForm;
    };

    public destroyed() :void{
        this.appointmentType.forEach((x:any) => {
            x.isShowing = false;
        });
    }

    async runAddNewAppointmentType(appointmentType:Partial<IAppointmentTypeDTO>):Promise<void>{
        if(appointmentType.title == null || appointmentType.title == ""){
            this.postSnackbarMessage("Navn på oppgis");
            return;
        }
        //@ts-ignore
        if(appointmentType.price == null || appointmentType.price == ""){
            appointmentType.price = 0;
        }

        console.log("appointment type:", appointmentType);

        if(appointmentType.color != null){
          //@ts-ignore
        appointmentType.color = appointmentType.color.hex;
        } else {
          appointmentType.color = "";
        }

        if(appointmentType.showCustomColor == null){
          appointmentType.showCustomColor = false;
        }

        if(appointmentType.durationInMinutes == null){
            this.postSnackbarMessage("Varighet i minutter må oppgis");
            return;
        }

        if(isNaN(appointmentType.durationInMinutes)){
            this.postSnackbarMessage("Varighet i minutter må være et tall");
            return;
        }

        if(isNaN(appointmentType.price)){
            this.postSnackbarMessage("Pris må være et tall");
            return;
        }


        if(appointmentType.price < 0){
            this.postSnackbarMessage("Pris kan ikke være negativ");
            return;
        }
        if(appointmentType.durationInMinutes <= 0 && !this.extra){
            this.postSnackbarMessage("Feil varighet");
            return;
        }
        if(appointmentType.durationInMinutes > 720){
            this.postSnackbarMessage("En enkelt tjeneste kan ikke vare lengere enn 12 timer");
            return;
        }
        this.isLoading = true;

        appointmentType.isExtraService = this.extra;
        //appointmentType.Image = this.appointmentImage;
        this.isShowingAppointmentTypeForm = false;

        await this.addNewAppointmentType(appointmentType);

        this.isCreating = false;
        this.editingService = null;
        this.appointmentImage = null;
        this.clearForm();
        this.isLoading = false;
    };

    clearForm():void{
        this.newAppointmentType.title = '';
        this.newAppointmentType.price = null;
        this.newAppointmentType.description = "";
        this.newAppointmentType.durationInMinutes = null;
        this.newAppointmentType.autoApply = true;
    }

     async runDeleteAppointmentType(appointmentTypeId:string):Promise<void>{
        const vm = this;

         let dialog : IDialog = {
             text: "Er du sikker på at du vil slette denne tjenesten?",
             type: DialogType.Choice,
             header: "Slett tjeneste",
             action: function(){
                 vm.deleteAppointmentType(appointmentTypeId);
             }
         };
         vm.$store.commit(DialogMutations.AddToDialogQueue, dialog);

    }
    editService(service){
        this.isEditing = true;
        this.editingService = service;
        this.discount = this.editingService.discountPrice != -1;
    }
    runEditService(){
        this.editAppointmentType(this.editingService);
        this.isEditing = false;
        this.editingService = null;
    }

    runCreateService(){

        this.runAddNewAppointmentType(this.editingService);
    }

    async created():Promise<void> {
        this.getCurrentUser();
        if(this.appointmentType.length == 0){
            this.isLoading = true;
        }
        if(this.categories == null || this.appointmentType == null){
            await this.getAppointmentTypes(this.extra);
            if(!this.extra){
              await this.getCategories();
            }
            this.isLoading = false;
        } else {
            this.isLoading = false;
        }

    }

    get services(){
      if(this.extra) return this.extraServices;
      return this.appointmentType;
    }
    getImgUrl(pet) {
        let images = require.context('@/../public/img/', false, /\.png$/)
        return images('./' + pet + ".png")
    }

    selectImage(image){
        this.appointmentImage = image;
    }

  async reOrder(serviceId, newOrder){
    await this.changeServiceOrder({serviceId: serviceId, order: newOrder})
  }


    get categoryOptions(){
        return this.categories.map(x=>x.title);
    }

  getExtraServiceTitleById(id){
    const cat = this.extraServices.find(x=>x.id == id);
    if(cat == null) return "";
    return cat.title;
  }

    getCategoryTitleById(id){
        const cat = this.categories.find(x=>x.id == id);
        if(cat == null) return "";
        return cat.title;
    }
    getCategoryIdByTitle(title){
        const cat = this.categories.find(x=>x.title == title);
        if(cat == null) return -1;
        return cat.id;
    }

  updateExtraServices(extraService, serviceId){

      console.log("extra service: ", extraService);
    console.log("Service id: ", serviceId);
    let payload = {serviceId: serviceId, extraServices: extraService}
    this.changeExtraService(payload);
  }

    changeCategory(categoryName, serviceId){
        let categoryId = this.getCategoryIdByTitle(categoryName);

        this.changeCategoryOfService({serviceId: serviceId, categoryId: categoryId, order: 1});
    }

    get showEditDialog(){
        return this.isEditing || this.isCreating;
    }

    set showEditDialog(value){
        this.isEditing = value;
        this.isCreating = value;
    }

    booleanToNorwegian(value: boolean): string {
        if (value) {
            return "Ja"
        } else {
            return "Nei"
        }
    }

}
