import { render, staticRenderFns } from "./GenericDialog.vue?vue&type=template&id=89ade422&scoped=true&"
import script from "./GenericDialog.vue?vue&type=script&lang=ts&"
export * from "./GenericDialog.vue?vue&type=script&lang=ts&"
import style0 from "./GenericDialog.vue?vue&type=style&index=0&id=89ade422&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "89ade422",
  null
  
)

export default component.exports