
// @ts-ignore
import axios, {AxiosRequestConfig} from 'axios';
// @ts-ignore
import { GetterTree, MutationTree, ActionTree } from 'vuex';
import {APIURL2} from '@/main';
import authConfig, {AuthConfig} from "@/Utilities/authConfig";
import {actionStringSnackbar} from "@/store/snackbar";
import {getCustomers} from "@/Utilities/endpoints";
import {mutationStringSnackbar} from "./snackbar";

// Interface
export interface InsuranceState {
    insurances: Array<IInsurance>
}

export enum InsuranceMutations{
    SetInsurances = "setInsurances",
}

export enum InsuranceActions{
    GET = "getInsurances",
    POST = "postInsurance",
    PUT = "putInsurance",
    GET_BY_ID = "getInsuranceById",
    DELETE_BY_ID = "deleteInsuranceById",
}

// Interface Model
export interface IInsurance {
    id: number;
    userId: string;
    name: string;
    address: string;
    emailAddress: string;
    phoneNumber: string;
    postalCode: string;
    city: string;
    country: string;
    bankAccount: string;
}


export const state : InsuranceState = {
    insurances: [],

};

export const getters: GetterTree<InsuranceState, any> = {
    insurances: (state) => state.insurances,
};

export const mutations: MutationTree<InsuranceState> = {
    setInsurances(state : InsuranceState, payload : Array<IInsurance>){
        state.insurances = payload;
    },
};

export const actions: ActionTree<InsuranceState, any> = {


    async getInsurances({commit}): Promise<any> {
        let header: AxiosRequestConfig= await authConfig();

        return new Promise((resolve, reject) => {
            axios.get(APIURL2 + 'Insurance/current', header).then((res) => {
                resolve(res.data);
                commit(InsuranceMutations.SetInsurances, res.data);
            }, (error) => {
                console.error(error);
                commit(mutationStringSnackbar.setSnackbar, "Kunne hente forsikringer.")
            }).catch((err) => {
                console.log(err);
                reject(err);
            });
        });
    },
    async getInsuranceById({commit}, id: number): Promise<any> {
        let header: AxiosRequestConfig= await authConfig();

        return new Promise((resolve, reject) => {
            axios.get(APIURL2 + 'Insurance/id/current?id='+id, header).then((res) => {
                resolve(res.data);
            }, (error) => {
                console.error(error);
                commit(mutationStringSnackbar.setSnackbar, "Kunne hente forsikring.")
            }).catch((err) => {
                console.log(err);
                reject(err);
            });
        });
    },

    async deleteInsuranceById({commit, dispatch}, id: number): Promise<any> {
        let header: AxiosRequestConfig= await authConfig();

        return new Promise((resolve, reject) => {
            axios.delete(APIURL2 + 'Insurance/current?id='+id, header).then((res) => {
                dispatch(InsuranceActions.GET);
                resolve(res.data);
            }, (error) => {
                console.error(error);
            }).catch((err) => {
                console.log(err);
                reject(err);
            });
        });
    },


    async postInsurance({commit, dispatch}, dto: IInsurance): Promise<any> {
        let header: AxiosRequestConfig= await authConfig();
        return new Promise((resolve) => {
            axios.post(APIURL2 + 'Insurance/current', dto, header).then((res) => {
                dispatch(InsuranceActions.GET);
                resolve(res.data);
            }, ()=> {
                commit(mutationStringSnackbar.setSnackbar, "Kunne ikke opprette forsikring")
            });
        });
    },

    async putInsurance({commit, dispatch}, dto: IInsurance): Promise<any> {
        let header: AxiosRequestConfig= await authConfig();
        return new Promise((resolve) => {
            axios.put(APIURL2 + 'Insurance/current', dto, header).then((res) => {
                dispatch(InsuranceActions.GET);
                resolve(res.data);
            }, ()=> {
                commit(mutationStringSnackbar.setSnackbar, "Kunne ikke redigere forsikring")
            });
        });
    },

};

export const insurances = {
    actions,
    mutations,
    getters,
    state,
};
