

    import Vue from 'vue'
    import Component from 'vue-class-component'
    import {
        State,
        Getter,
        Action,
        Mutation,
        namespace
    } from 'vuex-class'
    import ToolTip from "../../../ToolTip.vue";
    import {IAuditLogDTO, IUserDTO} from "../../../../types/AutoGenerated/reinforcedTypings";
    import {IUser} from "../../../../store/user";

    @Component({
        name: "BookingPageInformationSettings",
        components: {ToolTip}
    })

    export default class BookingPageInformationSettings extends Vue {

        public showInformationBox: boolean = false;
        public informationBoxText: string = "";

      @Action public postSnackbarMessage: Function;

      @Getter user : IUserDTO;
      @Action patchUser: Function;
      @Action postAuditLog: Function;



        created() {
            this.showInformationBox = this.user.showInformationBox;
            this.informationBoxText = this.user.informationBoxText;
        }
        async saveChanges(){

          if(this.showInformationBox != this.user.showInformationBox){
            let audit: Partial<IAuditLogDTO> = {action: 1, type: 2, comment: "", message: " endret innstilling 'Vis Informasjonsboks' til " + this.booleanToOnOff(this.showInformationBox), hideForUser: false,};
            this.postAuditLog(audit);
          }
          if(this.informationBoxText != this.user.informationBoxText){
            let audit: Partial<IAuditLogDTO> = {action: 1, type: 2, comment: "", message: " endret teksten i informasjonsboksen.", hideForUser: false,};
            this.postAuditLog(audit);
          }

            let update: Partial<IUserDTO> = {
                showInformationBox: this.showInformationBox,
                informationBoxText : this.informationBoxText,
            };

            this.patchUser(update);
        }

        booleanToOnOff(value: boolean): string {
            if (value) {
                return "På"
            } else {
                return "Av"
            }
        }


      get isOldDomain(){
        return window.location.toString().includes("vergba");
      }
    }

