
    import {Component, Vue, Watch} from 'vue-property-decorator';
import Sidebar from "../../components/global/Sidebar.vue";
import HeaderBar from "../../components/global/MainHeader.vue";
import TreatmentTypes from "../../components/Settings/Services/TreatmentTypes.vue";
import SelectWorktime from "@/components/global/selectWorktime.vue";
import AppointmentBookingLink from "@/components/global/AppointmentBookingLink.vue";
import CompanyInfoCard from "@/components/global/CompanyInfoCard.vue";
import {askForPermissioToReceiveNotifications} from "@/Utilities/authConfig";
import SmsSettings from "../../components/global/SmsSettings.vue";
import Services from "./services.vue";
import {Action, Getter} from "vuex-class";
import InvoiceSettings from "../../components/InvoiceSettings.vue";
import InsuranceSettings from "../../components/InsuranceSettings.vue";
import FunctionSettings from "../../components/FunctionsSettings.vue";
import {IUser} from "../../store/user";
import SettingLink from "../../components/Settings/SettingLink.vue";
import SettingCategory from "../../components/Settings/Services/SettingCategory.vue";
import VacationDays from "../../components/Settings/StaffMembers/StaffMember/VacationDays.vue";
import WorkingHours from "../../components/Settings/StaffMembers/StaffMember/WorkingHours.vue";
import ServiceCategories from "../../components/global/ServiceCategories.vue";
import SubscriptionInformation from "../../components/Settings/SubscriptionInformation.vue";
import AccountSettings from "../../components/Settings/AccountSettings.vue";
import BookingDistribution from "../../components/Settings/Booking/BookingDistribution.vue";
import BookingNotificationSettings from "../../components/Settings/Booking/BookingNotificationSettings.vue";
import BookingPageFieldSettings from "../../components/Settings/Booking/Booking-page/BookingPageFieldSettings.vue";
import GeneralBookingSettings from "../../components/Settings/Booking/GeneralBookingSettings.vue";
import BookingPageGeneralSettings from "../../components/Settings/Booking/Booking-page/BookingPageGeneralSettings.vue";
import ServiceOptions from "../../components/Settings/Services/ServiceOptions.vue";
import StaffMembersSettings from "../../components/Settings/StaffMembers/StaffMembersOverview.vue";
import {IStaffMemberDTO} from "../../types/AutoGenerated/reinforcedTypings";
import StaffMemberDetailsSettings
    from "../../components/Settings/StaffMembers/StaffMember/StaffMemberDetailsSettings.vue";
    import StaffMemberServicesSettings
        from "../../components/Settings/StaffMembers/StaffMember/StaffMemberServicesSettings.vue";
    import PersonalAccountSettings from "../../components/Settings/PersonalAccountSettings.vue";
    import Loader from "../../components/global/Loader.vue";
    import BookingPageInformationSettings
      from "../../components/Settings/Booking/Booking-page/BookingPageInformationSettings.vue";
    import CalendarStyleSettings from "../../components/Settings/Calendar/CalendarStyleSettings.vue";
    import EmailListSettings from "../../components/Settings/Functions/EmailListSettings.vue";
    import BookingPageCustomerSettings
      from "../../components/Settings/Booking/Booking-page/BookingPageCustomerSettings.vue";
    import StaffMemberGoogleCalendarSettings
      from "@/components/Settings/StaffMembers/StaffMember/StaffMemberGoogleCalendarSettings.vue";
    import ExtraServices from "@/components/Settings/Services/ExtraServices.vue";
    import Resources from "@/components/Settings/Services/Resources.vue";

@Component({
    name:'settings-compnent',
    components:{
      Resources,
      ExtraServices,
      StaffMemberGoogleCalendarSettings,
      BookingPageCustomerSettings,
      EmailListSettings,
      CalendarStyleSettings,
      BookingPageInformationSettings,
        Loader,
        PersonalAccountSettings,
        StaffMemberServicesSettings,
        StaffMemberDetailsSettings,
        StaffMembersSettings,
        ServiceOptions,
        BookingPageGeneralSettings,
        GeneralBookingSettings,
        BookingPageFieldSettings,
        BookingNotificationSettings,
        BookingDistribution,
        AccountSettings,
        SubscriptionInformation,
        ServiceCategories,
        WorkingHours,
        VacationDays,
        SettingCategory,
        SettingLink,
        FunctionSettings,
        InsuranceSettings,
        InvoiceSettings,
        Services,
        SmsSettings,
        AppointmentBookingLink, SelectWorktime, Sidebar, HeaderBar, TreatmentTypes, CompanyInfoCard}
})
export default class Settings extends Vue {
    private menuListToShow:number  = 0;

    @Action public addToDialogQueue:Function;
    @Action public getStaffMembers:Function;
    @Getter public staffMembers: IStaffMemberDTO[];
    @Getter public firebaseUserAccount;
    @Action getFirebaseUserAccount;
    @Getter public user: IUser;
    public selectedStaffMember: IStaffMemberDTO;
    public selectedStaffMemberId: number = -1;


    @Watch("staffMembers")
    onStaffMembersChange(){
        if(this.selectedStaffMember != null){
            const updatedStaffMember = this.staffMembers.find((x) => {
                return x.id === this.selectedStaffMemberId;
            });
            this.selectedStaffMember = updatedStaffMember;
        }
    }

    gotoContact(){
      window.open("http://m.me/availify/",'_blank');
    }
    private expand(menuIndex:number):void{
        this.menuListToShow = menuIndex;
    }

    async created():Promise<void>{
       this.getStaffMembers();

        await this.getFirebaseUserAccount();
       if(this.firebaseUserAccount.role === 0){
           this.menuListToShow = 0;
       } else {
           this.menuListToShow = 25;
       }

       if(this.$router.currentRoute.params.setting != null){
         this.menuListToShow = parseInt(this.$router.currentRoute.params.setting);
         this.expand(parseInt(this.$router.currentRoute.params.setting));

       }
    }

    expandStaffMember(staffMember : IStaffMemberDTO, menuIndex: number){
        this.selectedStaffMember = staffMember;
        this.selectedStaffMemberId = staffMember.id;
        this.menuListToShow = menuIndex;
    }



}
