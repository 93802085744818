

    import Vue from 'vue'
    import Component from 'vue-class-component'
    import {
        State,
        Getter,
        Action,
        Mutation,
        namespace
    } from 'vuex-class'
    import ToolTip from "../../../ToolTip.vue";
    import {IAuditLogDTO, IUserDTO} from "../../../../types/AutoGenerated/reinforcedTypings";
    import {IUser} from "../../../../store/user";

    enum CustomerMergeType{
      None,
      Phone
    }

    @Component({
        name: "BookingPageCustomerSettings",
        components: {ToolTip}
    })

    export default class BookingPageCustomerSettings extends Vue {



        public customerMergeType: CustomerMergeType = CustomerMergeType.None;
        public automaticAddCustomers : boolean = false;

      @Action public postSnackbarMessage: Function;

      @Getter user : IUserDTO;
      @Action patchUser: Function;
      @Action postAuditLog: Function;



        created() {
          this.customerMergeType = this.user.customerMergeType;
          this.automaticAddCustomers = this.user.automaticAddCustomers;
        }
        async saveChanges(){

          if(this.customerMergeType != this.user.customerMergeType){
            let audit: Partial<IAuditLogDTO> = {action: 1, type: 2, comment: "", message: " endret innstilling 'Automatisk tilegne ny kunder ' til " + this.enumToString(this.customerMergeType), hideForUser: false,};
            this.postAuditLog(audit);
          }

          if(this.automaticAddCustomers != this.user.automaticAddCustomers){
            let audit: Partial<IAuditLogDTO> = {action: 1, type: 2, comment: "", message: " endret innstilling 'Automatisk opprett kunder fra booking ' til " + this.booleanToOnOff(this.automaticAddCustomers), hideForUser: false,};
            this.postAuditLog(audit);
          }

            let update: Partial<IUserDTO> = {
              customerMergeType: this.customerMergeType,
              automaticAddCustomers: this.automaticAddCustomers
            };

            this.patchUser(update);
        }

        enumToString(value: CustomerMergeType): string {
            if (value == CustomerMergeType.None) {
                return "Av"
            } else {
                return "Telefonnummer"
            }
        }

      booleanToOnOff(value: boolean): string {
        if (value) {
          return "På"
        } else {
          return "Av"
        }
      }

      get mergeOptions() {
        return [
          {value: 0, label: "Ingen automatisk tilegning"},
          {value: 1, label: "Telefonnummer"},
          //  {value: 2, label: "SMS"}
        ]
      }

    }

