
// @ts-ignore
import axios, {AxiosRequestConfig} from 'axios';
// @ts-ignore
import { GetterTree, MutationTree, ActionTree } from 'vuex';
import {store} from '@/store/store';
import { APIURL2 } from '@/main';
import authConfig, {AuthConfig} from "@/Utilities/authConfig";
import {actionStringSnackbar} from "@/store/snackbar";


// Interface
export interface appointmentTypeState {
    appointmentType: IappointmentType[];
    extraServices: IappointmentType[];
    categories: Array<IServiceCategory>;
}

// Interface Model
export interface IappointmentType {
    id: number;
    adminId: string;
    title: string;
    price: number;
    description: string;
    durationInMinutes: number;
    discountPrice: number;
    hideInBooking: boolean;
}

export interface IServiceCategory {
    id: number;
    title: string;
    order: number;
}

// Mutation enums
export const enum mutationStringAppointmentType {
    setAppointmentType = 'setAppointmentType',
    SET_APPOINTMENT_TYPE = 'setAppointmentType',
    SET_EXTRA_SERVICES = 'setExtraServices',
SET_CATEGORIES = 'setCategories'
}
export const enum actionStringAppointmentType{
     GET_APPOINTMENT_TYPES_AS_CUSTOMER = 'getAppointmentTypesAsCustomer',
}
export const enum getterStringAppointmentType{
    APPOINTMENT_TYPE = 'appointmentType',
}




export const state = {
    appointmentType: {},
    categories: {},
    extraServices: []
};

export const getters: GetterTree<any, appointmentTypeState> = {
    appointmentType: (state) => state.appointmentType,
    categories: (state) => state.categories,
    extraServices: (state) => state.extraServices,
};

export const mutations: MutationTree<appointmentTypeState> = {
    setAppointmentType(state, payload: IappointmentType[]) {
        state.appointmentType = payload;
    },
    setExtraServices(state, payload: IappointmentType[]) {
        state.extraServices = payload;
    },

    setCategories(state, payload: Array<IServiceCategory>) {
        state.categories = payload;
    }
};

export const actions: ActionTree<appointmentTypeState, any> = {
    async getAppointmentTypes({commit}, payload) {
        let header : AxiosRequestConfig = await authConfig();
        axios.get(APIURL2 + 'AppointmentType/current',  header)
        .then((res) => {

          const newobj : any = [];

          for (let i = 0; i < res.data.length; i++) {
              console.log("I: ", res.data[i]);
             const addedobj = Object.assign(res.data[i], {isShowing: false});
             newobj.push(addedobj);
          }

          let normalServices = newobj.filter((x) => {
              return !x.isExtraService
          });

            let extraServices = newobj.filter((x) => {
                return x.isExtraService
            });

            commit(mutationStringAppointmentType.SET_APPOINTMENT_TYPE, normalServices);
            commit(mutationStringAppointmentType.SET_EXTRA_SERVICES, extraServices);
        }).catch((err) => {
          console.log(err);
        });
      },

    async getCategories({commit}) {
        let header : AxiosRequestConfig = await authConfig();
        axios.get(APIURL2 + 'AppointmentType/categories/current',  header)
            .then((res) => {

                const newobj = [];

                for (let i = 0; i < res.data.length; i++) {
                    const addedobj = Object.assign(res.data[i], {isShowing: false});
                    newobj.push(addedobj);
                }

                commit(mutationStringAppointmentType.SET_CATEGORIES, newobj);
            }).catch((err) => {
            console.log(err);
        });
    },


    async changeCategoryOfService({commit, dispatch}, payload) {
        let header : AxiosRequestConfig = await authConfig();
        axios.put(APIURL2 + "AppointmentType/changeCategoryOrder/current?id=" + payload.serviceId + "&categoryId=" + payload.categoryId + "&order=" + payload.order, {}, header)
            .then((res) => {
                dispatch(actionStringSnackbar.postSnackbarMessage, "Tjeneste har endret kategori.");
                dispatch('getCategories');
            }).catch((err) => {
            console.log(err);
        });
    },


// Add new appointmentType / treatment on an admin
async changeCategoryOrder({commit, dispatch}, payload) {
    let header : AxiosRequestConfig = await authConfig();

    axios.put(APIURL2 + "AppointmentType/category/changeOrder/current?categoryId=" + payload.categoryId + "&order=" + payload.order, {}, header)
        .then((res) => {
            dispatch(actionStringSnackbar.postSnackbarMessage, "Kategori endret rekkefølge.");
            dispatch('getCategories');
        }).catch((err) => {
        console.log(err);
    });
},
  // Add new appointmentType / treatment on an admin
  async changeServiceOrder({commit, dispatch}, payload) {
    let header : AxiosRequestConfig = await authConfig();

    axios.put(APIURL2 + "AppointmentType/service/changeOrder/current?serviceId=" + payload.serviceId + "&order=" + payload.order, {}, header)
      .then((res) => {
        dispatch(actionStringSnackbar.postSnackbarMessage, "Tjeneste endret rekkefølge.");
        dispatch('getAppointmentTypes');
      }).catch((err) => {
      console.log(err);
    });
  },

    // Add new appointmentType / treatment on an admin
    async changeExtraService({commit, dispatch}, payload) {
        let header : AxiosRequestConfig = await authConfig();

        axios.put(APIURL2 + "AppointmentType/changeExtraService/current", payload, header)
            .then((res) => {
                dispatch('getAppointmentTypes');
            }).catch((err) => {
            console.log(err);
        });
    },


  // Add new appointmentType / treatment on an admin
    async putCategory({commit, dispatch}, payload:IServiceCategory) {
        let header : AxiosRequestConfig = await authConfig();
        axios.put(APIURL2 + "AppointmentType/category/current", payload, header)
            .then((res) => {
                dispatch(actionStringSnackbar.postSnackbarMessage, "Kategori er endret.");
                dispatch('getCategories');
            }).catch((err) => {
            console.log(err);
        });
    },

    // Add new appointmentType / treatment on an admin
    async addNewCategory({commit, dispatch}, newTreatment:IServiceCategory) {
        let header : AxiosRequestConfig = await authConfig();
        axios.post(APIURL2 + "AppointmentType/category/current", newTreatment, header)
            .then((res) => {
                dispatch(actionStringSnackbar.postSnackbarMessage, "Ny kategori lagt til.");
                dispatch('getCategories');
            }).catch((err) => {
            console.log(err);
        });
    },

    async deleteCategory({dispatch}, categoryId:number) {
        let header : AxiosRequestConfig = await authConfig();
        axios.delete(APIURL2 + 'AppointmentType/category/current?id=' + categoryId, header)
            .then((res) => {
                dispatch(actionStringSnackbar.postSnackbarMessage, "Kategori er slettet.");
                dispatch('getCategories');
            }).catch((err) => {
            console.log(err);
        });
    },

    // Add new appointmentType / treatment on an admin
    async addNewAppointmentType({commit, dispatch}, newTreatment:IappointmentType) {
        let header : AxiosRequestConfig = await authConfig();

        return new Promise((resolve, reject) => {
            axios.post(APIURL2 + "AppointmentType/current", newTreatment, header)
                .then((res) => {
                    dispatch(actionStringSnackbar.postSnackbarMessage, "Ny tjeneste lagt til.");
                    dispatch('getAppointmentTypes');
                    resolve(res.data);
                }, (error) => {
                    dispatch(actionStringSnackbar.postSnackbarMessage, "Kunne ikke legge til ny tjeneste.");
                }).catch((err) => {
                console.log(err);
            });
        });

    },

    async deleteAppointmentType({dispatch}, appointmentTypeId:string) {
        let header : AxiosRequestConfig = await authConfig();
        axios.delete(APIURL2 + 'AppointmentType/current?id=' + appointmentTypeId, header)
            .then((res) => {
                dispatch(actionStringSnackbar.postSnackbarMessage, "Tjenesten er slettet");
                dispatch('getAppointmentTypes');
            }).catch((err) => {
            console.log(err);
        });
    },

    async editAppointmentType({dispatch}, service:IappointmentType) {
        let header : AxiosRequestConfig = await authConfig();
        axios.put(APIURL2 + 'AppointmentType/current', service, header)
            .then((res) => {
                dispatch(actionStringSnackbar.postSnackbarMessage, "Tjenesten er endret");
                dispatch('getAppointmentTypes');
            }).catch((err) => {
            console.log(err);
        });
    },

     getAppointmentTypesAsCustomer({commit}, userId) {

        axios.get(APIURL2 + 'AppointmentType/asAnonymous?userId=' + userId)
        .then((res) => {
          commit(mutationStringAppointmentType.SET_APPOINTMENT_TYPE, res.data);
        }).catch((err) => {
          console.log(err);
        });
      },

};

export const appointmentTypes = {
    actions,
    mutations,
    getters,
    state,
};
