
// @ts-nocheck

    import Vue from 'vue'
    import Component from 'vue-class-component'
    import {
        Getter,
        Action,
        Mutation,
    } from 'vuex-class'
    import {formatDate, FormatSpaceType} from "../types/formatDateHelper";
    import {Prop} from "vue-property-decorator";
    import {IInvoice, InvoiceActions, InvoiceMutations} from "../store/Invoices";
    import {IDialog} from "../store/dialog";
    import {AppointmentInfo} from "../store/appointments/appointmentTypings";
    import {IUser} from "../store/user";
    import InvoiceSender from "./InvoiceSender.vue";

    @Component({
        name: "InvoicesList",
        components: {InvoiceSender}
    })

    export default class InvoicesList extends Vue {



        @Action(InvoiceActions.GetInvoicesForAppointment) public getInvoicesForAppointment:(appointmentId: number) => Promise<Array<IInvoice>>;

        @Action public addToDialogQueue:Function;
        @Action public postSnackbarMessage: Function;

        @Action(InvoiceActions.GetPdf) public getPdf:(invoiceId: number) => Promise<number>;
        @Action(InvoiceActions.ChangeStatus) public changeStatus:(changeStatusPayload) => Promise<number>;

        @Prop({default: null}) appointment: AppointmentInfo;
        @Prop({default: null}) customerId: number;


        @Prop({default: []}) invoices: Array<IInvoice>;

        @Prop({default: false}) public isLoading : boolean;


        @Mutation(InvoiceMutations.SetSendingInvoice) public setSendingInvoice:(payload: IInvoice) => void;
        @Getter public user : IUser;

        headers = [];
        search = "";


        set headers(e){
        }
        get headers(){
            let array = [
                {text: "Fakturanr.", align: "start", sortable: true, value:'invoiceNumber'},
                {text: "Bestillingnr.", align: "start", sortable: true, value:'appointmentNumber'},
                {text: "Beløp (NOK)", align: "start", sortable: true, value:'chargeAmount'},
                {text: "Kunde",sortable: true, value:"customerName"},
                {text: "Fakturadato",sortable: true, value:"date"},
                {text: "Forfallsdato",sortable: true, value:"dueDate"},
                {text: "Status",sortable: true, value:"status"},
                {text: "Last ned",sortable: true, value:"download"},
                {text: "Send",sortable: true, value:"send"},
            ];

            if(this.user.useKID){
                let kidheader =   {text: "KID",sortable: true, value:"kid"};
                array.splice(3,0, kidheader);
            }
            return array;
        }

        invoiceStatuses = [
            "Ny",
            "Sendt",
            "Betalt",
        ];

        public statusFilters = ["Ny", "Sendt", "Betalt"];





        async sendInvoice(invoice:IInvoice){
            this.setSendingInvoice(invoice);
        }


        async onSent(){
                await this.loadInvoices();
        }

        async downloadAsPdf(invoice :IInvoice){
            let data = await this.getPdf(invoice.id);
            const url = window.URL.createObjectURL(new Blob([data]));

            const link = document.createElement('a');
            link.href = url;
            link.setAttribute('download', 'file.pdf'); //or any other extension
            document.body.appendChild(link);
            link.click();
        }


        async loadInvoices(){
            let vm = this;
            if(vm.appointment == null && vm.customerId == null){
                vm.$emit("update");
            } else if(vm.appointment != null){
                await vm.getInvoicesForAppointment(vm.appointment.id);
            }
        }
        async created() {
            try{
                await this.loadInvoices();
            } catch(e){
                let dialog: IDialog = {
                    text: "Kunne ikke hente faktura"
                };
                this.addToDialogQueue(dialog);
            }
        }

        async updateInvoiceStatus(event, invoice){
            await this.changeStatus({invoiceId: invoice.id, status: event});
            await this.loadInvoices();
        }

        get invoicesForDataTable(){
            let returnValue : Array<IInvoice> = this.invoices;
            return returnValue.filter(x=> {
                return this.statusFilters.includes(x.status)
            });
        }

        get hasInvoices(){
            return this.invoices.length > 0 || !this.isLoading;
        }

        getColor (eventId) {
            if (eventId == "Ny") return '#E0E0E0';
            if (eventId == "Sendt") return '#a5c6d9';
            if (eventId == "Betalt") return '#a5d9af';
            return "grey";
        }

        formatDateDot(date: Date){
            if(date == null){
                return "";
            }

            let toDateObject = new Date(date as string);
            return formatDate(toDateObject, FormatSpaceType.DOT);
        }

        get hasSelectedAllFilters(){
            return this.statusFilters.length == this.invoiceStatuses.length;
        }

        get typedUser() : IUser {
            return this.user;
        }
    }

