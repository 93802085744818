

    import Vue from 'vue'
    import Component from 'vue-class-component'
    import {
        State,
        Getter,
        Action,
        Mutation,
        namespace
    } from 'vuex-class'
    import {Prop, Watch} from "vue-property-decorator";
    import {actionStringCustomers, ICustomer} from "../../store/customers";
    import {IInsurance, InsuranceActions} from "../../store/Insurances";
    import {IUser} from "../../store/user";
    import DatePicker from "./DatePicker.vue";
    import {formatDate, FormatSpaceType} from "../../types/formatDateHelper";
    import {ICustomerDTO} from "../../types/AutoGenerated/reinforcedTypings";

    @Component({
        name: "CreateCustomer",
        components: {DatePicker}
    })

    export default class CreateCustomer extends Vue {

        @Prop({}) editingCustomer;

        @Prop({}) defaultName;
        @Prop({}) defaultPhone;
        @Prop({}) defaultEmail;
        @Action(actionStringCustomers.ADD_CUSTOMER) public addCustomer:(newCustomer: ICustomer) => Promise<ICustomer>;
        @Action public postSnackbarMessage:Function;
        @Action public updateCustomer: (payload: Partial<ICustomer>) => void;

        @Getter public insurances;
        @Action(InsuranceActions.GET) getInsurances:() => Promise<Array<IInsurance>>;
        @Getter public user:IUser;

        public isShowingDatePicker:boolean = false;

        public newCustomer:Partial<ICustomerDTO>  = {
            firstName:'',
            lastName:'',
            gender:'',
            phoneNumber:"",
            emailAddress:'',
            generalInfo: '',
            insuranceId: 0,
            id: 0,
            sendSmsReminder: false,
            sendEmailReminder: false,
        };

        @Watch("newCustomer.emailAddress")
        handlerEmail(newValue){
            if(newValue.length == 0){
                //this.newCustomer.sendEmailReminder = false;
            }
        }
        @Watch("newCustomer.phoneNumber")
        handlerPhone(newValue){
            if(newValue.length != 8){
                //this.newCustomer.sendSmsReminder = false;
            }
        }

        mounted() {
            if(this.editingCustomer != null){
                this.newCustomer = this.editingCustomer;
            }
            this.getInsurances();

            if(this.defaultName != null){
                this.newCustomer.firstName = this.defaultName;
            }
            if(this.defaultPhone != null){
                this.newCustomer.phoneNumber = this.defaultPhone;
            }
            if(this.defaultEmail != null){
                this.newCustomer.emailAddress = this.defaultEmail;
            }

            //Dragable v-title
                // const d = {};
                // document.addEventListener("mousedown", e => {
                //     const closestDialog = e.target.closest(".v-dialog.v-dialog--active");
                //     if (e.button === 0 && closestDialog != null && e.target.classList.contains("v-card__title")) { // element which can be used to move element
                //         d.el = closestDialog; // element which should be moved
                //         d.mouseStartX = e.clientX;
                //         d.mouseStartY = e.clientY;
                //         d.elStartX = d.el.getBoundingClientRect().left;
                //         d.elStartY = d.el.getBoundingClientRect().top;
                //         d.el.style.position = "fixed";
                //         d.el.style.margin = 0;
                //         d.oldTransition = d.el.style.transition;
                //         d.el.style.transition = "none"
                //     }
                // });
                // document.addEventListener("mousemove", e => {
                //     if (d.el === undefined) return;
                //     d.el.style.left = Math.min(
                //         Math.max(d.elStartX + e.clientX - d.mouseStartX, 0),
                //         window.innerWidth - d.el.getBoundingClientRect().width
                //     ) + "px";
                //     d.el.style.top = Math.min(
                //         Math.max(d.elStartY + e.clientY - d.mouseStartY, 0),
                //         window.innerHeight - d.el.getBoundingClientRect().height
                //     ) + "px";
                // });
                // document.addEventListener("mouseup", () => {
                //     if (d.el === undefined) return;
                //     d.el.style.transition = d.oldTransition;
                //     d.el = undefined
                // });
                // setInterval(() => { // prevent out of bounds
                //     const dialog = document.querySelector(".v-dialog.v-dialog--active");
                //     if (dialog === null) return;
                //     dialog.style.left = Math.min(parseInt(dialog.style.left), window.innerWidth - dialog.getBoundingClientRect().width) + "px";
                //     dialog.style.top = Math.min(parseInt(dialog.style.top), window.innerHeight - dialog.getBoundingClientRect().height) + "px";
                // }, 100);
        }

        public showDatePicker():void{
            this.isShowingDatePicker = true;
        }

        public saveDate(event:object, date:Date):void{
            this.newCustomer.dateOfBirth = date;
            this.isShowingDatePicker = false;
        }
        public cancelDate():void{
            this.isShowingDatePicker = false;
        }

        public async editCustomer(){
            if(this.newCustomer.firstName === '' || this.newCustomer.firstName == null){
                this.postSnackbarMessage("Navn må fylles ut.");
                return false;
            }

             await this.updateCustomer(this.newCustomer as ICustomer);

            this.postSnackbarMessage("Kunde redigert.");

            this.$emit("created");
        }
        public async submit():Promise<any>{
            if(this.newCustomer.firstName === '' || this.newCustomer.firstName == null){
                this.postSnackbarMessage("Navn må fylles ut.");
                return false;
            }

            let addedCustomer = await this.addCustomer(this.newCustomer as ICustomer);

            this.postSnackbarMessage("Kunde opprettet.");

            this.$emit("created", addedCustomer.id);

        }

        get dateOfBirthFormatted(){
            if(this.newCustomer != null && this.newCustomer.dateOfBirth != null){

            return formatDate(this.newCustomer.dateOfBirth);
            } else {
                return "";
            }
        }

        close(){
            this.$emit("close");
        }

    }

