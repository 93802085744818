
    import {Component, Prop, Vue, Watch} from 'vue-property-decorator';
    import {actionStringCustomers, ICustomer} from '../../store/customers';
    import {ICustomerJournals} from '../../store/customerJournals';
    import muscleSelector from '../global/muscleGroupSelector.vue';
    import EditCustomer from "./EditCustomer.vue";
    import {DialogActions, DialogType, IDialog} from "../../store/dialog";
    import InvoicesList from "../InvoicesList.vue";
    import {IPayment, PaymentActions} from "../../store/Payments";
    import PaymentList from "../PaymentList.vue";
    import PaymentCreator from "./PaymentCreator.vue";
    import InvoiceSender from "../InvoiceSender.vue";
    import {IInvoice, InvoiceActions} from "../../store/Invoices";
    import {IUser} from "../../store/user";
    import Loader from "./Loader.vue";
    import DatePicker from "./DatePicker.vue";
    import {Action, Getter, Mutation} from "vuex-class";
    import {formatDate, FormatSpaceType} from "../../types/formatDateHelper";
    import {formatDateString} from "../../Utilities/dateUtility";
    import {IUserDTO} from "../../types/AutoGenerated/reinforcedTypings";

    @Component({
        name: 'CustomerModal',
        components: {
            InvoiceSender,
            PaymentCreator,
            PaymentList,
            InvoicesList,
            EditCustomer, Loader, DatePicker, muscleSelector}
    })
    export default class CustomerModal extends Vue {
        @Prop({}) customer;
        @Prop({}) toglgeIndex;
        @Prop({default: false}) hideAppointments;
        @Mutation public setDefaultCustomerToBook:Function;
        @Action(PaymentActions.GET_FOR_CUSTOMER) getPaymentsForCustomer:(customerId: number) => Promise<Array<IPayment>>;

        @Action public addCustomer:Function;
        @Action public getCustomers:Function;
        @Action public getCurrentUser:() => Promise<IUser>;
        @Action public getCustomerJournals:Function;
        @Action public addNewCustomerJournal:Function;
        @Action public getCustomerJournal:Function;
        @Action public postSnackbarMessage:Function;
        @Action public deleteCustomer:Function;
        @Action public updateCustomer: (payload: Partial<ICustomer>) => void;
        @Action public updateCustomerJournal:Function;
        @Action public getCustomerStatistics: Function;
        @Action(DialogActions.AddToDialogQueue) addToDialogQueue:(dialog: IDialog) => void;
        @Getter public customerJournal:ICustomerJournals[];
        @Getter public user :IUserDTO;
        @Getter public customers;

        @Action(InvoiceActions.GetInvoicesForCustomer) public getInvoicesForCustomer:(customerId: number) => Promise<Array<IInvoice>>;


        public showJournals:boolean = true;
        public editing: boolean = false;

        public payments: Array<IPayment> = [];
        public statistics = {};

        public isShowingDatePicker:boolean = false;
        public formType:string = '';
        public isReadingJournal:boolean =false;
        currentReadingJournal: any = {};
        public isEditingJournal:boolean = false;
        public isLockedForEditing:boolean = false;
        public currentJournalText:string = '';
        public currentJournalId:string = '';
        public isShowingNewJournalBox:boolean = false;
        public newJournal = {text:''};
        public currentJournalCustomerId:string = '';
        public newPayment: boolean = false;
        public isLoadingPayments: boolean = false;

        public tab : number = 0;


        get tabs(){
            if(this.user.enablePaymentModule){
                return  [{label: "Bestillinger", id: 0, icon: "calendar_today"}, {label: "Journaler", id: 1, icon: "assignment"},
                    {label: "Faktura", id: 3, icon: "receipt"},
                    {label: "Betalinger", id: 4, icon: "receipt"},
                ];
            } else {
                return  [{label: "Bestillinger", id: 0, icon: "calendar_today"}, {label: "Journaler", id: 1, icon: "assignment"},
                ];
            }

        }

        public secondMenuActiveIndex:number = 0;
        newCustomerGeneralInfo: string = "";
        isAddingNewJournal: boolean = false;

        public journalHtml: string = "";
        public invoices: Array<IInvoice> = [];

        @Watch("customerJournal")
        onCustomerJorunalChanged() {
            this.isAddingNewJournal = false;
        }

        beforeCreate() {
            //Some weird thing about circular dependecnceis or something... can't remember
            //This component uses Appointment-list. Appointment-list uses Customer Modal.
            this.$options.components.AppointmentList = require('../../pages/admin/AppointmentList.vue').default
        }

        async onFinished(){
            await this.loadPayments();
            this.newPayment = false;
        }

        onSent(){

        }

        formatDate(value){
            return this.formatDateString(value);
        }

        formatDateString(date: Date){
          return formatDateString(date);
        }

        booleanToNorwegian(value: boolean): string {
            if (value) {
                return "Ja"
            } else {
                return "Nei"
            }
        }



        finished(){
            this.editing = false;
            this.$emit("finished");
        }

        public async created():Promise<any> {


            this.invoices = await this.getInvoicesForCustomer(this.customer.id);

            await this.loadPayments();
            this.statistics = await this.getCustomerStatistics(this.customer.id);

            this.showJournals = false;
            await this.getCustomerJournal(this.customer.id);
            this.showJournals = true;
            this.newCustomerGeneralInfo = this.customer.generalInfo;


        }

        async loadPayments(){
            this.isLoadingPayments = true;
            this.payments = await this.getPaymentsForCustomer(this.customer.id);
            this.isLoadingPayments = false;
        }


        public showDatePicker(formType:string):void{
            this.formType = formType;
            this.isShowingDatePicker = true;
        }

        public gotoEditAnamnese(){
            this.secondMenuActiveIndex = 2;
        }
        public get journalText():string{
            return "Journaler"
        }
        public get customerDescriptionText():string{
            return "Generell tekst"
        }

        public closeNewJournalBox(){
            this.newJournal.text = "";
            this.isShowingNewJournalBox = false
        }


        public cancelDate():void{
            this.isShowingDatePicker = false;
        }

        formatNumber(v){
            if(v != null){
                let value = (v).toLocaleString('no-NO', {
                    style: 'currency',
                    currency: 'NOK',
                }); /* $2,500.00 */
                value = value.replace(",", " ");
                value = value.replace(".", ",");

                return value;
            } else {
                return "NOK 0.00";
            }

        }


        public addNewJournal(){
            this.isShowingNewJournalBox = !this.isShowingNewJournalBox;
            if(this.isShowingNewJournalBox) {
                window.addEventListener("beforeunload", this.beforeUnloadFunction);
            } else {
                window.removeEventListener("beforeunload", this.beforeUnloadFunction)
            }
        };

        beforeUnloadFunction(e){
                const confirmationMessage = 'Du har ulagret endringer i journalen '
                    + 'Hvis du forlater siden, vil endringene dine gå tapt';

                (e || window.event).returnValue = confirmationMessage; //Gecko + IE
                return confirmationMessage; //Gecko + Webkit, Safari, Chrome etc.
        }


        public async editJournal():Promise<void>{
            this.isEditingJournal = !this.isEditingJournal;

            if(this.isEditingJournal) {
                window.addEventListener("beforeunload", this.beforeUnloadFunction);
            } else {
                window.removeEventListener("beforeunload", this.beforeUnloadFunction)
            }


            let updatedJournal:Partial<ICustomerJournals> = {
                id:this.currentJournalId,
                title:this.currentJournalText,
                content:this.currentJournalText,
                customerId: this.currentJournalCustomerId,
            };

            if(this.isEditingJournal === false){
                await this.updateCustomerJournal(updatedJournal);
                await this.getCustomerJournal(this.currentJournalCustomerId);
                this.postSnackbarMessage('Journalen er oppdatert');
                window.removeEventListener("beforeunload", this.beforeUnloadFunction)
            } else {
                return;
            }


        }

        public cancelEditingJournal(){

            let vm = this;
            let dialog : IDialog = {
                text: "Du har ulagret endringer i journal. Er du sikker på at du vil gå ut?",
                type: DialogType.Choice,
                action: function(){
                    // vm.isReadingJournal = !vm.isReadingJournal;
                    // vm.currentJournalText = '';
                    vm.isEditingJournal = false;
                }
            };

            this.addToDialogQueue(dialog);

        }

        public readJournal(i, journ){
            journ.isShowing = !journ.isShowing;
            this.currentJournalText = journ.content;
            this.isLockedForEditing = journ.isLocked;
            this.currentJournalId = journ.id;
            this.currentReadingJournal = journ;
            this.currentJournalCustomerId = journ.customerId;
            this.isReadingJournal = true;
        };

        public bookAppointment():void{
            this.setDefaultCustomerToBook(this.customer);
            this.$router.push("/kalender");
        }

        public async addNewJournalOnCustomer(customer):Promise<void>{

            this.isAddingNewJournal = true;

            const journal = {
                title: "ny journal",
                content: this.newJournal.text,
                customerId: customer.id
            };

            await this.addNewCustomerJournal(journal);
            this.newJournal.text = "";
            window.removeEventListener("beforeunload", this.beforeUnloadFunction);
            this.isShowingNewJournalBox = false;
            this.showJournals = true;
        };




        public updateAnamnese(customer:ICustomer):void{
            this.secondMenuActiveIndex = 2;
            customer.generalInfo = this.newCustomerGeneralInfo;
            this.updateCustomer(customer);

        }


    }
    