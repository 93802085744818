
// @ts-nocheck

import { Bar, Line  } from 'vue-chartjs';
import { Component, Vue, Prop } from 'vue-property-decorator';
import { LANG, Language } from '../../main';



@Component({
    extends: Line , // this is important to add the functionality to your component
})
export default class CommitChart extends Vue<Line> {
  @Prop({default:null}) currentYear:Array<number>;
  @Prop({default:null}) previousYear:Array<number>;


  public language:any | null = null;
  public gradient:any | null;
  public gradient2:any | null;
  public option:any =  {
          scales: {
            yAxes: [{
              ticks: {
                beginAtZero: true
              },
              gridLines: {
                display: false
              }
            }],
            xAxes: [ {
              gridLines: {
                display: false
              }
            }]
          },
          legend: {
            display: true
          },
          responsive: true,
          maintainAspectRatio: false
        }

  public getPageLanguage():void{
        LANG === Language.NORWEGIAN ? this.language = this.pageLanguageNorwegian() : this.language = this.pageLanguageEnglish();
  }


  public mounted ():void {
    // Overwriting base render method with actual data.
      const canvas : any = this.$refs.canvas;

    this.gradient = canvas.getContext('2d').createLinearGradient(0, 0, 0, 450)
    this.gradient2 = canvas.getContext('2d').createLinearGradient(0, 0, 0, 450)

    this.gradient.addColorStop(0, 'rgba(135, 106, 255, 0.8)')
    this.gradient.addColorStop(0.5, 'rgba(135, 106, 255, 0.5)');
    this.gradient.addColorStop(1, 'rgba(135, 106, 255, 0)');

    this.gradient2.addColorStop(0, 'rgba(86, 220, 182, 0.5)')
    this.gradient2.addColorStop(0.5, 'rgba(86, 220, 182, 0.5)');
    this.gradient2.addColorStop(1, 'rgba(86, 220, 182, 0.5)');

      this.renderChart({
      labels: this.language[0].month,
      datasets: [
        {
          label: 'Tjent ' + new Date().getFullYear(),
          borderColor: '#876AFF',
          pointBackgroundColor: '#876AFF',
          borderWidth: 1,
          pointBorderColor: '#876AFF',
          backgroundColor: this.gradient,
          data: this.currentYear
        },
         {
          label: 'Tjent ' + (new Date().getFullYear() - 1),
          borderColor: '#56dcb6',
          pointBackgroundColor: '#56dcb6',
          borderWidth: 1,
          pointBorderColor: '#56dcb6',
          backgroundColor: this.gradient2,
          data: this.previousYear,
        }
      ],
    },/*End of chart data*/

      this.option
    )
  }

  public created():void{
    this.getPageLanguage();
  }

    pageLanguageNorwegian() {
        return [
            {month:['Januar', 'Februar', 'Mars', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Desember']},
            {earnedThisYear: "Tjent " + new Date().getFullYear()},
            {earnedLastYear: "Tjent " + (new Date().getFullYear() - 1)},
        ];
    }
    pageLanguageEnglish() {
        return [
            {month:['Januar', 'Februar', 'Mars', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Desember']},
            {earnedThisYear: "Tjent " + new Date().getFullYear()},
            {earnedLastYear: "Tjent " + (new Date().getFullYear() - 1)},
        ];
    }
}
