
    "use strict";

    import Vue from "vue";
    import {Component, Prop} from 'vue-property-decorator';
    import {AnimationTypes} from "@/types/AnimationTypes";


    //* Component definition ************************************************************

    @Component({
        name: "loader"
    })

//* Class ***************************************************************************

    export default class Loader extends Vue {
        @Prop({default:AnimationTypes.SPINNER}) animationType:AnimationTypes;

        created(){
        }

    }

