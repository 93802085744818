export function getPropertyName(propertyFunction) {
    return /\.([^\.;]+);?\s*\}$/.exec(propertyFunction.toString())[1];
}

export function createPatchObject(partialObject){
    let payload = [];

    for(let prop in partialObject){
        let pathName = String(prop);
        let patchElement = {
            "op": "replace",
            "path": "/" + pathName,
            "value": partialObject[prop]
        };

        payload.push(patchElement);
    }
    return payload;
}
