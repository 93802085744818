
    import {Component, Prop, Vue} from 'vue-property-decorator';
    import AppointmentList from "./AppointmentList.vue";

    @Component({
        name: 'Appointment-list-page',
        components: {
            AppointmentList}
    })
    export default class AppointmentListPage extends Vue {


    }
