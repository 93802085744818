
    import {Component, Prop, Vue} from 'vue-property-decorator';
    import {Action} from "vuex-class";
    import CustomerModal from "./CustomerModal.vue";
    import {ICustomer} from "../../store/customers";

    @Component({
        name: 'CustomerModalWindow',
        components: {CustomerModal}
    })
    export default class CustomerModalWindow extends Vue {

        @Prop({}) customer;
        @Prop({}) customerId;
        @Prop({default: false}) hideAppointments;

        @Action public getCustomer : Function;

        public fetchedCustomer: ICustomer = null;

        dialogValue: boolean = false;



        async mounted(){
            if(this.customer != null){
                this.fetchedCustomer = await this.getCustomer(this.customer.id);
            }
            if(this.customerId != null){
                this.fetchedCustomer = await this.getCustomer(this.customerId);
            }

        }
        get dialog(){
            return this.dialogValue;
        }
        set dialog(value){
            this.dialogValue = value;
            if(value == false){
                this.$emit("finish");
            }
        }
        public async created():Promise<any> {
            this.dialogValue = true;
        }

        deleted(customerId){
            this.$emit("deleted", customerId);
        }

        finished(){
            this.dialogValue = false;
            this.$emit("finish");
        }
    }
    