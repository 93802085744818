// @ts-ignore
import { GetterTree, MutationTree, ActionTree } from 'vuex';
import {APIURL2} from '@/main';
import {AuthConfig} from "@/Utilities/authConfig";
import {actionStringSnackbar} from "@/store/snackbar";
import {getCustomers} from "@/Utilities/endpoints";

// Interface
export interface LayoutState {
    mini: boolean
    mobileNavigation: boolean,
    hideMobileFooter: boolean
}

export enum LayoutMutations{
    SetMini = "setMini",
    SetMobileNavigation = "setMobileNavigation",
}


export const state : LayoutState = {
    mini: true,
    mobileNavigation: false,
    hideMobileFooter: false
};

export const getters: GetterTree<LayoutState, any> = {
    mini: (state) => state.mini,
    mobileNavigation: (state) => state.mobileNavigation,
    hideMobileFooter: (state) => state.hideMobileFooter,
};

export const mutations: MutationTree<LayoutState> = {
    setMini(state : LayoutState, payload : boolean){
        state.mini = payload;
    },
    setMobileNavigation(state : LayoutState, payload : boolean){
        state.mobileNavigation = payload;
    },
    setHideMobileFooter(state : LayoutState, payload : boolean){
        state.hideMobileFooter = payload;
    },

};

export const actions: ActionTree<LayoutState, any> = {


};

export const layout = {
    actions,
    mutations,
    getters,
    state,
};
