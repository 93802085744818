// @ts-ignore
import {GetterTree, MutationTree, ActionTree} from 'vuex';
// @ts-ignore
import axios, {AxiosRequestConfig} from 'axios';
import {APIURL2} from '@/main';
import authConfig, {AuthConfig} from "@/Utilities/authConfig";
import {actionStringSnackbar} from "./snackbar";


// Interface
export interface workingHoursState {
    workingHoursByDay: bookedWorkingHours[];
    workingDays: IWorkingDays | null;
    allWorkingDays: IWorkingDays[];
    vacationDays: Date[];
    pauseTimes: [];
    bookingSettings: IUserBookingSettingsDTO|null;
    bookingSettingsIntervals: CalendarInterval[];
}

export interface CalendarInterval {

    openMinute: number;
    openHour: number;
    closeMinute: number;
    closeHour: number;
}

export interface IUserBookingSettingsDTO
{
    mondayOpenFromMidnight: number;
    tuesdayOpenFromMidnight: number;
    wednesdayOpenFromMidnight: number;
    thursdayOpenFromMidnight: number;
    fridayOpenFromMidnight: number;
    saturdayOpenFromMidnight: number;
    sundayOpenFromMidnight: number;
    mondayCloseFromMidnight: number;
    tuesdayCloseFromMidnight: number;
    wednesdayCloseFromMidnight: number;
    thursdayCloseFromMidnight: number;
    fridayCloseFromMidnight: number;
    saturdayCloseFromMidnight: number;
    sundayCloseFromMidnight: number;
}

export interface bookedWorkingHours {
    workingHoursByDay: string;
}

export interface IWorkingDays {
    dayOfWork: string,
    isWorking: boolean,
}

// Mutation enums
export const enum mutationStringWorkingHours {
    setBookingSettings = 'setBookingSettings',
    setBookingSettingsIntervals = "setBookingSettingsIntervals",
    setAllWorkingDays = 'setAllWorkingDays',
    setWorkingHoursByDay = 'setWorkingHoursByDay',
    setWorkingDays = 'setWorkingDays',
setVacationDays = 'setVacationDays',
    setPauseTimes = "setPauseTimes"
}

export const enum actionStringWorkingHours {
    getBookingSettings = 'getBookingSettings',
}

export const state: workingHoursState = {
    bookingSettings: null,
    bookingSettingsIntervals: [],
    allWorkingDays: [],
    vacationDays: [],
    pauseTimes: [],
    workingHoursByDay: [],
    workingDays: null
};

export const getters: GetterTree<any, workingHoursState> = {
    bookingSettings: (state) => state.bookingSettings,
    bookingSettingsIntervals: (state) => state.bookingSettingsIntervals,
    allWorkingDays: (state) => state.allWorkingDays,
    workingDays: state => state.workingDays,
    vacationDays: state => state.vacationDays,
    pauseTimes: state => state.pauseTimes,
};

export const mutations: MutationTree<workingHoursState> = {
    setBookingSettings(state, payload: IUserBookingSettingsDTO) {
        state.bookingSettings = payload;
    },
    setBookingSettingsIntervals(state, payload: CalendarInterval[]) {
        state.bookingSettingsIntervals = payload;
    },
    setVacationDays(state, payload: Date[]) {
        state.vacationDays = payload;
    },
    setPauseTimes(state, payload: []) {
        state.pauseTimes = payload;
    },
    setAllWorkingDays(state, payload: any) {
        state.allWorkingDays = payload;
    },
    setWorkingDays(state, payload) {
        state.workingDays = payload;
    },
};

export const actions: ActionTree<workingHoursState, any> = {


    async updateBookingSettings({commit}, payload) {
        let header: AxiosRequestConfig= await authConfig();

        let additionalQueries = "";
        if(payload != null &&  payload.staffMemberId != null && payload.staffMemberId != 0){
            additionalQueries += "?staffMemberId=" + payload.staffMemberId;
        }

        axios.put(APIURL2 + 'User/bookingSettings/current' + additionalQueries, payload.workingHours ,
            header).then(() => {
            commit(mutationStringWorkingHours.setBookingSettings, payload.workingHours);
            console.log("Success");
            commit(actionStringSnackbar.postSnackbarMessage, "Åpningstider oppdatert.");
        }).catch((err) => {
            console.log(err);
            commit("addToDialogQueue", {text: "Kunne ikke oppdatere åpningstider. Sjekk om du har skrevet inn riktig format. (HH:MM)"});
        });
    },
    async getBookingSettings({commit}, payload): Promise<IUserBookingSettingsDTO> {

        let header: AxiosRequestConfig= await authConfig();

        let additionalQueries = "";
        if(payload != null && payload.staffMemberId != null && payload.staffMemberId != 0){
            additionalQueries += "?staffMemberId=" + payload.staffMemberId;
        }

        return new Promise((resolve, reject) => {
            axios.get(APIURL2 + 'User/bookingSettings/current' + additionalQueries,
                header).then((res) => {
                commit(mutationStringWorkingHours.setBookingSettings, res.data);
                resolve(res.data);
            }).catch((err) => {
                console.log(err);
                reject(err);
            });
        });
    },
    async getBookingSettingsIntervals({commit}, payload): Promise<IUserBookingSettingsDTO> {

        let header: AxiosRequestConfig= await authConfig();

        let additionalQueries = "";
        if(payload != null && payload.staffMemberId != null){
            additionalQueries += "?staffMemberId=" + payload.staffMemberId;
        }

        return new Promise((resolve, reject) => {
            axios.get(APIURL2 + 'User/bookingSettings/intervals/current' + additionalQueries,
                header).then((res) => {
                commit(mutationStringWorkingHours.setBookingSettingsIntervals, res.data);
                resolve(res.data);
            }).catch((err) => {
                console.log(err);
                reject(err);
            });
        });
    },

    async getPauseTimes({commit}, payload): Promise<Array<string>> {

        let header: AxiosRequestConfig= await authConfig();

        let additionalQueries = "";
        if(payload != null &&  payload.staffMemberId != null && payload.staffMemberId != 0){
            additionalQueries += "?staffMemberId=" + payload.staffMemberId;
        }
        return new Promise((resolve, reject) => {
            axios.get(APIURL2 + 'PauseTimes/current' + additionalQueries,
                header).then((res) => {
                commit(mutationStringWorkingHours.setPauseTimes, res.data);
                resolve(res.data);
            }).catch((err) => {
                console.log(err);
                reject(err);
            });
        });
    },

    async addPauseTime({commit}, payload): Promise<Array<string>> {

        let header: AxiosRequestConfig= await authConfig();

        let additionalQueries = "";
        if(payload != null &&  payload.staffMemberId != null && payload.staffMemberId != 0){
            additionalQueries += "&staffMemberId=" + payload.staffMemberId;
        }

        return new Promise((resolve, reject) => {
            axios.post(APIURL2 + 'PauseTimes/current' + additionalQueries, payload,
                header).then((res) => {
                commit(mutationStringWorkingHours.setPauseTimes, res.data);
                resolve(res.data);
            }).catch((err) => {
                console.log(err);
                reject(err);
            });
        });
    },
    async deletePauseTime({commit}, payload): Promise<Array<string>> {

        let header: AxiosRequestConfig= await authConfig();

        let additionalQueries = "";
        if(payload != null &&  payload.staffMemberId != null && payload.staffMemberId != 0){
            additionalQueries += "&staffMemberId=" + payload.staffMemberId;
        }

        return new Promise((resolve, reject) => {
            axios.delete(APIURL2 + 'PauseTimes/current?id=' + payload.id + additionalQueries,
                header).then((res) => {
                commit(mutationStringWorkingHours.setPauseTimes, res.data);
                resolve(res.data);
            }).catch((err) => {
                console.log(err);
                reject(err);
            });
        });
    },

    async getVacationDays({commit}, payload): Promise<Array<string>> {

        let header: AxiosRequestConfig= await authConfig();

        let additionalQueries = "";
        if(payload != null &&  payload.staffMemberId != null && payload.staffMemberId != 0){
            additionalQueries += "?staffMemberId=" + payload.staffMemberId;
        }
        return new Promise((resolve, reject) => {
            axios.get(APIURL2 + 'VacationDays/current' + additionalQueries,
                header).then((res) => {
                commit(mutationStringWorkingHours.setVacationDays, res.data);
                resolve(res.data);
            }).catch((err) => {
                console.log(err);
                reject(err);
            });
        });
    },

    async addVacationDays({commit}, payload): Promise<Array<string>> {

        let header: AxiosRequestConfig= await authConfig();

        let additionalQueries = "";
        if(payload != null &&  payload.staffMemberId != null && payload.staffMemberId != 0){
            additionalQueries += "&staffMemberId=" + payload.staffMemberId;
        }

        return new Promise((resolve, reject) => {
            axios.post(APIURL2 + 'VacationDays/current?vacationDaysString=' + payload.vacationDays + additionalQueries, {},
                header).then((res) => {
                commit(mutationStringWorkingHours.setVacationDays, res.data);
                resolve(res.data);
            }).catch((err) => {
                console.log(err);
                reject(err);
            });
        });
    },
    async removeVacationDays({commit}, payload): Promise<Array<string>> {

        let header: AxiosRequestConfig= await authConfig();

        let additionalQueries = "";
        if(payload != null &&  payload.staffMemberId != null && payload.staffMemberId != 0){
            additionalQueries += "&staffMemberId=" + payload.staffMemberId;
        }

        return new Promise((resolve, reject) => {
            axios.delete(APIURL2 + 'VacationDays/current?vacationDaysString=' + payload.vacationDays + additionalQueries, header).then((res) => {
                commit(mutationStringWorkingHours.setVacationDays, res.data);
                resolve(res.data);
            }).catch((err) => {
                console.log(err);
                reject(err);
            });
        });
    },

    async getAllWorkingDays({commit}, payload) {

        let header: AxiosRequestConfig= await authConfig();

        let additionalQueries = "";
        if(payload != null && payload.staffMemberId != null && payload.staffMemberId != 0){
            additionalQueries += "?staffMemberId=" + payload.staffMemberId;
        }

        return new Promise((resolve, reject) => {
            axios.get(APIURL2 + 'WorkingDays/all/current' + additionalQueries, header).then((res) => {
                commit(mutationStringWorkingHours.setAllWorkingDays, res.data);
                resolve(res.data);
            }).catch((err) => {
                reject(err);
            });
        });
    },

    // async getAllWorkingDaysAsAnonymous({commit}, userId) {
    //
    //     return new Promise((resolve, reject) => {
    //         axios.get(APIURL2 + 'WorkingDays/all/?userId=' + userId).then((res) => {
    //             commit(mutationStringWorkingHours.setAllWorkingDays, res.data);
    //             resolve(res.data);
    //         }).catch((err) => {
    //             reject(err);
    //         });
    //     });
    // },

    async updateWorkingDays({commit, dispatch}, payload) {
        let header: AxiosRequestConfig= await authConfig();

        let additionalQueries = "";
        if(payload != null &&  payload.staffMemberId != null && payload.staffMemberId != 0){
            additionalQueries += "&staffMemberId=" + payload.staffMemberId;
        }


        axios.put(APIURL2 + 'WorkingDays/current?dayOfWeek=' + payload.dayOfWeek + '&isWorking=' + payload.isWorking + additionalQueries, {},
            header).then(() => {
        }).catch((err) => {
            console.log(err);
        });
    },

};

export const workingHours = {
    actions,
    mutations,
    getters,
    state,
};
