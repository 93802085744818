
    import {Component, Vue} from 'vue-property-decorator';
import {Action, Getter} from "vuex-class";
    import {actionStringUser, getterStringUser} from "@/store/user";
    import {ISubscriptionPlan} from "@/types/HasSubscription";
import Loader from "@/components/global/Loader.vue";
    import CustomButton from "@/components/global/styledComponents/customButton.vue";
    import StripePayment from "./StripePayment.vue";
    import {IDialog} from "@/store/dialog";
    import {IUser} from "@/store/user";
    import {IUserDTO} from "@/types/AutoGenerated/reinforcedTypings";

@Component({
    name:'sms-settings',
    components:{StripePayment, Loader,CustomButton}
})
export default class SmsSettings extends Vue {
    public smsVerification: boolean = false;
    public smsCredits: number = 0;

    public price: number = 0;
    public description: string = "";
    public checkout: boolean = false;

    public loading: boolean = true;

    @Action public postSnackbarMessage:Function;

    @Action(actionStringUser.GET_USER_SUBSCRIPTION_PLAN) getUserSubscriptionPlan:() => Promise<ISubscriptionPlan>;
    @Getter(getterStringUser.SUBSCRIPTION_PLAN) subscriptionPlan:ISubscriptionPlan;


    @Getter public user: IUserDTO;
    @Action public patchUser: Function;
    @Action public addToDialogQueue: Function;
    async created(){
        this.smsVerification = this.user.smsVerification;
        this.smsCredits = this.user.smsCredits;

        try{
            let plan:ISubscriptionPlan = await this.getUserSubscriptionPlan();
            this.loading = false;

        } catch(e){
            this.loading = false;
        }
    }

     saveChanges(){
         let update : Partial<IUser> = {
             smsVerification: this.smsVerification
         };
         this.patchUser(update);
    }

    booleanToNorwegian(value: boolean): string {
        if (value) {
            return "Ja"
        } else {
            return "Nei"
        }
    }
    purchase1(){
        this.checkout = true;
        this.price = 60;
        this.description = "100 sms"
    }

    recievedPaymentToken(token){
       // this.createSubscription(token);
    }

    smsVerificationChanged(value){
        if(value == true && !this.subscriptionPlan){
            this.smsVerification = false;
            let dialog: IDialog = {
                text: "Du må ha et gyldig abonnement for å aktivere denne funksjonen."
            }
            this.addToDialogQueue(dialog);
        }
    }

    async recievedFormData(subscriptionFormData){
        //await this.createSubscription(subscriptionFormData);
        //this.isLoading = true;
        //await this.getUserSubscription();
        //await this.getUserSubscriptionPlan();
        //this.isLoading = false;
        //this.checkout = false;
    }
}
