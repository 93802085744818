
    import {Component, Prop, Vue} from 'vue-property-decorator';
    import {Getter, Mutation, Action} from "vuex-class";
    import FrontPageHeader from '../../components/global/FrontPageHeader.vue';
    import router from '@/router';
    import App from "@/App.vue";

    import CustomInput from '../../components/global/styledComponents/CustomInput.vue';
    import {IUser} from "@/store/user";
    import CustomButton from "@/components/global/styledComponents/customButton.vue";
    import {IDialog, DialogType, DialogMutations} from '@/store/dialog';

    import * as firebase from "firebase/app";
    import fb from 'firebase';
    import {IUserDTO} from "@/types/AutoGenerated/reinforcedTypings";



    @Component({
        name:'Login',
        components: {CustomButton, FrontPageHeader, App, CustomInput},
    })
    export default class Login extends Vue{
        @Action public registerUser:Function;
        @Action public postSnackbarMessage:(message:string) => void;
        @Mutation public addToDialogQueue:(dialog:IDialog) => void;


        public isShowingForgotPasswordField:boolean = false;
        public error:string = '';
        public password:string = '';
        public password2:string = '';
        public email:string = '';
        public isLoading:boolean = false;
        public isShowingRegisterUserForm:boolean = false;

        private hasRegistered:boolean = false;



        //FIREBASE REGISTER USER
        public showRegisterForm():void{
            this.isShowingRegisterUserForm = !this.isShowingRegisterUserForm;
        }

        public async register():Promise<void> {


            if (this.email === "" || this.password == "") {
                this.postSnackbarMessage("Email og passord må fylles ut.");
                return;
            }

            if (this.password !== this.password2) {
                this.postSnackbarMessage("Passordene må være like.");
                return;
            }

            try {
                this.hasRegistered = true;

                this.$store.commit("setIsNewUser", true);

                await fb.auth().createUserWithEmailAndPassword(this.email, this.password);

            } catch (error) {
                let errorCode = error.code;
                if (errorCode === "auth/email-already-in-use") {
                    this.postSnackbarMessage("Denne e-post adressen er opptatt.");
                }
                if (errorCode === "auth/invalid-email") {
                    this.postSnackbarMessage("Ugyldig e-post adresse.");
                }
                if (errorCode === "auth/weak-password") {
                    this.postSnackbarMessage("Passord må innholde minst 6 tegn.");
                }
            }
        }

        //FIREBASE LOGIN USER
        public login():void{
            this.isLoading = true;

          fb.auth().signInWithEmailAndPassword(this.email, this.password).then(res => {


                this.isLoading = false;

                if(res.additionalUserInfo.isNewUser){
                    router.push("/onboarding");
                } else {
                    router.push("/admin");
                }

            }).catch(error => {
                this.hasRegistered = false;
                this.isLoading = false;
                let errorCode = error.code;
                let errorMessage = error.message;
                if(errorCode === "auth/user-not-found"){
                    this.postSnackbarMessage("Finner ikke bruker.");
                    this.isLoading = false;
                }
                if(errorCode === "auth/wrong-password"){
                    this.postSnackbarMessage("Feil Email eller passord.");
                    this.isLoading = false;
                }
                if(errorCode === "auth/invalid-email"){
                    this.postSnackbarMessage("Ugyldig Email.");
                    this.isLoading = false;
                }

            });
        }

        public googleLogin():void{
            const provider = new fb.auth.GoogleAuthProvider();
            provider.addScope('https://www.googleapis.com/auth/contacts.readonly');
          fb.auth().signInWithPopup(provider).then((result) =>  {

            let user : any = result.additionalUserInfo;

            if(result.additionalUserInfo.isNewUser) {
                let newUser: Partial<IUserDTO> = {
                    firstName: user.profile.name,
                    lastName: user.profile.family_name,
                    phoneNumber: "",
                    emailAddress: user.profile.email,
                    profilePictureUrl: user.profile.picture,
                    appointmentDistributionInMinutes: 60
                };
                this.registerUser(newUser).then((x:IUser) => {
                    router.push("/onboarding");
                });
            }
            // ...
            }).catch(function(error) {
            // Handle Errors here.
            let errorCode = error.code;
            let errorMessage = error.message;
            // The email of the user's account used.
            let email = error.email;
            // The firebase.auth.AuthCredential type that was used.
            let credential = error.credential;
            // ...
            });
        }

        public goToLandingPage():void{
            router.replace("/");
        }

        public forgot():void{

            if(this.email == ""){
                let dialog: IDialog = {
                    text: "Vennligst fyll ut din e-post for å tilbakestille passordet.",
                };
                this.addToDialogQueue(dialog);
            } else {
                let dialog: IDialog = {

                    type: DialogType.Choice,
                    text: "Sende nytt passord til " + this.email + "?",
                    action: this.forgotYes
                };
                this.addToDialogQueue(dialog);
            }

        }

      goToLogin():void{
        this.isShowingRegisterUserForm = false;
      }

        forgotYes(){
            let vm  = this;
          fb.auth().sendPasswordResetEmail(this.email).then(function() {
                vm.addToDialogQueue({text: "En e-post ble sendt til deg."});
            }).catch(function(error) {
                vm.$store.commit(DialogMutations.AddToDialogQueue, );
                vm.addToDialogQueue({text: error.message});
            });
        }

        public created() {
            let route:string = this.$route.params.register;
            if(route === "login"){
                this.isShowingRegisterUserForm = true;
            }
        }

    }
