
// @ts-ignore
import axios, {AxiosRequestConfig} from 'axios';
// @ts-ignore
import { GetterTree, MutationTree, ActionTree } from 'vuex';
import {APIURL2} from '@/main';
import authConfig, {AuthConfig} from "@/Utilities/authConfig";
import {actionStringSnackbar} from "@/store/snackbar";
import {getCustomers} from "@/Utilities/endpoints";
import {mutationStringSnackbar} from "./snackbar";

// Interface
export interface InvoicesState {
    invoices: Array<IInvoice>
    invoiceNumber: number,
    sendingInvoice: IInvoice
}

export enum InvoiceMutations{
    SetInvoices = "setInvoices",
    SetInvoiceNumber = "setInvoiceNumber",
    SetSendingInvoice = "setSendingInvoice",
}

export enum InvoiceActions{
    GetInvoices = "getInvoices",
    PostInvoice = "postInvoice",
    GetCurrentInvoiceNumber = "getCurrentInvoiceNumber",
    GetPdf = "getPdf",
    ChangeStatus = "changeStatus",
    SendInvoiceToCustomer = "sendInvoiceToCustomer",
    GetInvoicesForAppointment = "getInvoicesForAppointment",
GetInvoicesForCustomer = "getInvoicesForCustomer"
}

// Interface Model
export interface IInvoice {
    id: number;
    invoiceNumber: number;
    senderName: string;
    customerName: string;
    customerAddress: string;
    customerEmailAddress: string;
    customerPhoneNumber: string;
    customerPostalCode: string;
    customerCity: string;
    customerCountry: string;
    dueDays: number;
    appointmentId: number;
    appointmentServicePrice: number;
    chargeAmount: number;
    customerAccountId: number;
    customerId: number;
    bankAccount: number;
    organizationNumber: number;
    vat: number;
    service: string;
    dischargeAmount: number;
    serviceQuantity: number;
    companyName: string;
    companyEmail: string;
    companyPhone: string;
    status: string;
    appointmentNumber: number;
    dueDate?: Date|string;
    date?: Date|string;
    markAsPaid: boolean;
}


export const state : InvoicesState = {
    invoices: [],
    invoiceNumber: 0,
    sendingInvoice: null
};

export const getters: GetterTree<InvoicesState, any> = {
    invoices: (state) => state.invoices,
    sendingInvoice: (state) => state.sendingInvoice,
};

export const mutations: MutationTree<InvoicesState> = {
    setInvoices(state : InvoicesState, payload : Array<IInvoice>){
        state.invoices = payload;
    },
    setSendingInvoice(state : InvoicesState, payload : IInvoice){
        state.sendingInvoice = payload;
    },
};

export const actions: ActionTree<InvoicesState, any> = {
    async changeStatus({commit}, payload): Promise<any> {
        let header: AxiosRequestConfig= await authConfig();

        return new Promise((resolve, reject) => {
            axios.put(APIURL2 + 'Invoice/status/current?invoiceId=' + payload.invoiceId + "&status=" + payload.status, {}, header).then((res) => {
                resolve(res.data);
            }, (error) => {
                console.error(error);
                commit(mutationStringSnackbar.setSnackbar, "Kunne ikke endre status.")
            }).catch((err) => {
                console.error(err);
                reject(err);
            });
        });
    },


    async sendInvoiceToCustomer({commit}, payload): Promise<any> {
        let header: AxiosRequestConfig= await authConfig();

        return new Promise((resolve, reject) => {
            axios.post(APIURL2 + 'Invoice/send/current', payload, header).then((res) => {
                resolve(res.data);
            }, (error) => {
                console.error(error);
                commit(mutationStringSnackbar.setSnackbar, "Kunne ikke sende faktura.")
            }).catch((err) => {
                console.error(err);
                reject(err);
            });
        });
    },

    async getInvoicesForCustomer({commit}, appointmentId: number): Promise<any> {
        let header: AxiosRequestConfig= await authConfig();

        return new Promise((resolve, reject) => {
            axios.get(APIURL2 + 'Invoice/forCustomer/current?id=' + appointmentId, header).then((res) => {
                resolve(res.data);
            }, (error) => {
                console.error(error);
                commit(mutationStringSnackbar.setSnackbar, "Kunne hente faktura.")
            }).catch((err) => {
                console.error(err);
                reject(err);
            });
        });
    },
    async getInvoicesForAppointment({commit}, appointmentId: number): Promise<any> {
        let header: AxiosRequestConfig= await authConfig();

        return new Promise((resolve, reject) => {
            axios.get(APIURL2 + 'Invoice/forAppointment/current?appointmentId=' + appointmentId, header).then((res) => {
                resolve(res.data);
            }, (error) => {
                console.error(error);
                commit(mutationStringSnackbar.setSnackbar, "Kunne hente faktura.")
            }).catch((err) => {
                console.error(err);
                reject(err);
            });
        });
    },

    async getPdf({commit}, invoiceId: number): Promise<any> {
        let header: AxiosRequestConfig= await authConfig();
        header.responseType = "blob";

        return new Promise((resolve, reject) => {
            axios.get(APIURL2 + 'Invoice/pdf/current?invoiceId=' + invoiceId, header).then((res) => {
                resolve(res.data);
            }, (error) => {
                console.error(error);
                commit(mutationStringSnackbar.setSnackbar, "Kunne ikke laste ned PDF.")
            }).catch((err) => {
                console.error(err);
                reject(err);
            });
        });
    },
    async getInvoices({commit}): Promise<any> {
        let header: AxiosRequestConfig= await authConfig();
        return new Promise((resolve, reject) => {
            axios.get(APIURL2 + 'Invoice/current', header).then((res) => {
                commit(InvoiceMutations.SetInvoices, res.data);
                resolve(res.data);
            }, (error) => {
                console.error(error);
                commit(mutationStringSnackbar.setSnackbar, "Kunne ikke hente faktura.")
            }).catch((err) => {
                console.error(err);
                reject(err);
            });
        });
    },
    async getCurrentInvoiceNumber({commit}): Promise<any> {
        let header: AxiosRequestConfig= await authConfig();
        return new Promise((resolve, reject) => {
            axios.get(APIURL2 + 'Invoice/invoiceNumber/current', header).then((res) => {
                commit(InvoiceMutations.SetInvoiceNumber, res.data);
                resolve(res.data);
            }, (error) => {
                console.error(error);
                commit(mutationStringSnackbar.setSnackbar, "Kunne ikke hente gyldig fakturanummer")
            }).catch((err) => {
                console.log(err);
                reject(err);
            });
        });
    },
    async postInvoice({commit, dispatch}, dto: IInvoice): Promise<any> {
        let header: AxiosRequestConfig= await authConfig();
        return new Promise((resolve) => {
            axios.post(APIURL2 + 'Invoice/current', dto, header).then((res) => {
                dispatch(InvoiceActions.GetInvoices);
                resolve(res.data);
            }, ()=> {
                commit(mutationStringSnackbar.setSnackbar, "Kunne ikke opprette faktura")
            });
        });
    },

};

export const invoices = {
    actions,
    mutations,
    getters,
    state,
};
