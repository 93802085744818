
    import {Component, Prop, Vue, Watch} from 'vue-property-decorator';


    @Component({
        name:'date-picker',
    })
    export default class DatePicker extends Vue{
    @Prop({default:false}) isShowingDatePicker:boolean;
    public selectedDate: Date | string = '';

    @Watch("isShowingDatePicker")
    onChanged(n){
        if(n){
            setTimeout(() => {
                const picker : any = this.$refs.picker;
                picker.activePicker = 'YEAR'
            }, 10);
        }
    }

    public handleCancelEvent(event:string):void{
        this.$emit('cancel', event);
    }

    public handleSaveEvent(event:string):void{
        this.$emit('save', event, this.selectedDate);
    }



    }
