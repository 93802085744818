import Vue from 'vue';
import Vuetify from 'vuetify';
import 'vuetify/dist/vuetify.min.css'
import {IS_DEVELOPING} from "../main";
Vue.use(Vuetify, {
  iconfont: 'md',
});

//let primaryColor = "#876AFF";
let primaryColor = "#3f51b5";

let accentColor = "#4D2CB3";

if(window.location.hostname == "localhost"){
  // primaryColor = "#59b5b1";
  //accentColor = "#3e827f"
}

  export default new Vuetify({
  icons: {
    iconfont: 'mdi', // default - only for display purposes
  },
  theme: {
    // dark: true,
    themes: {
      light: {
        primary: primaryColor,
        secondary: '#b0bec5',
        accent: accentColor,
        error: '#b71c1c',
        success: '#56DCB6',
      },
    },
  },
})
