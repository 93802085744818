
import { Component, Vue, Prop } from 'vue-property-decorator';
import {Getter,  Action} from "vuex-class";
import CustomButton from "../../global/styledComponents/customButton.vue";
import Loader from "../../global/Loader.vue";
import {IServiceCategory} from "@/store/appointmentTypes";
import {IAppointmentTypeDTO, IStaffMemberDTO} from "@/types/AutoGenerated/reinforcedTypings";
import {DialogMutations, DialogType, IDialog} from "@/store/dialog";
import TreatmentTypes from "@/components/Settings/Services/TreatmentTypes.vue";

@Component({
    name:'extra-services',
    components:{TreatmentTypes, Loader, CustomButton}
})
export default class ExtraServices extends Vue {
}
