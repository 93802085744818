
import { Component, Vue } from 'vue-property-decorator';
import {Getter,  Action} from "vuex-class";
import CustomButton from "../../global/styledComponents/customButton.vue";
import Loader from "../../global/Loader.vue";
import {IResourceDTO, IStaffMemberDTO} from "@/types/AutoGenerated/reinforcedTypings";
import {DialogMutations, DialogType, IDialog} from "@/store/dialog";

@Component({
    name:'resources',
    components:{Loader, CustomButton}
})
export default class Resources extends Vue {

    public newResource:Partial<IResourceDTO> = {};
    public isShowingForm:boolean = false;

    private isLoading:boolean = true;
    private editingResource: Partial<IResourceDTO> = null;
    private isEditing: boolean = false;
    public autoApply: boolean = true;
    private isCreating: boolean = false;

    @Action public getResources:() => Promise<any>;
    @Action public editResource:(resource: Partial<IResourceDTO>) => Promise<any>;
    @Action public addResource:(resource: Partial<IResourceDTO>) => Promise<any>;
    @Action public deleteResource:(resourceId: number) => Promise<any>;
    @Getter public resources : Array<IResourceDTO>;

    @Action public getCurrentUser:Function;
    @Getter public user;
    @Action public postSnackbarMessage:(message:string) => void;
    @Getter public staffMembers: IStaffMemberDTO[];


    public toggleAdd():void{
      let newEditResource : Partial<IResourceDTO> = {
        amount: 1,
        description: "",
        name: "",
        userId: this.user.id
      }
      this.editingResource = newEditResource;
        this.isCreating = true;
        this.isShowingForm = !this.isShowingForm;
    };

    async runAddNewAppointmentType(resource:Partial<IResourceDTO>):Promise<void>{
        if(resource.name == null || resource.name == ""){
            this.postSnackbarMessage("Navn på oppgis");
            return;
        }

        if(resource.amount == null || resource < 1){
            this.postSnackbarMessage("Antall må være 1 eller høyere.");
            return;
        }

        this.isLoading = true;
        this.isShowingForm = false;

        await this.addResource(resource);

        this.isCreating = false;
        this.editingResource = null;
        this.clearForm();
        this.isLoading = false;
    };

    clearForm():void{
        this.newResource.name = '';
        this.newResource.amount = 1;
        this.newResource.description = "";
    }

     async runDeleteAppointmentType(resourceId:number):Promise<void>{
        const vm = this;

         let dialog : IDialog = {
             text: "Er du sikker på at du vil slette denne ressursen?",
             type: DialogType.Choice,
             header: "Slett ressurs",
             action: function(){
                 vm.deleteResource(resourceId);
             }
         };
         vm.$store.commit(DialogMutations.AddToDialogQueue, dialog);

    }
    editService(resource){
        this.isEditing = true;
        this.editingResource = resource;
    }
    runEditService(){
        this.editResource(this.editingResource);
        this.isEditing = false;
        this.editingResource = null;
    }

    async runCreateResource(){
        await this.addResource(this.editingResource);
        this.isCreating = false;
    }

    async created():Promise<void> {
        this.getCurrentUser();
        await this.getResources();
        this.isLoading = false;
    }

    get showEditDialog(){
        return this.isEditing || this.isCreating;
    }

    set showEditDialog(value){
        this.isEditing = value;
        this.isCreating = value;
    }

    booleanToNorwegian(value: boolean): string {
        if (value) {
            return "Ja"
        } else {
            return "Nei"
        }
    }

}
