

    import Vue from 'vue'
    import Component from 'vue-class-component'
    import {
        State,
        Getter,
        Action,
        Mutation,
        namespace
    } from 'vuex-class'
    import {Prop} from "vue-property-decorator";

    @Component({
        name: "SettingCategory",
        components: {}
    })

    export default class SettingCategory extends Vue {

        @Prop({}) icon;
        @Prop({}) label;
        @Prop({}) isExpanded;

        created() {

        }
        onClick(){
            this.$emit("click");
        }
    }

