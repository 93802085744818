

    import Vue from 'vue'
    import Component from 'vue-class-component'
    import {IUserBookingSettingsDTO} from "../../../../store/workingHours";
    import {
        Getter,
        Action,
    } from 'vuex-class'
    import {Prop, Watch} from "vue-property-decorator";
    import {IUser} from "../../../../store/user";
    import Loader from "../../../global/Loader.vue";
    import {IDialog} from "../../../../store/dialog";
    import {IStaffMemberDTO} from "../../../../types/AutoGenerated/reinforcedTypings";

    @Component({
        name: "WorkingHours",
        components: {Loader}
    })

    export default class WorkingHours extends Vue {

        @Prop({default: null}) staffMember: IStaffMemberDTO;

        //Vuex

        @Action public updateWorkingDays: Function;
        @Action public patchUser: Function;
        @Action public getCurrentUser: Function;
        @Action public addToDialogQueue: Function;
        @Action public updateBookingSettings: Function;
      @Action public getBookingSettings: Function;
      @Action public getPauseTimes: Function;
        @Action public postSnackbarMessage: Function;
        @Action public getAllWorkingDays: Function;
      @Action public getBookingSettingsIntervals: Function;
      @Action public deletePauseTime: Function;
      @Action public addPauseTime: Function;

      @Getter public allWorkingDays;
      @Getter public pauseTimes;
        @Getter public user: IUser;

        public bookingSettings: IUserBookingSettingsDTO | null = null;
        public isWorkingToday = false;
        public dayIndex = 0;
        public isEditingHours = false;
        public isLoading: boolean = true;

        public allHoursValid: boolean = true;
      public newPauseStart = "";
      public newPauseEnd = "";


        public workingHourRule = [
            v => !!v || 'Tidspunkt er påkrevd',
            (v) => {
                let pattern = /^(0[0-9]|1[0-9]|2[0-3]):[0-5][0-9]$/
               return pattern.test(v) || 'Ugyldig format (HH:MM)'
            }

        ];

        public selectedDay = null;

        @Watch("staffMember")
         onStaffMemberChange(){
            //If the staff member is changed without destorying the component

            this.isLoading = true;
             this.initializeWorkingHours();
        }

        get days() {

            return [{
                label: "Mandag",
                weekDayName: "Monday",
                weekdayIndex: 1
            },
                {
                    label: "Tirsdag",
                    weekDayName: "Tuesday",
                    weekdayIndex: 2
                },
                {
                    label: "Onsdag",
                    weekDayName: "Wednesday",
                    weekdayIndex: 3
                },
                {
                    label: "Torsdag",
                    weekDayName: "Thursday",
                    weekdayIndex: 4
                },
                {
                    label: "Fredag",
                    weekDayName: "Friday",
                    weekdayIndex: 5
                },
                {
                    label: "Lørdag",
                    weekDayName: "Saturday",
                    weekdayIndex: 6
                },
                {
                    label: "Søndag",
                    weekDayName: "Sunday",
                    weekdayIndex: 0
                },]
        }


        get staffMemberId(){
            let staffMemberId = 0;
            if(this.staffMember != null){
                staffMemberId = this.staffMember.id;
            }
            return staffMemberId;
        }


        async initializeWorkingHours(){


            this.isLoading = true;
            if (this.bookingSettings == null) {
                this.isLoading = true;
            }

            this.bookingSettings = await this.getBookingSettings({staffMemberId: this.staffMemberId});
            await this.getBookingSettingsIntervals({staffMemberId: this.staffMemberId});
            await this.getAllWorkingDays({staffMemberId: this.staffMemberId});
            //@ts-ignore
            this.isWorkingToday = this.allWorkingDays[this.days[0]];
            this.selectDayToPickWorkingHours(this.days[0]); //Chose monday for editing by default
            await this.getPauseTimes({staffMemberId: this.staffMemberId});
           this.isLoading = false;

        }
        async created() {
           await this.initializeWorkingHours();
        }



        public selectDayToPickWorkingHours(selectedDay) {
            this.selectedDay = selectedDay;
            this.dayIndex = this.days.indexOf(selectedDay);
            this.updateWorkingHoursAndWorkingDaysListOnDay(selectedDay);
            this.isWorkingToday = this.allWorkingDays[this.selectedDay.weekdayIndex];
        }

        public booleanToNorwegian(value) {
            if (value) {
                return "Ja"
            } else {
                return "Nei"
            }
        }

        public toggleWorkingDay(newValue: boolean): void {
            let selectedDayToEnglish = this.selectedDay.weekDayName;
            let payload = {
                dayOfWeek: selectedDayToEnglish,
                isWorking: newValue,
                staffMemberId: this.staffMemberId
            };

            //PUT to server
            this.updateWorkingDays(payload);

            this.allWorkingDays[this.dayIndex] = newValue;

        }


        get openingHourLabel() {
            return "Åpningstid for " + this.selectedDay.label;
        }

        get closingHourLabel() {
            return "Stengetid for " + this.selectedDay.label;
        }

        async saveChangesToBookingSettings() {
            if(!this.allHoursValid){
                let dialog : IDialog = {
                    text: "Kunne ikke oppdatere åpningstider. Sjekk om du har skrevet inn riktig format (HH:MM)"
                };

                this.addToDialogQueue(dialog);
                return;
            }
            await this.updateBookingSettings({staffMemberId: this.staffMemberId, workingHours: this.bookingSettings});

            this.postSnackbarMessage("Åpningstider oppdatert.");

            await this.getBookingSettingsIntervals({staffMemberId: this.staffMemberId});
            await this.getBookingSettings({staffMemberId: this.staffMemberId});
            await this.getAllWorkingDays({staffMemberId: this.staffMemberId});
        }

        public async updateWorkingHoursAndWorkingDaysListOnDay(day): Promise<void> {
            this.dayIndex = day.weekDayIndex;
            this.isWorkingToday = this.allWorkingDays[this.dayIndex];

        }

        get pauseTimesOnSelectedWeekDay(){
          const vm = this;
          if(this.pauseTimes == null){
            return [];
          }
          if(typeof this.pauseTimes.filter != "function"){
            return [];
          }
          const found = this.pauseTimes.filter((x) => {
            return x.dayOfWeek == vm.selectedDay.weekdayIndex;
          })
          return found;

        }

      onDeletePause(id){
        let payload = {
          id: id,
          staffMemberId: this.staffMemberId
        };

          this.deletePauseTime(payload);
      }
      addPause() {
        const weekDayIndex = this.selectedDay.weekdayIndex;
        const start = this.newPauseStart;
        const end = this.newPauseEnd;
        const staffMemberId = this.staffMemberId;
        const payload = {
          staffId: staffMemberId,
          dayOfWeek: weekDayIndex,
          start: start,
          end: end
        }
        this.addPauseTime(payload);
      }

    }

