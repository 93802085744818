
    import { Component, Vue } from 'vue-property-decorator';
    import Sidebar from "../../components/global/Sidebar.vue";
    import HeaderBar from "../../components/global/MainHeader.vue";
    import CustomerModal from "../../components/global/CustomerModal.vue";
    import Loader from "../../components/global/Loader.vue";
    import {actionStringCustomers, ICustomer} from "@/store/customers";
    import {Action} from "vuex-class";
    import {DialogActions, DialogType, IDialog} from "@/store/dialog";
    import EditCustomer from "@/components/global/EditCustomer.vue";
    import {IAuditLogDTO} from "@/types/AutoGenerated/reinforcedTypings";


    @Component({
        name:'customer',
        components:{EditCustomer, Loader, CustomerModal, Sidebar, HeaderBar}
    })
    export default class Customer extends Vue{

        public customer: ICustomer = null;
        @Action(DialogActions.AddToDialogQueue) addToDialogQueue:(dialog: IDialog) => void;
        @Action(actionStringCustomers.GET_CUSTOMER) getCustomer:(customerId: string) => Promise<ICustomer>;
        @Action public deleteCustomer:Function;
        @Action public postSnackbarMessage:(message: string) => void;
      @Action postAuditLog: Function;

      deleteCustomerDialog: boolean = false;
      editing: boolean = false;

        onEditedCustomer(){
            this.loadCustomer();
        }
        async created(){
            await this.loadCustomer();

          let customerId:string = this.$route.params.customerId;


          let audit: Partial<IAuditLogDTO> = {
            action: 3,
            type: 4,
            comment: "",
            message: " opened customer page: " + customerId,
            hideForUser: true,
          };

          this.postAuditLog(audit);
        }
        async loadCustomer(){

            let customerId:string = this.$route.params.customerId;
            try{
                this.customer = await this.getCustomer(customerId);
            } catch(e){
                let dialog: IDialog = {
                    text: "Fant ikke denne kunden."
                };
                this.addToDialogQueue(dialog);
                this.goBack();
            }
        }

        goBack(){
            this.$router.push("/kunder");
        }

        async deletedCustomer(){
            try{
              await this.deleteCustomer(this.customer.id);
                this.postSnackbarMessage('Kunden: ' + this.customer.firstName + ' er slettet');
                this.goBack();
            } catch(e){
                this.postSnackbarMessage('Kunne ikke slette kunde.');

            }


        }

      public editCustomer():void{
        this.editing = true;
      }

      finished(){
        this.editing = false;
        this.$emit("finished");
      }



      deleteCustomerAction(){

        let vm = this;

        let dialog : IDialog = {
          text: "Er du sikker på at du vil slette denne kunden?",
          type: DialogType.Choice,
          header: "Slett kunde",
          action: function(){
            if(vm.customer != null){
              vm.deletedCustomer();
              vm.finished();

              vm.deleteCustomerDialog = false;

            }
          }
        };

        this.addToDialogQueue(dialog);

      }
      deleted(customerId){
        this.$emit("deleted", customerId);
      }

      callCustomer(){

        let audit: Partial<IAuditLogDTO> = {
          action: 3,
          type: 4,
          comment: "",
          message: " called from customer page: " + this.customer.phoneNumber,
          hideForUser: true,
        };

        this.postAuditLog(audit);

          let phone = "tel: " + this.customer.phoneNumber;
        window.open(phone, '_self');
      }

      smsCustomer(){

        let audit: Partial<IAuditLogDTO> = {
          action: 3,
          type: 4,
          comment: "",
          message: " opened text-message from customer page: " + this.customer.phoneNumber,
          hideForUser: true,
        };

        this.postAuditLog(audit);

        let phone = "sms:/" + this.customer.phoneNumber + "/";
        window.open(phone, '_self');
      }

    }
