import Vue from 'vue';
import {store} from './store/store';
import router from './router';
import App from './App.vue';
import './Utilities/authHandler';
import VueStripeCheckout from 'vue-stripe-checkout';
import Inject from './plugins/Inject';
import {AppointmentDistribution} from '@/types/AppointmentDistribution';
import VueQrcodeReader from 'vue-qrcode-reader';
import {Sector} from '@/types/Sector';
import {ButtonSize, ModalContent} from '@/types/ButtonSize';
import {MessageType} from '@/types/messageBarTypes';
import wysiwyg from 'vue-wysiwyg';
import {Wysiwyg} from 'vue-wysiwyg';
import axios from 'axios';
import "vue-wysiwyg/dist/vueWysiwyg.css";
import VueGtag from "vue-gtag";
// import VueGapi from 'vue-gapi''

import wb from "./service-worker";
Vue.prototype.$workbox = wb;

import vuetify from '@/plugins/vuetify' // path to vuetify export
new Vue({
  router,
  store,
  vuetify: vuetify,
  render: (h) => h(App),
}).$mount('#app');

export const enum Language{
  NORWEGIAN = 'no',
  ENGLISH = 'en',
}


export const LANG = Language.NORWEGIAN;
export let APIURL2:string;
export let USE_DEV_SITE:boolean;
export let IS_DEVELOPING:boolean;
export let BOOKING_URL:string;
export let NEW_BOOKING_URL:string;


// IS_DEVELOPING = window.location.hostname === "localhost";
USE_DEV_SITE = false;


let STRIPE_PUBLIC_KEY = 'pk_test_pOnz4aVIcgOq5uWsMd1KrO5z';
APIURL2 = 'https://localhost:5001/api/';
 // APIURL2 = 'https://timebestillerwebapp.azurewebsites.net/api/'

IS_DEVELOPING = true;

try{
  // @ts-ignore
  BOOKING_URL =  azurevar_bookingurl_endazurevar;
} catch(e){
  BOOKING_URL = "http://localhost:8081/";
}

try{
  // @ts-ignore
  NEW_BOOKING_URL =  azurevar_newbookingurl_endazurevar;
} catch(e){
  NEW_BOOKING_URL = "http://localhost:8081/";
}

try{
  // @ts-ignore
  APIURL2 =  azurevar_api_url_token_endazurevar;
  IS_DEVELOPING = false;
} catch(error){
  if(window.location.hostname !== "localhost"){
    console.warn('API URL IS UNDEFINED');
  }

}
try{
  // @ts-ignore
  STRIPE_PUBLIC_KEY = azurevar_stripe_pk_key_token_endazurevar;

} catch(e){
  if(window.location.hostname !== "localhost"){
    console.warn('STRIPE TOKEN IS UNDEFINED');
  }
}

Vue.use(VueStripeCheckout, STRIPE_PUBLIC_KEY);


// Translation provided by Vuetify (typescript)
//import no from 'vuetify/src/locale/no'


// Vue.use(Vue2TouchEvents);

Vue.use(VueGtag, {
  config: { id: "G-1FVGFKQSCW" }
});
// Vue.use(VueGapi, {
//   apiKey: 'AIzaSyBnqnHsXguX7mk97vUsnXT8X0EmSZ6_6uQ',
//   clientId: '605449339616-o0rj3sod6m1v9i0f820jho90jk7be8u8.apps.googleusercontent.com',
//   discoveryDocs: ['https://www.googleapis.com/discovery/v1/apis/calendar/v3/rest'],
//   scope: 'https://www.googleapis.com/auth/calendar',
// })

Vue.use(wysiwyg, {locale: 'no', hideModules: {'image' : true}});
Vue.use(VueQrcodeReader);
Vue.use(Inject,{
  AppointmentDistribution,
  Sector,
  ButtonSize,
  ModalContent,
  MessageType
});
// Vue.use(Trend)
Vue.config.productionTip = false;



export function getStripePublicKey(){
  return STRIPE_PUBLIC_KEY;
}

export function getApiUrl(){
  return APIURL2;
}
