

    import Vue from 'vue'
    import Component from 'vue-class-component'
    import {
        Action,
        Getter,
    } from 'vuex-class'
    import ToolTip from "../../ToolTip.vue";
    import {IUserDTO} from "@/types/AutoGenerated/reinforcedTypings";

    @Component({
        name: "BookingNotificationSettings",
        components: {ToolTip}
    })

    export default class BookingNotificationSettings extends Vue {

        @Getter user : IUserDTO;
        @Action patchUser: Function;

        public recieveEmailOnAppointment : boolean  = false;
        public recieveSMSOnAppointment : boolean  = false;
        public emailAddressToReceive : string  = "";
        public phoneNumberToReceive : string  = "";

        created() {
            this.recieveEmailOnAppointment = this.user.recieveEmailOnAppointment;
            this.recieveSMSOnAppointment = this.user.recieveSMSOnAppointment;
            this.emailAddressToReceive = this.user.emailAddressToReceive;
            this.phoneNumberToReceive = this.user.phoneNumberToReceive;
        }

        async saveChanges(){
            let updatedUserPayload: Partial<IUserDTO> = {
                emailAddressToReceive: this.emailAddressToReceive,
                phoneNumberToReceive: this.phoneNumberToReceive,
                recieveEmailOnAppointment: this.recieveEmailOnAppointment,
                recieveSMSOnAppointment: this.recieveSMSOnAppointment,
            };

            await this.patchUser(updatedUserPayload);
        }

        booleanToOnOff(value: boolean): string {
            if (value) {
                return "På"
            } else {
                return "Av"
            }
        }
    }

