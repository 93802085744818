
    import { Component, Vue } from 'vue-property-decorator';
    import Sidebar from "../../components/global/Sidebar.vue";
    import HeaderBar from "../../components/global/MainHeader.vue";
    import CustomerModal from "../../components/global/CustomerModal.vue";
    import Loader from "../../components/global/Loader.vue";
    import {Action, Getter} from "vuex-class";
    import {DialogActions, IDialog} from "../../store/dialog";
    import {actionStringAppointments, IAppointmentPatch} from "../../store/appointments/appointments";
    import {formatDate, FormatSpaceType, formatTime} from "../../types/formatDateHelper";
    import InvoiceCreator from "../../components/InvoiceCreator.vue";
    import {IInvoice, InvoiceActions} from "../../store/Invoices";
    import InvoicesList from "../../components/InvoicesList.vue";
    import AppointmentMenu from "../../components/AppointmentMenu.vue";
    import {IPayment, PaymentActions} from "../../store/Payments";
    import PaymentList from "../../components/PaymentList.vue";
    import PaymentCreator from "../../components/global/PaymentCreator.vue";
    import {
      IAppointmentDTO,
      IAppointmentInfoDTO,
      IAuditLogDTO,
      IUserDTO
    } from "../../types/AutoGenerated/reinforcedTypings";
    import {IUser} from "../../store/user";
    import {formatDateStringHour} from "../../Utilities/dateUtility";
    import AppointmentSummary from "@/components/global/AppointmentSummary.vue";


    @Component({
        name:'appointment',
        components:{
          AppointmentSummary,
            PaymentCreator,
            PaymentList,
            AppointmentMenu,
            InvoicesList, InvoiceCreator, Loader, CustomerModal, Sidebar, HeaderBar}
    })
    export default class Appointment extends Vue{

        public appointment: IAppointmentInfoDTO = null;
        @Action(actionStringAppointments.GET_APPOINTMENT_BY_NUMBER) getAppointment:(appointmentNumber: number) => Promise<IAppointmentInfoDTO>;
        @Action(InvoiceActions.GetInvoicesForAppointment) getInvoicesForAppointment:(appointmentId: number) => Promise<Array<IInvoice>>;
        @Action(PaymentActions.GET_FOR_APPOINTMENT) public getPaymentsForAppointment:(appointmentId: number) => Promise<Array<IPayment>>;


        @Action public deleteCustomer:Function;
        @Action public postSnackbarMessage:(message: string) => void;
        @Action(DialogActions.AddToDialogQueue) addToDialogQueue:(dialog: IDialog) => void;

        @Action patchAppointment: Function;

        @Action getAppointments: Function;

        @Getter user:IUserDTO;

      @Action postAuditLog: Function;

        public newPayment:boolean = false;
        public movingAppointment:boolean = false;
        public isLoadingPayments:boolean = false;



        public invoices: Array<IInvoice> = [];
        public payments: Array<IPayment> = [];


        get invoiceStatuses(){
            if(this.user.enablePaymentModule){
                return [
                    "Ny",
                    "Faktura opprettet",
                    "Faktura sendt",
                    "Faktura betalt",
                    "Ferdig",
                ]
            } else {
                return [
                    "Ny",
                    "Ferdig",
                ]
            }
        }


        async loadAppointment() {

            const params: any = this.$route.params;

            let appointmentNumber: number = params.appointmentNumber;

            try {
                this.appointment = await this.getAppointment(appointmentNumber);

                this.invoices = await this.getInvoicesForAppointment(this.appointment.id);
                this.isLoadingPayments = true;
                this.payments = await this.getPaymentsForAppointment(this.appointment.id);
                this.isLoadingPayments = false;

            } catch (e) {
                let dialog: IDialog = {
                    text: "Fant ikke denne  bestillingen."
                };
                this.addToDialogQueue(dialog);
                this.goBack();
            }
        }
        async created(){
            await this.loadAppointment();
          const params: any = this.$route.params;
          let appointmentNumber: number = params.appointmentNumber;


          let audit: Partial<IAuditLogDTO> = {
            action: 3,
            type: 4,
            comment: "",
            message: " went to appointment page - appoinment: " + appointmentNumber,
            hideForUser: true,
          };

          this.postAuditLog(audit);

        }

        goBack(){

          this.$store.commit("setAppointmentsSource", null);

          if(this.$store.getters.appointmentSource != null){
            this.$router.push("/" + this.$store.getters.appointmentSource);

          } else {
            this.$router.push("/bestillinger");
          }
        }

        formatDateCorrectly(day:string, month:string, year:string):string{
            let date = month + "/" + day + "/" + year;
            let datObject = new Date(date);
            return  formatDate(datObject, FormatSpaceType.DOT);
        }

        formatTimeCorrectly(hour:string | number, minute:string | number):string{
            return formatTime(hour, minute);
        }

        updateAdminComment(){

            let update : Partial<IAppointmentDTO> = {
                adminNote: this.appointment.adminNote,
            };

            const payload : IAppointmentPatch = {
                appointmentId: this.appointment.id,
                patch: update
            };

            this.patchAppointment(payload);

        }

        formatDateStringHour(date: Date){
            return formatDateStringHour(date);
        }

        getColor (eventId) {
            if (eventId == "Ny") return '#E0E0E0';
            if (eventId == "Faktura opprettet") return '#a5c6d9';
            if (eventId == "Faktura sendt") return '#a5c6d9';
            if (eventId == "Ferdig") return '#a5d9af';
            return "grey";
        }

        async onFinished(){
            this.newPayment = false;
            this.payments = await this.getPaymentsForAppointment(this.appointment.id);
            await this.loadAppointment();
        }



        moveAppointment(){
           this.movingAppointment = true;
        }

        async onMovedAppointment(){
            this.movingAppointment = false;
            await this.loadAppointment();
        }
        async onChangeCustomer(){
            await this.loadAppointment();
        }

        deleteAppointment(){

        }

        onDeleted(){
            this.goBack();
        }

        get appointmentStart(){

            const chars = "2020-06-07T07:30:00";

            const start = new Date(this.appointment.year, this.appointment.month - 1, this.appointment.day, this.appointment.hour, this.appointment.minute, 0);
            const tzoffset = (new Date()).getTimezoneOffset() * 60000; //offset in milliseconds
          //@ts-ignore
            const localISOTime = (new Date(start - tzoffset)).toISOString().slice(0, -1);

            return localISOTime.substr(0, chars.length);
        }

        booleanToNorwegian(value: boolean): string {
            if (value) {
                return "Ja"
            } else {
                return "Nei"
            }
        }

        formatAppointmentEnd(){
            let start = new Date(this.appointment.year, this.appointment.month - 1, this.appointment.day, this.appointment.hour, this.appointment.minute, 0);
            //@ts-ignore
            let end = new Date(start.getTime() + (this.appointment.appointmentDuration*1000*60));
            let h = end.getHours();
            let m = end.getMinutes();
            return this.formatTimeCorrectly(h, m);
        }

        async updateInvoiceStatus(event, invoice){

            let update : Partial<IAppointmentDTO> = {
                status: event,
            };

            const payload : IAppointmentPatch = {
                appointmentId: this.appointment.id,
                patch: update
            };

            await this.patchAppointment(payload);

            await this.getAppointments();
        }



    }
