

    import Vue from 'vue'
    import Component from 'vue-class-component'
    import {
        State,
        Getter,
        Action,
        Mutation,
        namespace
    } from 'vuex-class'
    import CustomButton from "./global/styledComponents/customButton.vue";
    import CustomInput from "./global/styledComponents/CustomInput.vue";

    import firebase from 'firebase/auth';
    import fb from 'firebase';
    import {Prop} from "vue-property-decorator"; //TODO: Super WTF. If I change this to "firebase/app" -> Then authHandler.ts' firebase reference will break. WTF!?


    @Component({
        name: "RegisterFirebaseAccount",
        components: {CustomInput, CustomButton}
    })

    export default class RegisterFirebaseAccount extends Vue {
        @Prop({default: false}) public onBoardingOnRegister: boolean;

        @Action public registerUser:Function;
        @Action public postSnackbarMessage:(message:string) => void;

        hasRegistered:boolean = false;

        password:string = '';
        password2:string = '';
        email:string = '';

        created() {

        }


        public async register():Promise<void> {


            if (this.email === "" || this.password == "") {
                this.postSnackbarMessage("Email og passord må fylles ut.");
                return;
            }

            if (this.password !== this.password2) {
                this.postSnackbarMessage("Passordene må være like.");
                return;
            }

            try {
                if(this.onBoardingOnRegister){
                    this.$store.commit("setIsNewUser", true);
                }
                this.hasRegistered = true;


                await fb.auth().createUserWithEmailAndPassword(this.email, this.password);


            } catch (error) {
                this.$store.commit("setIsNewUser", false);
                let errorCode = error.code;
                if (errorCode === "auth/email-already-in-use") {
                    this.postSnackbarMessage("Denne e-post adressen er opptatt.");
                }
                if (errorCode === "auth/invalid-email") {
                    this.postSnackbarMessage("Ugyldig e-post adresse.");
                }
                if (errorCode === "auth/weak-password") {
                    this.postSnackbarMessage("Passord må innholde minst 6 tegn.");
                }
            }
        }
    }

