

import Vue from 'vue'
import Component from 'vue-class-component'
import {
  State,
  Getter,
  Action,
  Mutation,
  namespace
} from 'vuex-class'
import NotificationBell from "./global/NotificationBell.vue";
import {IAuditLogDTO} from "../types/AutoGenerated/reinforcedTypings";
import CreateAppointment from "./global/CreateAppointment.vue";
import {Watch} from "vue-property-decorator";

@Component({
  name: "MobileHeader",
  components: {CreateAppointment, NotificationBell}
})

export default class MobileHeader extends Vue {

  @Action postAuditLog: Function;

  @Mutation public setDefaultCustomerToBook: Function;
  @Action public selectDateToBook: Function;
  @Action getBookedAppointmentsForDay: Function;


  public group2 = null;
  public bookOpen = false;
  public width = false;
  public height = false;


  async getEvents() : Promise<void> {
    let date = new Date();
    await this.getBookedAppointmentsForDay(date);
  }

  @Watch("$route")
  async onRouterChange(){
    let p = this.$router.currentRoute.path
    console.log(this.$router.currentRoute.path)
    if(p === "/" || p === "/admin"){
      this.group2 = 0;
      return;
    }
    if(p === "/kunder"){
      this.group2 = 2;
      return;

    }
    if(p === "/kalender"){
      this.group2 = 1;
      return;

    }
    if(p === "/hendelser"){
      this.group2 = 4;
      return;
    }
    if(p === "/innstillinger"){
      this.group2 = 5;
      return;
    }
    if(p === "/bestillinger"){
      this.group2 = 3;
      return;
    }
    this.group2 = 21328203;
  }

  async addedAppointment() {
    this.bookOpen = false;
    await this.getEvents();
  }

  book(){
    let audit: Partial<IAuditLogDTO> = {
      action: 3,
      type: 4,
      comment: "",
      message: " opened new appointment @ mobile menu",
      hideForUser: true,
    };

    this.postAuditLog(audit);


    this.setDefaultCustomerToBook(null);

    let now = new Date();
    this.selectDateToBook({year: now.getFullYear(), month: now.getMonth(), day: now.getDate()});
    this.bookOpen = true;
  }

  clickItem(item){
    if(item.index == 2){

    } else {

    this.group2 = item.index;
    }
    if(item.action != null){
      item.action();
    } else {
      let audit: Partial<IAuditLogDTO> = {
        action: 3,
        type: 4,
        comment: "",
        message: " click mobile item: " + item.route,
        hideForUser: true,
      };

      this.postAuditLog(audit);

      this.$router.push(item.route);
    }
  }

   isTablet(){
    return document.documentElement.clientWidth > 750;
  }

  get itemList(){
    let vm = this;
    let mobileItems = [
      {  index: 0, alt:'hjem', text:'Hjem', image:'../img/mobile/dashboard.svg',image2:'../img/mobile/dashboard_fill.svg', route: '/admin', icon: "dashboard"},
      {  index: 1,alt:'kalender', text:'Kalender', image:'../img/mobile/calendar.svg',  image2:'../img/mobile/calendar_fill.svg', route: '/kalender', icon:"calendar"},
      // { index: 2, alt:'kunder', text:'Kunder', image:'../img/mobile/customer.svg', image2:'../img/mobile/customer_fill.svg', route: '/kunder', icon: "account_circle"},
      // {  index: 2, alt:'legg til', text:'Ny', image:'../img/mobile/add.svg', image2:'../img/mobile/add_fill.svg', action: function() { vm.book() }, icon: "account_circle"},
      // { alt:'Tjenester', text:'Tjenester', image:'../img/service.png', route: '/tjenester', icon: "add_shopping_cart"},
      // { alt:'innstillinger', text:'Innstillinger', image:'../img/white-icons/settings.png', route: '/innstillinger', icon:"calendar_today"},
      // { alt:'hendelser', text:'Hendelser', image:'../img/book.png', route: '/hendelser', icon:"inbox"},
      // { alt:'Gavekort', text:'Gavekort', image:'../img/gift-card.png', route: '/gavekort'},
    ];

    let iPadItems = [
      {  index: 0, alt:'hjem', text:'Hjem', image:'../img/mobile/dashboard.svg',image2:'../img/mobile/dashboard_fill.svg', route: '/admin', icon: "dashboard"},
      {  index: 1,alt:'kalender', text:'Kalender', image:'../img/mobile/calendar.svg',  image2:'../img/mobile/calendar_fill.svg', route: '/kalender', icon:"calendar"},
      { index: 2, alt:'kunder', text:'Kunder', image:'../img/mobile/customer.svg', image2:'../img/mobile/customer_fill.svg', route: '/kunder', icon: "account_circle"},
      // {  index: 2, alt:'legg til', text:'Ny', image:'../img/mobile/add.svg', image2:'../img/mobile/add_fill.svg', action: function() { vm.book() }, icon: "account_circle"},
      // {  index: 3, alt:'Tjenester', text:'Tjenester', image:'../img/service.png', route: '/tjenester', icon: "add_shopping_cart"},
      {  index: 3, alt:'bestillinger', text:'Bestillinger', image:'../img/mobile/overview.svg', image2:'../img/mobile/overview_fill.svg', route: '/bestillinger', icon:"inbox"},
      {  index: 5, alt:'innstillinger', text:'Innstillinger',  image:'../img/mobile/settings.svg', image2:'../img/mobile/settings_fill.svg', route: '/innstillinger', icon:"calendar_today"},

      // { alt:'Gavekort', text:'Gavekort', image:'../img/gift-card.png', route: '/gavekort'},
    ];


    if(this.width > 800){
      return iPadItems;
    } else {
      return mobileItems;

    }
  }

  isMobile(){
    return  /Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent);
  }

  getDimensions() {
    this.width = document.documentElement.clientWidth;
    this.height = document.documentElement.clientHeight;
    console.log("Width: ", this.width);
  }


  public async mounted():Promise<void> {
    const vm = this;

    if (this.isMobile()) {
      this.width = document.documentElement.clientWidth;
      this.height = document.documentElement.clientHeight;
      window.addEventListener('resize', this.getDimensions);
    }
  }


  public toggleDropDownMenu():void{
    this.$store.commit("setMobileNavigation", true);

    let audit: Partial<IAuditLogDTO> = {
      action: 3,
      type: 4,
      comment: "",
      message: " opened mobile menu",
      hideForUser: true,
    };

    this.postAuditLog(audit);

  }

  get hideMobileFooter(){
    return this.$store.getters.hideMobileFooter;
  }

  get iconSize(){
    var width = window.innerWidth
        || document.documentElement.clientWidth
        || document.body.clientWidth;

    if(width < 420){
      return 24;
    } else {
      return 32;
    }
  }

  created() {
this.onRouterChange()
  }
}

