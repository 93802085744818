
// @ts-nocheck

    import Vue from 'vue'
    import Component from 'vue-class-component'
    import {
        Getter,
        Action,
        Mutation,
    } from 'vuex-class'
    import {IInvoice, InvoiceActions, InvoiceMutations} from "../store/Invoices";
    import {IUser} from "../store/user";

    @Component({
        name: "InvoiceSender",
        components: {}
    })

    export default class InvoiceSender extends Vue {
        markAsPaid :boolean = true;
        public sendingInvoiceCC: string = "";


        @Action(InvoiceActions.SendInvoiceToCustomer) public sendInvoiceToCustomer:(payload) => Promise<number>;
        @Action public addToDialogQueue:Function;
        @Action public postSnackbarMessage: Function;
        @Mutation(InvoiceMutations.SetSendingInvoice) public setSendingInvoice:(payload: IInvoice) => void;
        @Getter public sendingInvoice : IInvoice;


        sendInvoiceValid: boolean = true;


        public emailRules = [
            v => !!v || 'Epost er påkrevd',
            v => /.+@.+\..+/.test(v) || 'Epost må være gyldig',
        ];

        created() {
            this.sendingInvoiceCC = this.user.emailAddress;
        }

        get showSendingInvoice(){
            return this.sendingInvoice != null;
        }

        set showSendingInvoice(v){
            if(v == false){
                this.setSendingInvoice(null);
                this.setSendingInvoice(null);
            }

        }

        cancelSend(){
            this.showSendingInvoice = false
        }


        @Getter public user : IUser;


        async sendInvoiceToEmailAddress(){
            let payload = {
                id: this.sendingInvoice.id,
                emailAddress: this.sendingInvoice.customerEmailAddress,
                cc: this.sendingInvoiceCC,
                markAsPaid: this.markAsPaid
            };

            await this.sendInvoiceToCustomer(payload);
            this.postSnackbarMessage("Faktura sendt!");
            this.showSendingInvoice = false;
            this.$emit("sent");
            this.setSendingInvoice(null);

        }


    }

