

    import Vue from 'vue'
    import Component from 'vue-class-component'
    import {
        Action,
    } from 'vuex-class'
    import InvoiceCreator from "../InvoiceCreator.vue";
    import {IPayment, IPostPayment, PaymentActions, PaymentType} from "@/store/Payments";
    import {Prop} from "vue-property-decorator";
    import {DialogActions, IDialog} from "@/store/dialog";
    import {IAppointmentDTO} from "@/types/AutoGenerated/reinforcedTypings";

    @Component({
        name: "PaymentCreator",
        components: {InvoiceCreator}
    })

    export default class PaymentCreator extends Vue {

        newPaymentAmount: number = 0;
        showAmount: boolean = false;
        markAsPaid: boolean = false;
        choosingAppointment: boolean = false;

        public chosenAppointmentId: number = null;
        toggle_exclusive = "";
        private newPaymentType: PaymentType;
        public newInvoice:boolean = false;
        public appointmentToPay:IAppointmentDTO = null;
        public canEditAppointment:boolean = true;

        @Prop({default: null}) defaultValue: number;
        @Prop({default: null}) appointment: IAppointmentDTO;
        @Prop({default: null}) customerId: number;
        @Action public postSnackbarMessage:(message: string) => void;
        @Action(DialogActions.AddToDialogQueue) addToDialogQueue:(dialog: IDialog) => void;
        @Action(PaymentActions.POST) postPayment:(payment: IPostPayment) => Promise<Array<IPayment>>;

        created() {
            this.newPaymentAmount = this.defaultValue;
            if(this.appointment != null){
                this.canEditAppointment = false;
                this.appointmentToPay = this.appointment;
                this.chosenAppointmentId = this.appointment.number;
            }
        }


        selectNewInvoice(){
            this.toggle_exclusive = "";
            this.newInvoice = true;
            this.showAmount = false;
        }

        selectCard(){
            this.showAmount = true
            this.newPaymentType = PaymentType.Card
        }

        selectCash(){
            this.showAmount = true
            this.newPaymentType = PaymentType.Cash
        }

        async addPayment(){
            let appointmentId = -1;
            if(this.appointmentToPay != null){
                appointmentId = this.appointmentToPay.number;
            }
            if(this.chosenAppointmentId != null){
                appointmentId = this.chosenAppointmentId;
            }

            if(isNaN(this.newPaymentAmount)){
                let dialog : IDialog = {
                    text: "Feil beløp"
                }
                this.addToDialogQueue(dialog);
                return;
            }
            let newPayment: IPostPayment = {
                amount: this.newPaymentAmount,
                type: this.newPaymentType,
                markAsPaid: this.markAsPaid,
                appointmentNumber: appointmentId
            }
            await this.postPayment(newPayment);
            this.showAmount = false;
            this.$emit("finished");
            this.postSnackbarMessage("Betaling opprettet!");
        }

        onFinished(){
            this.newInvoice = false;
            this.$emit("invoice");
            this.$emit("finished");
        }

        onChosenAppointment(a){
            this.appointmentToPay = a;
            //@ts-ignore
            this.newPaymentAmount = this.appointmentToPay.appointmentTypePrice;
            this.chosenAppointmentId = this.appointmentToPay.number;
            this.choosingAppointment = false;
        }

        beforeCreate() {
            this.$options.components.AppointmentList = require('../../pages/admin/AppointmentList.vue').default
        }

        onInvoiceClose(){
            this.newInvoice = false;
        }
    }

