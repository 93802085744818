
import { Component, Vue } from 'vue-property-decorator';
import { Action, Getter } from 'vuex-class';
import {Inotifications} from "@/store/notifications";


@Component({
    name: "notification-dropdown",
    components: {}
})
export default class NotificationDropdown extends Vue {
    //Vuex
    @Action public getNotifications:Function;
    @Action public markNotificationAsRead:Function;
    @Getter public notifications: Inotifications;


    public markAsRead():void{
        this.markNotificationAsRead();
    }

    public created():void{
        this.getNotifications().then(res => {
        })
    }
}
