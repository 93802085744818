

    import Vue from "vue";
    import {Component, Prop} from "vue-property-decorator";
    import CustomButton from "@/components/global/styledComponents/customButton.vue";
    import {IDialog} from "../../store/dialog";

    //* Component definition ************************************************************

    @Component({
        name: "Dialog",
        components: {CustomButton}
    })

//* Class ***************************************************************************

    export default class Dialog extends Vue {

        @Prop({}) dialog: IDialog;

        private buttonEnabled: boolean = false;

        mounted(){
            let vm = this;
            if(this.dialog.actionButtonDelay != 0){

                this.buttonEnabled = false;

                setTimeout( function(){
                    vm.buttonEnabled = true;
                }, vm.dialog.actionButtonDelay * 1000);
            } else {
                this.buttonEnabled = true;
            }
        }

        closeNormalDialog(){
            this.$store.commit('closeNormalDialog', true);
        }
        closeNormalDialogCancel(){
            this.$store.commit('closeNormalDialog', false);
        }

        get isPng(){
            return this.dialog.icon.includes(".png");
        }

    }

