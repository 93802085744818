
// @ts-nocheck

    import Vue from 'vue'
    import Component from 'vue-class-component'
    import {
        Getter,
        Action,
        Mutation,
    } from 'vuex-class'
    import {Prop} from "vue-property-decorator";
    import {IInvoice, InvoiceActions, InvoiceMutations} from "../store/Invoices";
    import {IUser} from "../store/user";
    import {IInsurance, InsuranceActions} from "../store/Insurances";
    import Loader from "./global/Loader.vue";
    import {IAppointmentInfoDTO} from "../types/AutoGenerated/reinforcedTypings";
    import {IDialog} from "../store/dialog";

    @Component({
        name: "InvoiceCreator",
        components: {Loader}
    })

    export default class InvoiceCreator extends Vue {


        @Getter public user : IUser;
        @Getter public invoices : Array<IInvoice>;
        @Action public addToDialogQueue:Function;
        @Action public postSnackbarMessage: Function;

        @Action(InvoiceActions.GetInvoices) public getInvoices:() => Promise<Array<IInvoice>>;
        @Action(InvoiceActions.GetCurrentInvoiceNumber) public getInvoiceNumber:() => Promise<number>;
        @Action(InvoiceActions.GetPdf) public getPdf:(invoiceId: number) => Promise<number>;
        @Action(InvoiceActions.ChangeStatus) public changeStatus:(changeStatusPayload) => Promise<number>;
        @Action(InvoiceActions.SendInvoiceToCustomer) public sendInvoiceToCustomer:(payload) => Promise<number>;


        @Action(InsuranceActions.GET_BY_ID) getInsuranceById:(id: number) => Promise<IInsurance>;

        @Action public getInvoices:Function;
        @Action public postInvoice:Function;

        @Mutation(InvoiceMutations.SetSendingInvoice) public setSendingInvoice:(payload: IInvoice) => void;

        @Prop({default: null}) appointment;
        @Prop({default: false}) creatingNew;
        @Prop({default: false}) showDialog;
        @Prop({default: false}) defaultAmount;

        public postalCodeRules = [
            v => !!v || 'Postkode er påkrevd',
        ];

        public cityRules = [
            v => !!v || 'By er påkrevd',
        ];

        public addressRules = [
            v => !!v || 'Adresse er påkrevd',
        ];

        public amountRules = [
            v => !!v || 'Antall er påkrevd',
            v => v >= 0 || 'Antall må ha en positiv verdi'
        ];

        markAsPaid: boolean = true;
        invoiceValid: boolean = true;
        invoiceForm: boolean = true;
        newInvoice: boolean = false;
        isEditing: boolean = true;
        creatingInvoice: {} = {};
        chosenAppointment: IAppointmentInfoDTO = null;
        newInvoiceTitle: string = "";
        price: number = 0;
        discount: number = 0;
        amount: number = 1;
        vat: number = 25;
        invoiceNumber: number = 0;

        public priceRules = [
            v => !!v || 'Beløp er påkrevd',
            v => v >= 0 || 'Beløp på ha en positiv verdi'
        ];

        get discountRules() {
            return [
                 this.discount <= (this.price * this.amount) || 'Rabatt kan ikke være høyere enn pris',
            ];
        }

        get typedUser() : IUser {
            return this.user;
        }


        closeNewInvoice(){
            this.chosenAppointment = null;
            this.$emit("close");
        }
        openNewInvoice(){
            this.newInvoice = true;
        }



        get totalSum(){
            return (((this.price * this.amount) - this.discount) * (1+(this.vat / 100)));
        }

        async onChosenAppointment(appointment : IAppointmentInfoDTO){
            this.invoiceNumber = await this.getInvoiceNumber();
            this.chosenAppointment = appointment;
            this.price = this.chosenAppointment.appointmentTypePrice;

            if(this.chosenAppointment.customerInsuranceId != null && this.chosenAppointment.customerInsuranceId != 0 && this.chosenAppointment.customerInsuranceId != -1){
                let res : Partial<IInsurance> = await this.getInsuranceById(this.chosenAppointment.customerInsuranceId);
                this.chosenAppointment.customerEmail = res.emailAddress;
                this.chosenAppointment.customerCity = res.city;
                this.chosenAppointment.customerPostalCode = res.postalCode;
                this.chosenAppointment.customerAddress = res.address;
                this.chosenAppointment.customerName = res.name;
            }
        }

        async addInvoice(send: boolean){


            const invoiceForm : any = this.$refs.invoiceForm;

            if(!invoiceForm.validate()){
                return;
            }

            if(this.user.organizationNumber == 0){
                let dialog: IDialog = {
                    text:"Vennligst fyll ut ditt organisasjonsummer i Instillinger."
                };

                this.addToDialogQueue(dialog);
                return;
            }

            if(this.user.bankAccount == 0){
                let dialog: IDialog = {
                    text:"Vennligst fyll ut ditt kontonummer i Instillinger."
                };

                this.addToDialogQueue(dialog);
                return;
            }

            const price : string = this.price as string;
            const vat : string = this.vat as string;

            let newInvoice : IInvoice = {
                id: 0,
                invoiceNumber: 0,
                senderName: "",
                customerName: this.chosenAppointment.customerName,
                customerAddress: this.chosenAppointment.customerAddress,
                customerEmailAddress: this.chosenAppointment.customerEmail,
                customerPhoneNumber: this.chosenAppointment.customerPhone,
                customerPostalCode: this.chosenAppointment.customerPostalCode,
                customerCity: this.chosenAppointment.customerCity,
                customerCountry: "Norge",
                dueDays: 30, //Obsolete
                appointmentId: this.chosenAppointment.id,
                appointmentServicePrice: parseFloat(price),
                chargeAmount: parseFloat(this.totalSum),
                customerAccountId: 0,
                customerId: this.chosenAppointment.customerId,
                bankAccount: this.user.bankAccount,
                organizationNumber: this.user.organizationNumber,
                service: this.chosenAppointment.appointmentName,
                companyName: this.user.companyName,
                companyEmail: this.user.emailAddress,
                companyPhone: this.user.phoneNumber,
                serviceQuantity: this.amount,
                dischargeAmount: this.discount,
                vat: parseFloat(vat),
                status: "Ny",
                appointmentNumber: this.chosenAppointment.number,
                markAsPaid: this.markAsPaid
            };

            try{
                let res = await this.postInvoice(newInvoice);
                this.postSnackbarMessage("Faktura opprettet!");
                if(send) {
                    this.setSendingInvoice(res);
                }

                this.$emit("finished");

            } catch(e){
                this.postSnackbarMessage("Kunne ikke opprette faktura");
            }

            this.closeNewInvoice();
        }

        beforeCreate() {
            this.$options.components.AppointmentList = require('../pages/admin/AppointmentList.vue').default
        }

        formatNumber(v){
            let value = (v).toLocaleString('no-NO', {
                style: 'currency',
                currency: 'NOK',
            }); /* $2,500.00 */
            value = value.replace(",", " ");
            value = value.replace(".", ",");

            return value;
        }

        formatBankAccount(s){
            if(s == null || s.length == 0 || s == 0){
                return "MANGLER BANKKONTO"
            }
            let v = s.toString().split("");
            v.splice(4, 0, " ");
            v.splice(7, 0, " ");
            return v.join("");
        }

        async created() {
            this.vat = this.user.taxPercentage;
            if(this.vat == null){
                this.vat = 0;
            }

            if(this.defaultAmount != null){
                this.price = this.defaultAmount;
            }
            if(this.appointment != null){

                this.chosenAppointment = this.appointment;
                this.invoiceNumber = await this.getInvoiceNumber();
                this.price = this.chosenAppointment.appointmentTypePrice;

                if(this.chosenAppointment.customerInsuranceId != null && this.chosenAppointment.customerInsuranceId != 0 && this.chosenAppointment.customerInsuranceId != -1){
                    let res : Partial<IInsurance> = await this.getInsuranceById(this.chosenAppointment.customerInsuranceId);
                    this.chosenAppointment.customerEmail = res.emailAddress;
                    this.chosenAppointment.customerCity = res.city;
                    this.chosenAppointment.customerPostalCode = res.postalCode;
                    this.chosenAppointment.customerAddress = res.address;
                    this.chosenAppointment.customerName = res.name;
                }

            } else {
                this.chosenAppointment = null;


            }


            this.newInvoiceTitle = "Opprett faktura";

            this.amount = 1;

            this.vat = this.user.taxPercentage;
            if(this.vat == null){
                this.vat = 0;
            }

            this.newInvoiceTitle = "Ny faktura";
            this.isEditing = true;
        }
    }

