import {Action, Getter} from "vuex-class";




// @ts-ignore
import { GetterTree, MutationTree, ActionTree } from 'vuex';
// @ts-ignore
import axios, {AxiosRequestConfig} from 'axios';
import { APIURL2 } from '@/main';
import { actionStringSnackbar } from './snackbar';
import authConfig, {AuthConfig} from "@/Utilities/authConfig";


export interface IEmailListState {
    emailList: IEmailListEntry[],
}

export interface IEmailListEntry {
    email: string,
    name: string,
    signupDate: Date
}

export const state : IEmailListState = {
    emailList: [],
};



export const getters: GetterTree<IEmailListState, IEmailListState> = {
    emailList:state => state.emailList,
};

export const mutations: MutationTree<IEmailListState> = {
    setEmailList(state, payload:IEmailListEntry[]) { state.emailList = payload; },
};


export const actions: ActionTree<IEmailListState, any> = {


async getEmailList({commit, dispatch}):Promise<any> {
        let header : AxiosRequestConfig = await authConfig();
        return new Promise((resolve, reject) => {
            axios.get(APIURL2 + 'EmailList/current',
                header).then((res) => {
                commit("setEmailList", res.data);
                resolve(res);
            }).catch((err) => {
                console.log(err);
                dispatch(actionStringSnackbar.postSnackbarMessage, err.message );
                reject(err);
            });
        });
    },
};

export const emailList = {
    actions,
    mutations,
    getters,
    state
};
