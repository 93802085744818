
// @ts-ignore
import axios, {AxiosRequestConfig} from 'axios';
// @ts-ignore
import { GetterTree, MutationTree, ActionTree } from 'vuex';
import {APIURL2} from '@/main';
import authConfig, {AuthConfig} from "@/Utilities/authConfig";
import {actionStringSnackbar} from "@/store/snackbar";
import {getCustomers} from "@/Utilities/endpoints";
import {createPatchObject} from "../Utilities/ObjectUtility";
import {IAppointmentPatch} from "./appointments/appointments";
import {IAuditLogDTO} from "@/types/AutoGenerated/reinforcedTypings";
import firebase from "firebase";
import User = firebase.User;

// Interface
export interface EventState {
    userEvents: Array<IEvent>
    debugEvents: Array<IEvent>
    unseenEvents: number,
    firebaseUsers: Array<any>,
    firebaseUserAccount: {}|null,
    auditLog: Array<IAuditLogDTO>,
    latestUpdate: {}|null
}

export enum EventMutations{
    SetUserEvents = "setUserEvents",
    SetUnseenEvents = "setUnseenEvents",
}

// Interface Model
export interface IEvent {
    id: number;
    message: string;
    time: Date;
    hasSeen: boolean;
    eventId: number;
}


export const state : EventState = {
    userEvents: [],
    unseenEvents: 0,
    debugEvents: [],
    firebaseUsers: [],
    firebaseUserAccount: null,
    auditLog: [],
    latestUpdate: null
};

export const getters: GetterTree<EventState, any> = {
    userEvents: (state) => state.userEvents,
    unseenEvents: (state) => state.unseenEvents,
    debugEvents: (state) => state.debugEvents,
    firebaseUsers: (state) => state.firebaseUsers,
    firebaseUserAccount: (state) => state.firebaseUserAccount,
    auditLog: (state) => state.auditLog,
    latestUpdate: (state) => state.latestUpdate,

};

export const mutations: MutationTree<EventState> = {
    setUserEvents(state : EventState, payload){
        state.userEvents = payload;
    },
    setDebugEvents(state : EventState, payload){
        state.userEvents = payload;
    },
    setUnseenEvents(state : EventState, payload : number){
        state.unseenEvents = payload;
    },
    setFirebaseUsers(state : EventState, payload : Array<User>){
        state.firebaseUsers = payload;
    },
    setFirebaseUserAccount(state : EventState, payload : Array<User>){
        state.firebaseUserAccount = payload;
    },

    setAuditLog(state : EventState, payload : IAuditLogDTO[]){
        state.auditLog = payload;
    },
    setLatestUpdate(state : EventState, payload : Array<User>){
        state.latestUpdate = payload;
    },



};

export const actions: ActionTree<EventState, any> = {



    async patchFirebaseUser({dispatch}, payload : any):Promise<any>{

        let patchPayload = createPatchObject(payload.patch);

        let header : AxiosRequestConfig = await authConfig();

        return new Promise((resolve, reject) => {
            try{
                axios.patch(APIURL2 + "FirebaseUser/current?firebaseUserId=" + payload.firebaseUserId, patchPayload, header).then((response) => {
                    return resolve(response);
                })
            }catch(e){
                reject(e);
            }
        });

    },

    async addFirebaseUser({commit, dispatch}, payload): Promise<any> {
        let header: AxiosRequestConfig= await authConfig();
        return new Promise((resolve, reject) => {
            axios.post(APIURL2 + 'FirebaseUser/current', payload, header).then((res) => {
                dispatch("getFirebaseUsersAsAdmin");
                resolve(res.data);
            }).catch((err) => {
                console.log(err);
                reject(err);
            });
        });
    },
    async getFirebaseUserAccount({commit}): Promise<any> {
        let header: AxiosRequestConfig= await authConfig();
        return new Promise((resolve, reject) => {
            axios.get(APIURL2 + 'FirebaseUser/current', header).then((res) => {
                commit("setFirebaseUserAccount", res.data);
                resolve(res.data);
            }).catch((err) => {
                console.log(err);
                reject(err);
            });
        });
    },
    async getFirebaseUsersAsAdmin({commit}): Promise<any> {
        let header: AxiosRequestConfig= await authConfig();
        return new Promise((resolve, reject) => {
            axios.get(APIURL2 + 'Admin/users/admin', header).then((res) => {
                commit("setFirebaseUsers", res.data);
                resolve(res.data);
            }).catch((err) => {
                console.log(err);
                reject(err);
            });
        });
    },

    async postAuditLog({commit}, payload): Promise<any> {
        let header: AxiosRequestConfig= await authConfig();

        console.log("Payload: ", payload);
        return new Promise((resolve, reject) => {
            axios.post(APIURL2 + 'AuditLog/current', payload, header).then((res) => {
                resolve(res.data);
            }).catch((err) => {
                console.log(err);
                reject(err);
            });
        });
    },
    async getLatestUpdate({commit}): Promise<any> {
        let header: AxiosRequestConfig= await authConfig();

        return new Promise((resolve, reject) => {
            axios.get(APIURL2 + 'UpdateLog/current' , header).then((res) => {
                commit("setLatestUpdate", res.data);
                resolve(res.data);
            }).catch((err) => {
                console.log(err);
                reject(err);
            });
        });
    },
    async getAuditLog({commit}, payload): Promise<any> {
        let header: AxiosRequestConfig= await authConfig();

        let q = "";

        if(payload != null){
            q += "?ignoreFlag=" + payload;
        }
        return new Promise((resolve, reject) => {
            axios.get(APIURL2 + 'AuditLog/current' + q, header).then((res) => {
                commit("setAuditLog", res.data);
                resolve(res.data);
            }).catch((err) => {
                console.log(err);
                reject(err);
            });
        });
    },
    async getDebugEvents({commit}): Promise<any> {
        let header: AxiosRequestConfig= await authConfig();
        return new Promise((resolve, reject) => {
            axios.get(APIURL2 + 'Debuglog/current', header).then((res) => {
                commit("setDebugEvents", res.data);
                resolve(res.data);
            }).catch((err) => {
                console.log(err);
                reject(err);
            });
        });
    },
    async getUserEvents({commit}): Promise<any> {
        let header: AxiosRequestConfig= await authConfig();
        return new Promise((resolve, reject) => {
            axios.get(APIURL2 + 'Event/current', header).then((res) => {
                commit(EventMutations.SetUserEvents, res.data);
                resolve(res.data);
            }).catch((err) => {
                console.log(err);
                reject(err);
            });
        });
    },
    async getUnseenEvents({commit}): Promise<any> {
        let header: AxiosRequestConfig= await authConfig();
        return new Promise((resolve, reject) => {
            axios.get(APIURL2 + 'Event/unseen/current', header).then((res) => {
                commit(EventMutations.SetUnseenEvents, res.data);
                resolve(res.data);
            }).catch((err) => {
                console.log(err);
                reject(err);
            });
        });
    },
    async markEventsAsRead({commit}): Promise<any> {
        let header: AxiosRequestConfig= await authConfig();
        return new Promise((resolve, reject) => {
            axios.post(APIURL2 + 'Event/markAsRead/current', {}, header).then((res) => {
                commit(EventMutations.SetUnseenEvents, 0);
                resolve(res.data);
            }).catch((err) => {
                console.log(err);
                reject(err);
            });
        });
    },

};

export const events = {
    actions,
    mutations,
    getters,
    state,
};
