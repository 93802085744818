
// @ts-ignore
import { GetterTree, MutationTree, ActionTree } from 'vuex';
// @ts-ignore
import axios, {AxiosRequestConfig} from "axios";
import {APIURL2} from "@/main";
import authConfig, {AuthConfig} from "@/Utilities/authConfig";



// Interface
export interface CompanyState {
    company: ICompany | null;
}

// Interface Model
export interface ICompany {
    companyName:string;
    treatmentAdress:string,
    subscriptionType:string
    appointmentDistributionInMinutes:number
}

// Mutation enums
export const enum mutationStringCompany {
    setCompany = 'setCompany',
}

//Action enums
export const enum actionStringCompany{
    getCompany = 'getCompany',
}

export const state:CompanyState = {
    company: null,
};

export const getters: GetterTree<any, CompanyState> = {
    company:state => state.company
};

export const mutations: MutationTree<CompanyState> = {
    setCompany(state, payload:ICompany) {
        state.company = payload;
    },

};

export const actions: ActionTree<CompanyState, any> = {
    async postCompany({commit}, payload): Promise<any> {
        let header : AxiosRequestConfig = await authConfig();
        axios.post(APIURL2 + 'Company/CreateAndJoin/current', {
             companyName:payload.companyName,
             treatmentAdress:payload.treatmentAdress,
             subscriptionType:payload.subscriptionType,
             appointmentDistributionInMinutes:payload.appointmentDistributionInMinutes
            },
           header).then((res) => {

            commit(mutationStringCompany.setCompany, res.data);
        }).catch((err) => {
            console.log(err);
        });
    },

    async getCompany({commit}){
        let header : AxiosRequestConfig = await authConfig();

        return new Promise((resolve, reject ) => {
        axios.get(APIURL2 + 'Company/current',
            header).then((res) => {
            commit(mutationStringCompany.setCompany, res.data);
            resolve(res);
        }).catch((err) => {
            console.log(err);
            reject(err);
        });
        });
    },


    async getCompanyAsCustomer({commit}, companyId){
        let header : AxiosRequestConfig = await authConfig();

        return new Promise((resolve, reject ) => {
        axios.get(APIURL2 + 'Company/' + companyId,
            header).then((res) => {
            commit(mutationStringCompany.setCompany, res.data);
            resolve(res);
        }).catch((err) => {
            console.log(err);
            reject(err);
        });
        });
    },



    async updateCompanyAppointmentDistribution({commit, dispatch}, payload:ICompany): Promise<any> {
        let header : AxiosRequestConfig = await authConfig();
        axios.put(APIURL2 + 'Company/currentCompany',
            {
                companyName:payload.companyName,
                treatmentAdress:payload.treatmentAdress,
                subscriptionType:payload.subscriptionType,
                appointmentDistributionInMinutes:payload.appointmentDistributionInMinutes
            },
            header).then(() => {
            dispatch('getCompany')

            // commit(mutationStringCompany.setCompany, res.data);
        }).catch((err) => {
            console.log(err);
        });
    },

};

export const company = {
    actions,
    mutations,
    getters,
    state,
};
