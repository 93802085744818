
// @ts-ignore
import axios, {AxiosRequestConfig} from 'axios';
// @ts-ignore
import { GetterTree, MutationTree, ActionTree } from 'vuex';
import {APIURL2} from '@/main';
import authConfig, {AuthConfig} from "@/Utilities/authConfig";
import {actionStringSnackbar} from "@/store/snackbar";
import {getCustomers} from "@/Utilities/endpoints";
import {mutationStringSnackbar} from "./snackbar";

// Interface
export interface PaymentsState {
    payments: Array<IPayment>
}

export enum PaymentMutations{
    setPayments = "setPayments",
}

export enum PaymentActions{
    GET = "getPayments",
    GET_FOR_APPOINTMENT = "getPaymentsForAppointment",
    GET_FOR_CUSTOMER = "getPaymentsForCustomer",
    POST = "postPayment",
}

export enum PaymentType{
    Cash = 0,
    Card = 1,
    Invoice = 2,
    Vipps = 3,
    Giftcard = 4,
}

// Interface Model
export interface IPayment {
    id: number;
    userId: string;
    customerName: string;
    customerId: number;
    invoiceNumber: number;
    appointmentId: number;
    amount: number;
    date: Date;
    type: PaymentType;
}

export interface IPostPayment {
    appointmentNumber: number;
    amount: number;
    markAsPaid: boolean;
    type: PaymentType;
}

export const state : PaymentsState = {
    payments: [],

};

export const getters: GetterTree<PaymentsState, any> = {
    payments: (state) => state.payments,
};

export const mutations: MutationTree<PaymentsState> = {
    setPayments(state : PaymentsState, payload : Array<IPayment>){
        state.payments = payload;
    },
};

export const actions: ActionTree<PaymentsState, any> = {

    async getPayments({commit}): Promise<any> {
        let header: AxiosRequestConfig= await authConfig();

        return new Promise((resolve, reject) => {
            axios.get(APIURL2 + 'Payment/current', header).then((res) => {
                resolve(res.data);
                commit(PaymentMutations.setPayments, res.data);
            }, (error) => {
                console.error(error);
                commit(mutationStringSnackbar.setSnackbar, "Kunne hente betalinger.")
            }).catch((err) => {
                console.log(err);
                reject(err);
            });
        });
    },

    async getPaymentsForAppointment({commit}, appointmentId: number): Promise<any> {
        let header: AxiosRequestConfig= await authConfig();

        return new Promise((resolve, reject) => {
            axios.get(APIURL2 + 'Payment/forAppointment/current?appointmentId=' + appointmentId, header).then((res) => {
                resolve(res.data);
                commit(PaymentMutations.setPayments, res.data);
            }, (error) => {
                console.error(error);
                commit(mutationStringSnackbar.setSnackbar, "Kunne hente betalinger for bestilling.")
            }).catch((err) => {
                console.log(err);
                reject(err);
            });
        });
    },
    async getPaymentsForCustomer({commit}, customerId: number): Promise<any> {
        let header: AxiosRequestConfig= await authConfig();

        return new Promise((resolve, reject) => {
            axios.get(APIURL2 + 'Payment/forCustomer/current?customerId=' + customerId, header).then((res) => {
                resolve(res.data);
                commit(PaymentMutations.setPayments, res.data);
            }, (error) => {
                console.error(error);
                commit(mutationStringSnackbar.setSnackbar, "Kunne hente betalinger for kunde.")
            }).catch((err) => {
                console.log(err);
                reject(err);
            });
        });
    },

    async postPayment({commit, dispatch}, dto: IPostPayment): Promise<any> {
        let header: AxiosRequestConfig= await authConfig();
        return new Promise((resolve) => {
            axios.post(APIURL2 + 'Payment/current', dto, header).then((res) => {
                dispatch(PaymentActions.GET);
                resolve(res.data);
            }, ()=> {
                commit(mutationStringSnackbar.setSnackbar, "Kunne ikke opprette betaling")
            });
        });
    },

};

export const payments = {
    actions,
    mutations,
    getters,
    state,
};
