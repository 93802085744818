import { render, staticRenderFns } from "./PaymentsOverview.vue?vue&type=template&id=f8109052&scoped=true&"
import script from "./PaymentsOverview.vue?vue&type=script&lang=ts&xmlns%3Av-slot=http%3A%2F%2Fwww.w3.org%2F1999%2FXSL%2FTransform&"
export * from "./PaymentsOverview.vue?vue&type=script&lang=ts&xmlns%3Av-slot=http%3A%2F%2Fwww.w3.org%2F1999%2FXSL%2FTransform&"
import style0 from "./PaymentsOverview.vue?vue&type=style&index=0&id=f8109052&prod&lang=scss&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "f8109052",
  null
  
)

export default component.exports