
    import {Component, Prop, Vue} from 'vue-property-decorator';
    import {ISubscriptionForm} from '@/store/checkout';
    import CustomButton from "@/components/global/styledComponents/customButton.vue";
    import Loader from "@/components/global/Loader.vue";

    @Component({
    name:'stripe-payment',
    components:{Loader, CustomButton}
})
export default class StripePayment extends Vue {

        @Prop({default:null}) description:string | null;
        @Prop({default:null}) amount:string | null;
        public currency:String = 'nok';
        public name:String =  'Availify';
        public image:String = '/img/default-logo.png';
    public token:object | null = null;

    async mounted () {
        // token - is the token object
        // args - is an object containing the billing and shipping address if enabled
        setTimeout(async (x) => {
          let checkout : any = this.$refs.checkoutRef;

            const { token, args } = await checkout.open({
                token: (token) => {
                    this.token = token;
                    this.$emit("recievepaymenttoken", token);
                }
            });

            const subscriptionFormData:ISubscriptionForm = {stripeEmail:token.email, stripeToken: token.id};
            this.$emit("recieveformdata", subscriptionFormData);
        }, 1000);

    }



    done ({token, args}) {
    }
    opened () { }
    closed () {
        this.$emit("closed");
    }
    canceled () {
        this.$emit("closed");
    }

}
