import {formatDate, FormatSpaceType, formatTime} from "../types/formatDateHelper";

export function getCurrentMonth(){
    let now = new Date();
    now.setMonth(now.getMonth() +1);
    return now.getMonth();
}

export function getMonthName(d, short = false){
let  months = ['Januar', 'Februar', 'Mars', 'April', 'Mai', 'Juni', 'Juli', 'August', 'September', 'Oktober', 'November', 'Desember'];

if(short && months[d] != null){
    return months[d].substring(0,3);
}
return months[d];
}

export function getWeekDay(d){
    let  days = ['Søn', 'Man', 'Tir', 'Ons', 'Tor', 'Fre', 'Lør'];
    return days[d];
}

export function getWeekNumber(d) {
    // Copy date so don't modify original
    d = new Date(Date.UTC(d.getFullYear(), d.getMonth(), d.getDate()));
    // Set to nearest Thursday: current date + 4 - current day number
    // Make Sunday's day number 7
    d.setUTCDate(d.getUTCDate() + 4 - (d.getUTCDay()||7));
    // Get first day of year
    const yearStart : any = new Date(Date.UTC(d.getUTCFullYear(),0,1));
    // Calculate full weeks to nearest Thursday
    const weekNo = Math.ceil(( ( (d - yearStart) / 86400000) + 1)/7);
    // Return array of year and week number
    return weekNo;
}

export function allHoursDividedByQuartersWithinOpeningTimes(startHour, startMinute, endHour, endMinute){
    return allHoursDividedByQuartersOfStart(startHour, 0, endHour); //Starts 10:00, or 08:00, whatever they start
}


export function allHoursDividedByQuarters(){

    return allHoursDividedByQuartersOfStart(6, 0, 24); //Starts 06:00

}

export function allHoursDividedByQuartersOfStart(startHour, startMinute, endHour){

    const x = 15; //minutes interval
    const times = []; // time array
    let tt = startHour*60; // start time
    tt += startMinute;

    const ap = ['AM', 'PM']; // AM-PM

//loop to increment the time and push results in array
    for (let i=0;tt<=endHour*60; i++) {
        let hh = Math.floor(tt/60) // getting hours of day in 0-24 format
        let hhString = hh.toString() // getting hours of day in 0-24 format
        let mm = (tt%60); // getting minutes of the hour in 0-55 format
        let mmString = mm.toString(); // getting minutes of the hour in 0-55 format
        if(hh < 10) hhString = "0" + hhString;
        if(mm < 10) mmString = "0" + mmString;
        times[i] = (hhString + ':' +mmString); // pushing data in array in [00:00 - 12:00 AM/PM format]
        tt = tt + x;
    }
    return times;
}


export function isValidDate(d) {
    const dAsNumber : number = d as number;
    return d instanceof Date && !isNaN(dAsNumber);
}

export function formatOnlyHour(date: Date){
    if(date == null){
        return "";
    }

    let toDateObject = new Date(date as unknown as string);
    return formatTime(toDateObject.getHours(), toDateObject.getMinutes());
}
export function formatDateStringHour(date: Date){
    if(date == null){
        return "";
    }

    let toDateObject = new Date(date as unknown as string);
    return formatDate(toDateObject, FormatSpaceType.DOT) + " " + formatTime(toDateObject.getHours(), toDateObject.getMinutes());
}

export function formatDateString(date: Date){
    if(date == null){
        return "";
    }

    let toDateObject = new Date(date as unknown as string);
    return formatDate(toDateObject, FormatSpaceType.DOT)
}
