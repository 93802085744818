

    import Vue from 'vue'
    import Component from 'vue-class-component'
    import {
        State,
        Getter,
        Action,
        Mutation,
        namespace
    } from 'vuex-class'
    import ToolTip from "../../../ToolTip.vue";
    import {IAuditLogDTO, IUserDTO} from "../../../../types/AutoGenerated/reinforcedTypings";
    import {IUser} from "../../../../store/user";

    @Component({
        name: "BookingPageGeneralSettings",
        components: {ToolTip}
    })

    export default class BookingPageGeneralSettings extends Vue {

      @Action public postSnackbarMessage: Function;
      @Action public postAuditLog: Function;


      currentAdminInfo:Partial<IUserDTO> = {
        bookingLink: '',
        connectedBookingLink: '',
        bookingLocationName: ''
      };



      @Getter user : IUserDTO;
        @Action patchUser: Function;

      setCurrentUser():void{
        this.currentAdminInfo.bookingLink = this.user.bookingLink;
        this.currentAdminInfo.connectedBookingLink = this.user.connectedBookingLink;
        this.currentAdminInfo.bookingLocationName = this.user.bookingLocationName;

      }

        created() {
            this.setCurrentUser();
        }
        async saveChanges(){

          if(this.currentAdminInfo.bookingLink.length < 3){
            this.postSnackbarMessage("Bookinglenken må inneholde minst 3 tegn");
            return false;
          }

          if(this.currentAdminInfo.bookingLink != this.user.bookingLink){
            let audit: Partial<IAuditLogDTO> = {action: 1, type: 2, comment: "", message: " endret innstilling 'Booking-ID' til " + this.currentAdminInfo.bookingLink, hideForUser: false,};
            this.postAuditLog(audit);
          }
          if(this.currentAdminInfo.connectedBookingLink != this.user.connectedBookingLink){
            let audit: Partial<IAuditLogDTO> = {action: 1, type: 2, comment: "", message: " endret innstilling 'Felles-ID' til " + this.currentAdminInfo.connectedBookingLink, hideForUser: false,};
            this.postAuditLog(audit);
          }
          if(this.currentAdminInfo.bookingLocationName != this.user.connectedBookingLink){
            let audit: Partial<IAuditLogDTO> = {action: 1, type: 2, comment: "", message: " endret innstilling 'Lokasjon navn' til " + this.currentAdminInfo.bookingLocationName, hideForUser: false,};
            this.postAuditLog(audit);
          }

            let update: Partial<IUserDTO> = {
              bookingLink: this.currentAdminInfo.bookingLink,
              connectedBookingLink: this.currentAdminInfo.connectedBookingLink,
              bookingLocationName: this.currentAdminInfo.bookingLocationName
            };

            this.patchUser(update);
        }

        booleanToOnOff(value: boolean): string {
            if (value) {
                return "På"
            } else {
                return "Av"
            }
        }


      get isOldDomain(){
        return window.location.toString().includes("vergba");
      }
    }

