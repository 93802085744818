

    import Vue from 'vue'
    import Component from 'vue-class-component'
    import {
        Getter,
        Action,
    } from 'vuex-class'
    import {IUser} from "../../../../store/user";
    import {Prop, Watch} from "vue-property-decorator";
    import {IStaffMemberDTO} from "../../../../types/AutoGenerated/reinforcedTypings";

    @Component({
        name: "VacationDays",
        components: {}
    })

    export default class VacationDays extends Vue {


        @Prop({default: null}) staffMember : IStaffMemberDTO;

      @Action public getVacationDays: Function;
      @Action public getAllWorkingDays: Function;
      @Getter public allWorkingDays;

        @Getter public user: IUser;
        @Getter public vacationDays;

        selectedVacationDays = [];
        @Action public addVacationDays: Function;
        @Action public removeVacationDays: Function;


        @Watch("staffMember")
        async onStaffMemberChange(){
            //If the staff member is changed without destorying the component
            this.getVacationDays({staffMemberId: this.staffMemberId});
          await this.getAllWorkingDays({staffMemberId: this.staffMemberId});
        }

        public async created(): Promise<void> {
            this.getVacationDays({staffMemberId: this.staffMemberId});
          await this.getAllWorkingDays({staffMemberId: this.staffMemberId});
        }


        removeCurrentSelectedDateToArrayEvents(){

          const commaSepertedString = this.selectedVacationDays.join();

            this.removeVacationDays({vacationDays: commaSepertedString, staffMemberId: this.staffMemberId});
        }

        get staffMemberId(){
            let staffMemberId = 0;
            if(this.staffMember != null){
                staffMemberId = this.staffMember.id;
            }
            return staffMemberId;
        }

       minTwoDigits(n) {
        return (n < 10 ? '0' : '') + n;
      }
        get vacationDaysEvents(){
          console.log("Vacation days: ", this.vacationDays);
          let mappedDays = this.vacationDays.map((x) => {
                return x.year + "-" + this.minTwoDigits(x.month) + "-" + this.minTwoDigits(x.day);
            })
          return mappedDays;
        }

        addCurrentSelectedDateToArrayEvents(){
          // const days = this.selectedVacationDays.map((x) => {
          //   let d = new Date(x);
          //   return {year: d.getFullYear(), month: d.getMonth()+1, day: d.getDate()}
          // })
          const commaSepertedString = this.selectedVacationDays.join();

          this.addVacationDays({vacationDays: commaSepertedString, staffMemberId: this.staffMemberId});

        }

         disableNonWorkingDays(selectedDay){
          let date = new Date(selectedDay);
          let weekDay = date.getDay(); //0 is sunday
           const isWorkingToday = this.allWorkingDays[weekDay];
           return isWorkingToday;
        }


    }

