
    import {Component, Prop, Vue} from 'vue-property-decorator';
    import Loader from "@/components/global/Loader.vue";

    @Component({
        name: 'monthly-earnings',
        components: {Loader},
    })
    export default class MonthlyEarned extends Vue {
        @Prop(String) private title;
        @Prop({default:false}) private hasLoaded:boolean;
        @Prop (Number) private amount;
        @Prop(String) private type;
        @Prop(String) private icon;
        @Prop({default:0}) private marginLeft:number;
        @Prop({default:0}) private marginRight:number | string;
        @Prop({default:2}) private verticalCardAmount:number;






    }
