

    import Vue from 'vue'
    import Component from 'vue-class-component'
    import {
        Action,
        Getter,
    } from 'vuex-class'
    import ToolTip from "../../ToolTip.vue";
    import {IAuditLogDTO, IUserDTO} from "../../../types/AutoGenerated/reinforcedTypings";

    @Component({
        name: "ServiceOptions",
        components: {ToolTip}
    })

    export default class ServiceOptions extends Vue {

        @Getter user : IUserDTO;
        @Action patchUser: Function;
      @Action postAuditLog: Function;

        public multipleServices : boolean  = false;
        public showServicePrice: boolean = false;
        created() {
          this.multipleServices = this.user.multipleServices;
          this.showServicePrice = this.user.showServicePrice;
        }

        async saveChanges(){
            let updatedUserPayload: Partial<IUserDTO> = {
                multipleServices: this.multipleServices,
              showServicePrice: this.showServicePrice
            };

          if(this.multipleServices != this.user.multipleServices){
            let audit: Partial<IAuditLogDTO> = {action: 1, type: 2, comment: "", message: " endret innstilling 'Flervalg av tjenester' til " + this.booleanToOnOff(this.multipleServices), hideForUser: false,};
            this.postAuditLog(audit);
          }
          if(this.showServicePrice != this.user.showServicePrice){
            let audit: Partial<IAuditLogDTO> = {action: 1, type: 2, comment: "", message: " endret innstilling 'Vis pris på tjenester' til " + this.booleanToOnOff(this.showServicePrice), hideForUser: false,};
            this.postAuditLog(audit);
          }

            await this.patchUser(updatedUserPayload);

        }

        booleanToOnOff(value: boolean): string {
            if (value) {
                return "På"
            } else {
                return "Av"
            }
        }
    }

