
    import {Component, Prop, Vue} from 'vue-property-decorator';
    import {Getter, Mutation, Action} from "vuex-class";
    // import AuthService from "../auth/AuthService";
    import { IMuscleComment } from '../../store/muscleComment';
    import CustomButton from "@/components/global/styledComponents/customButton.vue";


    interface IMuscle{
      name:string,
      id:number,
      isChecked:boolean,
      comment:string
    }

    @Component({
        name:'muscle-selector',
      components: {CustomButton},
    })
    export default class muscleGroupSelector extends Vue{
        @Prop(Number) public customerId: number | undefined;


        public activeDialogIndex:number | null = null;
        public isChecked:boolean = false;
        public dialog:boolean = false;
        public activeMuscle:string = '';
        // public muscleGroups:Array<IMuscle> = [
        //   {name:'Pectorals-left', id:1, isChecked:false, comment:''},
        //   {name:'Pectorals-right', id:2, isChecked:false, comment:''},
        //   {name:'Obliques-left', id:3, isChecked:false, comment:''},
        //   {name:'Obliques-right', id:4, isChecked:false, comment:''},
        //   {name:'Abs', id:5, isChecked:false, comment:''},
        //   {name:'Quads-left', id:6, isChecked:false, comment:''},
        //   {name:'Quads-right', id:7, isChecked:false, comment:''},
        //   {name:'Adductors-left', id:8, isChecked:false, comment:''},
        //   {name:'Adductors-right', id:9, isChecked:false, comment:''},
        //   {name:'Lats-left', id:10, isChecked:false, comment:''},
        //   {name:'Lats-right', id:11, isChecked:false, comment:''},
        //   {name:'Triceps-left', id:12, isChecked:false, comment:''},
        //   {name:'Triceps-right', id:13, isChecked:false, comment:''},
        //   {name:'Forearms-left', id:14, isChecked:false, comment:''},
        //   {name:'Forearms-right', id:15, isChecked:false, comment:''},
        //   {name:'Glutes-left', id:16, isChecked:false, comment:''},
        //   {name:'Glutes-right', id:17, isChecked:false, comment:''},
        //   {name:'Hamstrings-left', id:18, isChecked:false, comment:''},
        //   {name:'Hamstrings-right', id:19, isChecked:false, comment:''},
        //   {name:'Calves-left', id:20, isChecked:false, comment:''},
        //   {name:'Calves-right', id:21, isChecked:false, comment:''},
        //   {name:'Deltoids-left', id:22, isChecked:false, comment:''},
        //   {name:'Deltoids-right', id:23, isChecked:false, comment:''},
        //   {name:'Biceps-left', id:24, isChecked:false, comment:''},
        //   {name:'Biceps-right', id:25, isChecked:false, comment:''},
        //   {name:'Trapezius', id:26, isChecked:false, comment:''},
        // ];


        @Action public getMuscleComment:Function;
        @Action public updateMuscleComment:Function;
        @Getter public muscleComment:Array<IMuscleComment>;


        public checkMuscle(muscle:number, muscleName:string):void{
            this.activeMuscle = muscleName;
            let index = this.muscleComment.findIndex(x => x.muscleId === muscle);
            this.activeDialogIndex = index;
            this.muscleComment[index].isChecked = true;
            this.dialog = true;
        }

        public updateComment():void{
          let updatedMuscleComment = {
            customerId:this.customerId,
            muscleId:this.muscleComment[this.activeDialogIndex].muscleId,
            isChecked: this.muscleComment[this.activeDialogIndex].isChecked,
            comment: this.muscleComment[this.activeDialogIndex].comment,
          }

          this.updateMuscleComment(updatedMuscleComment);
          this.dialog = false;
        }

        public deleteComment(muscle:number):void{
          this.muscleComment[this.activeDialogIndex].isChecked = false;
          let updatedMuscleComment = {
            customerId:this.customerId,
            muscleId:this.muscleComment[this.activeDialogIndex].muscleId,
            isChecked: this.muscleComment[this.activeDialogIndex].isChecked,
            comment: this.muscleComment[this.activeDialogIndex].comment,
          }

          this.updateMuscleComment(updatedMuscleComment);
          this.dialog = false;
        }
        public created():void{
          this.getMuscleComment(this.customerId).then(() => {})
        }


    }
