// @ts-ignore
import Vue from 'vue';
// @ts-ignore
import Router from 'vue-router';
import Login from './pages/user/Login.vue';
import Settings from './pages/admin/Settings.vue';
import Customers from './pages/admin/Customers.vue';
import Customer from './pages/admin/Customer.vue';
import Appointment from './pages/admin/Appointment.vue';
import CalendarPage from './pages/admin/CalendarPage.vue';
import InvoicesOverview from './pages/admin/InvoicesOverview.vue';
import PaymentsOverview from './pages/admin/PaymentsOverview.vue';
import Dashboard from './pages/admin/Dashboard.vue';
import Onboarding from './pages/Onboarding.vue';
import MuscleGroupSelector from './components/global/muscleGroupSelector.vue';
import Statistics from './pages/admin/Statistics.vue';
import ExportPage from './pages/admin/ExportPage.vue';
import GiftCard from "@/pages/admin/GiftCard.vue";
import CancelAppointment from "@/pages/user/CancelAppointment.vue"
import Services from "@/pages/admin/services.vue";
import Register from "@/pages/user/register.vue"
import EventsOverview from "@/pages/admin/EventsOverview.vue"
import DebugOverview from "@/pages/admin/DebugOverview.vue"
import AuditOverview from "@/pages/admin/AuditOverview.vue"

import AppointmentListPage from "./pages/admin/AppointmentListPage.vue"


Vue.use(Router);

let router = new Router({
  scrollBehavior (to, from, savedPosition) {
    return { x: 0, y: 0 }
  },
  routes: [
    { path: '/', name: 'login', component: Login},
    { path: '/login', name: 'login ', component: Login},
    { path: '/innstillinger', name: 'Innstillinger', component: Settings, meta:{ requiresAuth: true }},
    { path: '/innstillinger/:setting', name: 'Innstillinger ', component: Settings, meta:{ requiresAuth: true }},

    { path: '/kunder', name: 'Kunder', component: Customers, meta:{ requiresAuth: true }},
    { path: '/kunder/:customerId', name: 'Kunde', component: Customer, meta:{ requiresAuth: true, hideMobileHeader: true  }},
    { path: '/kalender', name: 'Kalender', component: CalendarPage, meta:{ requiresAuth: true }},
    { path: '/kalender/:appointmentId', name: 'KalenderBestilling', component: CalendarPage, meta:{ requiresAuth: true }},
    { path: '/admin', name: 'Hjem', component: Dashboard, meta:{ requiresAuth: true }},
    { path: '/oauth2callback', name: 'oAuthCallBack', component: ExportPage, meta:{ requiresAuth: true }},
    { path: '/onboarding', name: 'Onboarding', component: Onboarding, meta:{ requiresAuth: true, hasLayout: false}},
    { path: '/muscle', name: 'muscleGroupSelector', component: MuscleGroupSelector, meta:{ requiresAuth: true }},
    { path: '/statistikk', name: 'Statistikk', component: Statistics, meta:{ requiresAuth: true }},
    { path: '/eksporter', name: 'Eksporter', component: ExportPage, meta:{ requiresAuth: true }},

    { path: '/bestillinger', name: 'Bestillinger', component: AppointmentListPage, meta:{ requiresAuth: true }},
    { path: '/bestillinger/:appointmentNumber', name: 'Bestilling', component: Appointment, meta:{ requiresAuth: true, hideMobileHeader: true }},


    { path: '/faktura', name: 'Faktura', component: InvoicesOverview, meta:{ requiresAuth: true }},
    { path: '/betalinger', name: 'Betalinger', component: PaymentsOverview, meta:{ requiresAuth: true }},

    { path: '/gavekort', name: 'Gavekort', component: GiftCard, meta:{ requiresAuth: true }},
    { path: '/tjenester', name: 'Tjenester', component: Services, meta:{ requiresAuth: true }},
    { path: '/hendelser', name: 'Hendelser', component: EventsOverview, meta:{ requiresAuth: true }},
    { path: '/logg/', name: 'Logg', component: AuditOverview, meta:{ requiresAuth: true }},
    { path: '/logg/:ignoreFlag', name: 'AdminLogg', component: AuditOverview, meta:{ requiresAuth: true }},
    { path: '/debug', name: 'Debug', component: DebugOverview, meta:{ requiresAuth: true }},


    { path: '/kansellere-time/:cancelId', name: 'Kansellere time', component: CancelAppointment},
    { path: '/register', name: 'Register', component: Register},

  ],
  mode: 'history',
});

router.beforeEach((to, from, next) => {
  if(from.name != to.name){
    console.log("From: ", from);
    console.log("To: ", to);
   next();
  }

  next();

});
router.afterEach( (to, from) => {
  if(to.path != null){
    localStorage.setItem("lasturl", to.path);
  }

});

export default router;
