

import Vue from 'vue'
import Component from 'vue-class-component'
import {
  State,
  Getter,
  Action,
  Mutation,
  namespace
} from 'vuex-class'
import {IStaffMemberDTO, IUserDTO} from "../../../types/AutoGenerated/reinforcedTypings";
import {formatDateStringHour} from "../../../Utilities/dateUtility";

@Component({
  name: "EmailListSettings",
})

export default class EmailListSettings extends Vue {

  public activateEmailList: boolean = false;
  public isLoading: boolean = false;

  @Action public postSnackbarMessage: Function;
  @Action public getEmailList: Function;
  @Getter public emailList: IStaffMemberDTO[];

  @Getter user : IUserDTO;
  @Action patchUser: Function;

  public headers = [
    {text: "Epost",sortable: true, value:"email"},
    {text: "Navn",sortable: true, value:"name"},
    {text: "Påmeldt",sortable: true, value:"signupDate"},
  ];

  async created() {
    this.activateEmailList = this.user.activateEmailList;
    this.isLoading = true;
    await this.getEmailList();
    this.isLoading = false;
  }
  async saveChanges(){
    let update: Partial<IUserDTO> = {
      activateEmailList: this.activateEmailList
    };

    this.patchUser(update);
  }

  formatDateStringHour(date: Date){
    return formatDateStringHour(date);
  }


  booleanToOnOff(value: boolean): string {
    if (value) {
      return "På"
    } else {
      return "Av"
    }
  }


}

