

    import Vue from 'vue'
    import Component from 'vue-class-component'
    import {logout} from "../../Utilities/authConfig";
    import {
        State,
        Getter,
        Action,
        Mutation,
        namespace
    } from 'vuex-class'
    import {DialogMutations, DialogType, IDialog} from "../../store/dialog";
    import fb from "firebase";

    @Component({
        name: "PersonalAccountSettings",
        components: {}
    })

    export default class PersonalAccountSettings extends Vue {

        @Action public addToDialogQueue:Function;
        @Getter public firebaseUser;
        @Getter public firebaseUserAccount;


        created() {

        }

        getRoleDisplayName(role){
            if(role == 0){
                return "Administrator"
            }
            if(role == 1){
                return "Bruker"
            }
        }


        logoutConfirm(){
            let dialog : IDialog = {
                text: "Ønsker du å logge ut?",
                action: logout,
                header: "Logg ut",
                type: DialogType.Choice
            };

            this.addToDialogQueue(dialog);

        }

        public forgot():void{

            let dialog: IDialog = {

                type: DialogType.Choice,
                text: "Vil du sende lenke til å endre passord til " + this.firebaseUser.email + "?",
                action: this.forgotYes
            };
            this.addToDialogQueue(dialog);
        }

        forgotYes(){
            let vm  = this;
            fb.auth().sendPasswordResetEmail(this.firebaseUser.email).then(function() {
                vm.addToDialogQueue({text: "En e-post ble sendt til deg."});
            }).catch(function(error) {
                vm.$store.commit(DialogMutations.AddToDialogQueue, );
                vm.addToDialogQueue({text: error.message});
            });
        }
    }

