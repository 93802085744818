

    import Vue from 'vue'
    import Component from 'vue-class-component'
    import {
        State,
        Getter,
        Action,
        Mutation,
        namespace
    } from 'vuex-class'
    import {DialogMutations, DialogType, IDialog} from "@/store/dialog";
    import {IappointmentType, IServiceCategory} from "@/store/appointmentTypes";
    import Loader from "./Loader.vue";
    import CustomButton from "./styledComponents/customButton.vue";

    @Component({
        name: "ServiceCategories",
        components: {CustomButton, Loader}
    })

    export default class ServiceCategories extends Vue {

        public newCategory = {title: '', isShowing: false};
        public isLoading = false;
        public isShowingForm = false;


        @Action public addNewCategory:(payload:IServiceCategory) => Promise<any>;
        @Action public putCategory:(payload:IServiceCategory) => Promise<any>;
        @Action public deleteCategory:(payload:any) => Promise<any>;
        @Action public getCategories:() => Promise<Array<IServiceCategory>>;
        @Action public changeCategoryOrder:(payload) => Promise<any>;
        @Action public postSnackbarMessage:(message:string) => void;

        @Getter public categories : Array<IServiceCategory>;

        private isEditing: boolean = false;
        private editingCategory: IServiceCategory = null;

        async created() {
            this.isLoading = true;
            await this.getCategories();
            this.isLoading = false;
        }

        async onAddNewCategory(category : IServiceCategory): Promise<void>{
            if(category.title == null || category.title == ""){
                this.postSnackbarMessage("Navn på oppgis")
                return;
            }
            this.isLoading = true;

            let newAppointment = await this.addNewCategory(category);
            this.clearForm();
            this.isShowingForm = false;
            this.isLoading = false;
        };

        clearForm():void{
            this.newCategory.title = '';
        }

        public toggleItem(item, index):void{
            item.isShowing = !item.isShowing;
        };

        async archiveCategory(itemId:string):Promise<void>{
            const vm = this;

            let dialog : IDialog = {
                text: "Er du sikker på at du vil slette denne kategorien?",
                type: DialogType.Choice,
                header: "Slett tjeneste",
                action: function(){
                    vm.deleteCategory(itemId);
                }
            };
            vm.$store.commit(DialogMutations.AddToDialogQueue, dialog);

        }

        //Methods
        public toggleForm():void{
            this.isShowingForm = !this.isShowingForm;
        };

        reOrder(categoryId, newOrder){
            this.changeCategoryOrder({categoryId: categoryId, order: newOrder})
        }

        editCategory(category){
            this.editingCategory = category;
            this.isEditing = true;
        }
        runEditCategory(){
            this.putCategory(this.editingCategory);
            this.editingCategory = null;
            this.isEditing = false;
        }

    }

