// @ts-ignore
import { GetterTree, MutationTree, ActionTree } from 'vuex';
import {APIURL2} from '@/main';
import {AuthConfig} from "@/Utilities/authConfig";
import {actionStringSnackbar} from "@/store/snackbar";
import {getCustomers} from "@/Utilities/endpoints";
import {getCustomer} from "@/Utilities/endpoints";
import {
    getCustomerAccountByPhoneNumber,
    getCustomerAccountByPhoneNumberAndPassword,
    postCustomerAccount,
    putCustomerAccount,
    sendNewPassordToCustomerAccount,
    sendPasswordCode
} from "../Utilities/endpoints";
import {DialogMutations, IDialog} from "./dialog";
import {mutationStringSnackbar} from "./snackbar";

// Interface
export interface customerAccountState {
    customerAccount: ICustomerAccount|null
    bookingStep: number
}

export enum CustomerAccountsMutations{
    SetCustomerAccount = "setCustomerAccount",
    SetBookingStep = "setBookingStep",
}

export interface ICustomerAccountLoginRequest {
    phoneNumber: string,
    password: string
}

// Interface Model
export interface ICustomerAccount {
    id: number;
    adminId: string;
    firstName: string;
    lastName: string;
    phoneNumber: string;
    emailAddress: string;
    gender: string;
    dateOfBirth: Date|null;
    dateAdded: Date|null;
    address: string,
    postalCode: string,
    password: string,
    verified: boolean;
}


export const state : customerAccountState = {
    customerAccount: null,
    bookingStep: 0
};

export const getters: GetterTree<customerAccountState, any> = {
    customerAccount: (state) => state.customerAccount,
    bookingStep: (state) => state.bookingStep
};

export const mutations: MutationTree<customerAccountState> = {
    setCustomerAccount(state : customerAccountState, payload : ICustomerAccount){
        state.customerAccount = payload;
    },
    setBookingStep(state : customerAccountState, payload : number){
        state.bookingStep = payload;
    },
};

export const actions: ActionTree<customerAccountState, any> = {
    async getCustomerAccountByPhoneNumber({commit}, phoneNumber: string) : Promise<any> {

        try{
            let customer = await getCustomerAccountByPhoneNumber(phoneNumber);
            //@ts-ignore
            return resolve(customer);
        } catch(e){
            throw Error("Could not load customers")
        }
    },
    async getCustomerAccountByPhoneNumberAndPassword({commit}, login: ICustomerAccountLoginRequest) : Promise<ICustomerAccount> {
        return new Promise(async (resolve, reject) => {
            try {
                let customer = await getCustomerAccountByPhoneNumberAndPassword(login);
                commit(CustomerAccountsMutations.SetCustomerAccount, customer);
                return resolve(customer);
            } catch (e) {
                let dialog : IDialog = {
                    text: "Feil passord"
                };
                commit(DialogMutations.AddToDialogQueue, dialog);
                reject(e);
            }
        });
    },
    async sendNewPassordToCustomerAccount({commit}, phoneNumber: string) : Promise<any> {
        return new Promise(async (resolve, reject) => {
            try {
                let customer = await sendNewPassordToCustomerAccount(phoneNumber);
                return resolve(customer);
            } catch (e) {
                reject(e);
            }
        });
    },

    async logoutCustomerAccount({commit}, phoneNumber: Partial<ICustomerAccount>) : Promise<any> {
        commit(CustomerAccountsMutations.SetCustomerAccount, null);
        commit(CustomerAccountsMutations.SetBookingStep, 1);

    },
    async putCustomerAccount({commit}, phoneNumber: Partial<ICustomerAccount>) : Promise<any> {
        return new Promise(async (resolve, reject) => {
            try {
                let customer = await putCustomerAccount(phoneNumber);
                commit(CustomerAccountsMutations.SetCustomerAccount, customer);

                commit(mutationStringSnackbar.setSnackbar, "Du har redigert profilen din.");
                return resolve(customer);
            } catch (e) {
                reject(e);
            }
        });
    },


    async sendPasswordCode({commit}, payload) : Promise<any> {
        return new Promise(async (resolve, reject) => {
            try {
                let customer = await sendPasswordCode(payload);
                return resolve(customer);
            } catch (e) {
                let dialog: IDialog = {
                    text: "Feil kode."
                }
                commit(DialogMutations.AddToDialogQueue, dialog);
                reject(e);
            }
        });
    },
    async postCustomerAccount({commit}, customerAccount) : Promise<ICustomerAccount> {

        try{
            let customer = await postCustomerAccount(customerAccount);
            commit(CustomerAccountsMutations.SetCustomerAccount, customer);
            //@ts-ignore
            return resolve(customer);
        } catch(e){
            throw Error("Could not create customer account")
        }
    },

};

export const customerAccounts = {
    actions,
    mutations,
    getters,
    state,
};
